import React, { FC } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import { commonCn } from '@utils/cn';
import { colors } from '@theme/colors';

const skeletonVariants = cva('ps-bg-gray-200', {
  variants: {
    variant: {
      text: 'ps-block ps-w-full ps-rounded',
      circular: 'ps-rounded-full',
      rectangular: '',
      rounded: 'ps-rounded',
    },
    animation: {
      pulse: 'ps-animate-pulse motion-reduce:ps-animate-none',
      wave: 'ps-animate-wave motion-reduce:ps-animate-none',
      none: 'ps-animate-none',
    },
  },
  defaultVariants: {
    variant: 'text',
    animation: 'pulse',
  },
});

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof skeletonVariants> {
  height?: string | number;
  width?: string | number;
}

export const Skeleton: FC<SkeletonProps> = ({
  className,
  variant = 'rounded',
  animation = 'wave',
  height,
  width,
  style,
  children,
  ...props
}) => {
  const combinedStyles = {
    ...(animation === 'wave' && {
      backgroundImage: `linear-gradient(90deg, ${colors.gray['50']}, ${colors.gray['500']}, ${colors.gray['50']}, ${colors.gray['500']})`,
      backgroundSize: '300% 100%',
    }),
    height: height,
    width: width,
    ...style,
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Element = variant === 'text' ? 'span' : 'div';
  const beforeEmptyContent = "before:ps-content-['-'] before:ps-invisible";

  return (
    <Element
      data-testid={'skeleton'}
      className={commonCn(
        skeletonVariants({ variant, animation }),
        children ? '[&>*]:ps-invisible' : '',
        children && !width ? 'ps-max-w-fit' : '',
        variant === 'text' && !children ? beforeEmptyContent : '',
        className
      )}
      style={combinedStyles}
      {...props}
    >
      {typeof children === 'string' ? <div>{children}</div> : children}
    </Element>
  );
};

export default Skeleton;
