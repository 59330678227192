import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { SortableTabsListProps, TabsListSortable } from '@components/tabs/tabs-list/tabs-list-sortable';
import { TabsListUI } from '@components/tabs/tabs-list/tabs-list.ui';
import { TabsContext } from '@components/tabs/utils';

export const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    SortableTabsListProps & {
      sortable?: boolean;
    }
>(({ className, sortable = false, ...props }, ref) => {
  const { view } = React.useContext(TabsContext);

  if (sortable) return <TabsListSortable ref={ref} {...props} view={view} />;
  return <TabsListUI ref={ref} {...props} />;
});
TabsList.displayName = TabsPrimitive.List.displayName;
