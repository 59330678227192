import React from 'react';
import { MultipleAutocompleteOptionConstrain } from '@components/field/autocomplete/multiple-autocomplete/multiple-autocomplete';
import { useMeasure } from '@hooks';
import { commonCn } from '@utils/cn';

const Chip = (props: {
  open?: boolean;
  optionLabel: any;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div
      data-testid={'selected-options'}
      className={commonCn(
        'ps-mr-2 ps-whitespace-nowrap ps-rounded-full ps-bg-gray-200 ps-px-1 ps-py-0.5',
        props.open && 'ps-mb-1'
      )}
    >
      {props.optionLabel}
      <span
        className={commonCn(!props.disabled && 'ps-cursor-pointer', 'ps-ml-1  ps-font-bold hover:ps-text-gray')}
        onClick={props.onClick}
      >
        &#10005;
      </span>
    </div>
  );
};

const THRESHOLD = 1;

export const LeftAdornmentComponent = <T extends MultipleAutocompleteOptionConstrain>({
  selectedValues,
  getOptionLabel,
  getOptionCode,
  handleRemoveOption,
  disabled,
  isOpen,
}: {
  selectedValues: T[] | null;
  getOptionLabel: ((option: T) => string) | ((option: any) => any);
  getOptionCode: ((option: T) => string) | ((option: any) => any);
  handleRemoveOption: (e: React.SyntheticEvent, option: T) => void;
  disabled?: boolean;
  isOpen?: boolean;
}) => {
  const [innerRef, innerSize] = useMeasure<HTMLDivElement>();
  const showOverflow = innerSize.scrollWidth - innerSize.width > THRESHOLD;

  return (
    <div className={commonCn('ps-max-w-full ps-my-0.5 ps-flex ps-relative')}>
      <div className={commonCn('ps-flex ps-w-full ps-overflow-hidden', isOpen && 'ps-flex-wrap')} ref={innerRef}>
        {(selectedValues ?? []).map((value) => {
          return (
            <Chip
              key={getOptionCode(value)}
              open={isOpen}
              optionLabel={getOptionLabel(value)}
              disabled={disabled}
              onClick={(e) => {
                if (!disabled) handleRemoveOption(e, value);
              }}
            />
          );
        })}
      </div>

      {!isOpen && showOverflow && (
        <div
          className={
            'ps-absolute ps-right-0 ps-top-0 ps-bottom-0 ps-w-8 ps-flex ps-items-center ps-justify-center ps-bg-gradient-to-r ps-from-transparent ps-to-white'
          }
        />
      )}
    </div>
  );
};
