import React from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import spinnerUrlLight from './assets/img/ps-spinner-light.svg';
// assets
import spinnerUrlDark from './assets/img/ps-spinner.svg';

interface ProsightSpinnerProps {
  className?: string;
  variant?: string;
  size?: number;
}

export const ProsightSpinnerWithTimeout: React.FC<ProsightSpinnerProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      enqueueSnackbar('Pri výpočte nastala chyba', {
        variant: 'error',
      });
    }, 15000);

    // Clean up after the effect
    return () => clearTimeout(timer);
  });

  return <ProsightSpinner {...props} />;
};

export const ProsightSpinner: React.FC<ProsightSpinnerProps> = (props) => {
  const spinnerUrl = props.variant === 'light' ? spinnerUrlLight : spinnerUrlDark;

  return (
    <div className="ps-flex ps-h-[100px] ps-w-full ps-items-center ps-justify-center">
      <img
        className="ps-pointer-events-none ps-max-h-5 ps-max-w-5 ps-animate-spin"
        src={spinnerUrl}
        alt="prosight spinner"
      />
    </div>
  );
};

ProsightSpinner.propTypes = {
  className: PropTypes.string,
};

export default ProsightSpinner;
