import React, { HTMLProps, ReactNode, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { commonCn } from '@utils/cn';
import { Container } from './container';

export interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
  fullWidth?: boolean;
  center?: boolean;
  footer?: ReactNode;
}

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', fullWidth = false, center = false, footer, ...rest }, ref) => {
    return (
      <div ref={ref as any} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Container
          className={commonCn(
            `${center ? 'ps-mx-auto' : 'ps-mx-0'} ps-pt-5 xs:ps-px-2 sm:ps-px-4.5 md:ps-px-[54px] lg:ps-px-10.5`,
            rest.className
          )}
          fixed
          maxWidth={fullWidth ? 'none' : undefined}
        >
          {children}
          {footer && footer}
        </Container>
      </div>
    );
  }
);
Page.displayName = 'Page';

export default Page;
