import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { commonCn } from '@utils/cn';

const helperTextVariants = cva(
  'ps-m-0 ps-border-0 ps-border-l-[3px] ps-border-solid ps-pl-1 ps-text-base ps-font-bold ps-leading-4',
  {
    variants: {
      variant: {
        default: 'ps-border-gray-800 ps-font-normal',
        error: 'ps-border-red-500 ps-text-red-500',
        success: 'ps-border-green ps-text-green',
        warning: 'ps-border-yellow-300 ps-text-yellow-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface HelperTextProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof helperTextVariants> {
  children?: React.ReactNode;
  className?: string;
}

export const HelperText: React.FC<HelperTextProps> = ({ variant, className, ...props }) => {
  const isStatusRole = ['error', 'success', 'warning'].includes(variant as string);

  return (
    <p
      className={commonCn(helperTextVariants({ variant, className }))}
      role={isStatusRole ? 'status' : undefined}
      data-status={isStatusRole ? variant : undefined}
      {...props}
    />
  );
};
