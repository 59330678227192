export const DEFAULT_VARIANT = 'body2';
export const DEFUALT_TYPOGRAPHY_TABLE_HEAD = 'h4';

export const TYPOGRAPHY_VARIANT_TO_HTML_ELEMENT_MAP = {
  h1: 'h1',
  h1thin: 'h2',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body1: 'p',
  body2: 'p',
  label: 'label',
} as const;
