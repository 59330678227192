import React from 'react';
import { LinkStyles } from '@components/link';
import { AlertProps } from './index';

export const SimpleAlert: React.FC<AlertProps> = ({ children, title, action, secondAction }) => {
  return (
    <div className={`ps-w-full`}>
      {title && (
        <div className={'ps-flex ps-items-center ps-text-md ps-font-bold'}>
          {typeof title === 'object' ? title : <span>{title}</span>}
        </div>
      )}
      {children && (
        <div className="ps-flex ps-items-center">
          <div className={'ps-mr-1 ps-flex ps-text-md'}>{children}</div>
          {action && !secondAction && <div className={LinkStyles}>{action}</div>}
        </div>
      )}
      {action && secondAction && children && (
        <div>
          <span className={LinkStyles}>{action}</span>
          <span className="ps-mx-1 ps-text-gray-900">•</span>
          <span className={LinkStyles}>{secondAction}</span>
        </div>
      )}
    </div>
  );
};
