import React from 'react';
import { X as Close } from 'react-feather';
import { IconButton } from '@components/icon-button';
import { commonCn } from '@utils/cn';

export interface CloseButtonProps {
  onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  absolute?: boolean;
  className?: string;
}

export const CloseButton = React.forwardRef((props: CloseButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const { onClose, absolute, className, ...rest } = props;

  return (
    <IconButton
      aria-label="close"
      className={commonCn('ps-z-10', absolute && 'ps-absolute ps-right-0 ps-top-0', className)}
      onClick={onClose}
      ref={ref}
      {...rest}
    >
      <Close />
      <span className="ps-sr-only">Close</span>
    </IconButton>
  );
});
CloseButton.displayName = 'CloseButton';

export default CloseButton;
