import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { ChevronsRight, X as CloseIcon } from 'react-feather';
import Divider from '@components/divider/divider';
import IconButton from '@components/icon-button';
import { typographyVariants } from '@components/typography';
import { commonCn } from '@utils';

export const Close = React.forwardRef<
  React.ElementRef<typeof RadixDialog.Close>,
  Omit<React.ComponentPropsWithoutRef<typeof RadixDialog.Close>, 'asChild'> & { variant?: 'close' | 'slide-out' }
>(({ className, variant = 'close', ...props }, ref) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Icon = variant === 'close' ? CloseIcon : ChevronsRight;

  return (
    <RadixDialog.Close ref={ref} asChild {...props}>
      {props.children ?? (
        <IconButton className={commonCn(className)}>
          <Icon />
          <span className="ps-sr-only">Close</span>
        </IconButton>
      )}
    </RadixDialog.Close>
  );
});
Close.displayName = RadixDialog.Description.displayName;

export const Title = React.forwardRef<
  React.ElementRef<typeof RadixDialog.Title>,
  React.ComponentPropsWithoutRef<typeof RadixDialog.Title>
>(({ className, children, ...props }, ref) => (
  <RadixDialog.Title
    ref={ref}
    className={commonCn(typeof children === 'string' ? typographyVariants({ variant: 'h3' }) : '', className)}
    {...props}
  >
    {children}
  </RadixDialog.Title>
));
Title.displayName = RadixDialog.Title.displayName;

export const Header = ({
  children,
  startButton,
  endButton,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
  startButton?: React.ReactNode;
  endButton?: React.ReactNode;
}) => {
  return (
    <div {...props}>
      <div className="ps-flex w-full ps-items-center ps-p-2 ps-gap-1">
        {startButton && <div>{startButton}</div>}
        {children && <Title className={'ps-m-0'}>{children}</Title>}
        <div className={'ps-grow'} />
        {endButton && <div>{endButton}</div>}
      </div>

      {children && <Divider className={'ps-mt-0 ps-mb-0'} />}
    </div>
  );
};

Header.displayName = 'Header';
