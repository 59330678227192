import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { type VariantProps, cva } from 'class-variance-authority';
import { Check } from 'react-feather';
import { Skeleton } from '@components';
import { commonCn } from '@utils/cn';

const CheckboxVariants = cva(
  'ps-peer ps-peer ps-absolute ps-inset-0 ps-m-0 ps-h-full ps-w-full ps-shrink-0 ps-cursor-pointer ps-appearance-none disabled:ps-pointer-events-none',
  {
    variants: {
      customStyling: {
        true: 'ps-ring-none ps-border-none ps-bg-none',
        false:
          "disabled:hover:ps-ring-0' ps-rounded-[4px] ps-border-[1px] ps-border-solid ps-border-gray-500 ps-bg-white ps-p-0 ps-text-white hover:ps-ring-2 focus-visible:ps-border-[2px] disabled:ps-bg-gray-300 disabled:ps-bg-none disabled:checked:ps-border-gray-600 checked:disabled:ps-bg-gray-600 hover:disabled:ps-border-2",
      },
      color: {
        gray: 'checked:ps-border-default checked:ps-bg-default hover:ps-border-gray-700 hover:ps-ring-gray-400 focus-visible:ps-border-gray-700',
        blue: 'checked:ps-border-blue checked:ps-bg-blue hover:ps-border-blue hover:ps-ring-blue-100 focus-visible:ps-border-blue',
        green:
          'checked:ps-border-green checked:ps-bg-green hover:ps-border-green hover:ps-ring-green-100 focus-visible:ps-border-green',
        red: 'checked:ps-border-red checked:ps-bg-red hover:ps-border-red hover:ps-ring-red-100 focus-visible:ps-border-red',
        orange:
          'checked:ps-border-orange checked:ps-bg-orange hover:ps-border-orange hover:ps-ring-orange-500/25 focus-visible:ps-border-orange',
        none: '',
      },
      error: {
        true: 'ps-border-red checked:ps-border-red checked:ps-bg-red hover:ps-border-red hover:ps-ring-red-100 focus-visible:ps-border-red',
      },
      highlight: {
        true: 'ps-bg-yellow-50',
      },
    },
    defaultVariants: {
      customStyling: false,
      color: 'blue',
      error: false,
      highlight: false,
    },
  }
);

export interface CheckboxProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'color'>,
    VariantProps<typeof CheckboxVariants> {
  size?: number;
  checkedIcon?: React.ReactNode;
  unCheckedIcon?: React.ReactNode;
  iconProps?: React.HTMLAttributes<SVGElement>;
  customStyling?: boolean;
  isLoading?: boolean;
  highlight?: boolean;
}

const CheckboxInner = React.forwardRef<React.ElementRef<'input'>, CheckboxProps>(
  (
    {
      className,
      color,
      size = 16,
      checkedIcon,
      error,
      highlight,
      iconProps,
      isLoading,
      unCheckedIcon,
      customStyling,
      ...props
    },
    ref
  ) => {
    if (isLoading) return <Checkbox.Skeleton size={size} />;

    return (
      <div className={'ps-relative ps-flex-shrink-0'} style={{ width: size, height: size }}>
        <input
          type="checkbox"
          ref={ref}
          className={commonCn(
            CheckboxVariants({
              color: customStyling ? 'none' : color,
              error: error ?? !!props['aria-invalid'],
              highlight,
              customStyling,
            }),
            className
          )}
          {...props}
        />

        <span
          className={
            'ps-pointer-events-none ps-absolute ps-inset-0 ps-hidden ps-items-center ps-justify-center ps-text-white peer-checked:ps-flex'
          }
        >
          {checkedIcon ? (
            checkedIcon
          ) : (
            <Check size={11} strokeLinecap={'round'} strokeWidth={3} strokeLinejoin="round" {...iconProps} />
          )}
        </span>
        {unCheckedIcon && (
          <span
            className={
              'ps-pointer-events-none ps-absolute ps-inset-0 ps-flex ps-items-center ps-justify-center ps-text-white peer-checked:ps-hidden'
            }
          >
            {unCheckedIcon}
          </span>
        )}
      </div>
    );
  }
);
CheckboxInner.displayName = CheckboxPrimitive.Root.displayName;

const Checkbox = Object.assign(CheckboxInner, {
  Skeleton: (props: Partial<CheckboxProps>) => (
    <Skeleton variant="rounded" width={props.size ?? 16} height={props.size ?? 16} animation="wave" />
  ),
});

export { Checkbox };
