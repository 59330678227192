import React from 'react';
import Alert from '@components/alert';
import Stack from '@components/stack/stack';

export interface AlertGroupProps {
  children: Array<React.ReactNode>;
  severity?: 'error' | 'warning' | 'success' | 'info';
  // (title === false) - no title
  title?: React.ReactNode | false;
  collapsable?: boolean;
}

const getDefaultTitle = (severity: AlertGroupProps['severity'], count: number) => {
  if (severity === 'error') return `Počet chýb: ${count}`;
  if (severity === 'warning') return `Počet upozornení: ${count}`;
  if (severity === 'info') return `Počet informačných hlášok: ${count}`;
  if (severity === 'success') return `Počet správy o úspechu: ${count}`;
};

export const AlertGroup: React.FC<AlertGroupProps> = ({
  severity = 'success',
  children,
  title = null,
  collapsable = false,
  ...rest
}) => {
  const childrenCount = React.Children.count(children);

  const titleGroup = title === false ? null : title ?? getDefaultTitle(severity, childrenCount);

  return childrenCount < 3 && !collapsable ? (
    <Stack gap={3} {...rest}>
      {React.Children.map(children, (child) => (
        <Alert severity={severity} collapsable={false}>
          {child}
        </Alert>
      ))}
    </Stack>
  ) : (
    <Alert severity={severity} title={titleGroup} collapsable {...rest}>
      <Stack gap={3} className={'ps-mt-1'}>
        {React.Children.map(children, (child) => (
          <div>{child}</div>
        ))}
      </Stack>
    </Alert>
  );
};

export default AlertGroup;
