import * as React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { type VariantProps, cva } from 'class-variance-authority';
import { Circle } from 'react-feather';
import { Skeleton } from '@components';
import { commonCn } from '@utils/cn';

const RadioVariants = cva(
  'hover:border-gray-800 ps-peer ps-absolute ps-inset-0 ps-m-0 ps-h-full ps-w-full ps-cursor-pointer ps-appearance-none ps-rounded-full ps-border-[1px] ps-border-solid ps-border-gray-500 ps-bg-transparent ps-bg-white ps-p-0 checked:ps-ring-0 hover:ps-ring-2 active:ps-ring-0 disabled:ps-pointer-events-none disabled:!ps-border-gray-600 disabled:ps-bg-gray-300 disabled:checked:ps-bg-gray-600',
  {
    variants: {
      color: {
        gray: 'ps-text-black checked:ps-border-gray-700 checked:ps-bg-gray-800 hover:ps-border-gray hover:ps-bg-gray-800/[.07] hover:ps-ring-gray-900/[.07] hover:checked:ps-bg-gray-800 focus-visible:ps-ring-gray-900/[.07]',
        blue: 'ps-text-blue checked:ps-border-blue checked:ps-bg-blue  hover:ps-border-blue hover:ps-ring-blue/25 hover:checked:ps-bg-blue focus-visible:ps-ring-blue/25',
        green:
          'ps-text-green checked:ps-border-green checked:ps-bg-green hover:ps-border-green hover:ps-bg-green/[.08] hover:ps-ring-green/[.08] hover:checked:ps-bg-green focus-visible:ps-ring-green/[.08]',
        red: 'ps-text-red-500 checked:ps-border-red-500 checked:ps-bg-red-500 hover:ps-border-red hover:ps-bg-red-500/[.06] hover:ps-ring-red-500/[.06] hover:checked:ps-bg-red-500 focus-visible:ps-ring-red-500/[.06]',
        orange:
          'ps-text-orange checked:ps-border-orange checked:ps-bg-orange hover:ps-border-orange hover:ps-border-orange hover:ps-ring-yellow-100 hover:checked:ps-bg-orange focus-visible:ps-border-orange',
      },
      error: {
        true: 'ps-border-red-500 ps-text-red-500 checked:ps-border-red-500 checked:ps-bg-red-500 hover:ps-bg-red-500/[.06] hover:ps-ring-red-500/[.06] hover:checked:ps-bg-red-500 focus-visible:ps-ring-red-500/[.06]',
      },
      highlight: {
        true: 'ps-bg-yellow-50',
      },
    },
    defaultVariants: {
      color: 'blue',
      error: false,
      highlight: false,
    },
  }
);

const ColorMapping = {
  gray: 'ps-text-black',
  blue: 'ps-text-blue',
  green: 'ps-text-green',
  red: 'ps-text-red-500',
  orange: 'ps-text-orange',
};

export interface RadioProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'color'>,
    VariantProps<typeof RadioVariants> {
  size?: number;
  icon?: React.ReactNode;
  iconProps?: React.HTMLAttributes<SVGElement>;
  isLoading?: boolean;
  highlight?: boolean;
}

const RadioInner = React.forwardRef<React.ElementRef<'input'>, RadioProps>(
  ({ className, color, size = 16, value, icon, error, highlight, iconProps, isLoading, ...props }, ref) => {
    const iconStyles = icon ? 'checked:ps-bg-transparent hover:checked:ps-bg-transparent' : '';
    const iconColor = icon && color ? ColorMapping[color] : '';
    const iconError = icon && error ? ColorMapping['red'] : '';
    if (isLoading) return <Radio.Skeleton size={size} />;

    return (
      <div className={'ps-relative ps-flex ps-shrink-0'} style={{ width: size, height: size }}>
        <input
          type="radio"
          ref={ref}
          className={commonCn(
            RadioVariants({ color, error: error ?? !!props['aria-invalid'], highlight }),
            'ps-peer ps-inset-0 ps-m-0 ps-h-full ps-w-full ps-shrink ps-appearance-none',
            iconStyles,
            className
          )}
          value={value}
          {...props}
        />

        <span
          style={{ width: size, height: size }}
          className={commonCn(
            'ps-pointer-events-none ps-absolute ps-inset-0 ps-hidden ps-items-center ps-justify-center ps-text-white peer-checked:ps-flex',
            iconStyles,
            iconColor,
            iconError
          )}
        >
          {icon ? icon : <Circle size={size - 4} strokeWidth={4} {...iconProps} />}
        </span>
      </div>
    );
  }
);

RadioInner.displayName = RadioGroupPrimitive.Item.displayName;

const Radio = Object.assign(RadioInner, {
  Skeleton: (props: Partial<React.ComponentProps<typeof RadioInner>>) => (
    <Skeleton variant="circular" width={props.size ?? 16} height={props.size ?? 16} animation="wave" />
  ),
});

export { Radio };
