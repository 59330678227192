import React, { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { CheckboxField, CheckboxFieldCommonProps } from '@components/field';
import { useFieldContext } from '@components/field/field-context';

export type CheckboxFieldWithFormikPropsCommon = Omit<CheckboxFieldCommonProps, 'name'>;

export type CheckboxFieldSingleWithFormikProps = {
  name: string;
  validateOnChange?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, defaultAction: () => void) => void;

  multiple?: never;
  values?: never;
  value?: never;
  mapValueToString?: never;
} & CheckboxFieldCommonProps;

export type CheckboxFieldMultipleWithFormikProps<T> = {
  name: string;
  validateOnChange?: boolean;
  multiple: true;
  values?: T[];
  value: T;
  mapValueToString?: (value: T) => string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, values: T[], defaultAction: () => void) => void;

  checked?: never;
  defaultChecked?: never;
} & CheckboxFieldWithFormikPropsCommon;

export type CheckboxFieldWithFormikProps<T> =
  | CheckboxFieldSingleWithFormikProps
  | CheckboxFieldMultipleWithFormikProps<T>;
function isMultipleType<T>(props: CheckboxFieldWithFormikProps<T>): props is CheckboxFieldMultipleWithFormikProps<T> {
  return props.multiple === true;
}
function isSingleType<T>(props: CheckboxFieldWithFormikProps<T>): props is CheckboxFieldSingleWithFormikProps {
  return props.multiple === undefined;
}

// Single
// eslint-disable-next-line @typescript-eslint/naming-convention
export function CheckboxFieldWithFormik(
  props: CheckboxFieldSingleWithFormikProps,
  ref: React.ForwardedRef<HTMLInputElement>
): React.ReactNode;

// Multiple
// eslint-disable-next-line @typescript-eslint/naming-convention
export function CheckboxFieldWithFormik<T>(
  props: CheckboxFieldMultipleWithFormikProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>
): React.ReactNode;

// eslint-disable-next-line @typescript-eslint/naming-convention
export function CheckboxFieldWithFormik<T>({
  validateOnChange: fieldValidateOnChange = true,
  onChange,
  ...props
}: CheckboxFieldWithFormikProps<T>) {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<boolean | T[]>(props.name);
  const { validateField, validateOnChange, validateOnBlur } = useFormikContext<any>();
  const { isLoading, disabled } = useFieldContext();

  const setValueToFormik = useCallback(
    async (value: boolean | T[]) => {
      void setTouched(true, false);
      await setValue(value, validateOnChange || validateOnBlur);
      if (fieldValidateOnChange && !validateOnChange) void validateField(props.name);
    },
    [setTouched, setValue, validateOnChange, validateOnBlur, fieldValidateOnChange, validateField, props.name]
  );

  const commonProps = {
    error: props.error ? props.error : touched && error,
    isLoading: props.isLoading || isLoading,
    disabled: props.disabled || disabled,
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, values: any) => {
      if (onChange) {
        onChange(e, values, () => {
          void setValueToFormik(values);
        });
      } else {
        void setValueToFormik(values);
      }
    },
    [onChange, setValueToFormik]
  );

  if (isMultipleType(props)) {
    return (
      <CheckboxField
        {...props}
        {...commonProps}
        multiple={true}
        values={props.values ?? (value as T[])}
        onChange={handleChange}
      />
    );
  } else if (isSingleType(props)) {
    return (
      <CheckboxField
        {...props}
        {...commonProps}
        checked={props.checked ?? (value as boolean)}
        onChange={handleChange}
      />
    );
  } else {
    throw new Error(
      'Something went wrong: `multiple` property is invalid. Only valid values are `true` or `undefined`.'
    );
  }
}
