import React, { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import { useFieldContext } from '@components/field/field-context';
import {
  RadioField,
  RadioFieldBaseProps,
  RadioFieldMultipleProps,
  RadioFieldProps,
} from '@components/field/radio-field/radio-field';

type RadioFieldWithFormikCombinedPropsAllNever<T> = {
  [K in keyof RadioFieldWithFormikProps<T> | keyof RadioFieldMultipleWithFormikProps<T>]?: never;
};

type RadioFieldTypeProps<TType, T> = Omit<RadioFieldWithFormikCombinedPropsAllNever<T>, keyof TType> & TType;

type RadioFieldWithFormikProps<T> = Omit<RadioFieldProps<T>, 'onChange' | 'name' | 'value'> & {
  name: string;
  validateOnChange?: boolean;
  value?: string | boolean | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string | boolean, defaultAction: () => void) => void;
};

type RadioFieldMultipleWithFormikProps<T> = Omit<RadioFieldMultipleProps<T>, 'onChange' | 'name' | 'value'> & {
  name: string;
  validateOnChange?: boolean;
  value?: T;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: T, defaultAction: () => void) => void;
};

export type FormikFieldRadioProps<T> = RadioFieldBaseProps & RadioFieldTypeProps<RadioFieldWithFormikProps<T>, T>;
export type FormikFieldRadioMultipleProps<T> = RadioFieldBaseProps &
  RadioFieldTypeProps<RadioFieldMultipleWithFormikProps<T>, T>;
type RadioFieldWithFormikPropsInner<T> = FormikFieldRadioProps<T> | FormikFieldRadioMultipleProps<T>;

// String
// eslint-disable-next-line @typescript-eslint/naming-convention
function RadioFieldWithFormikInner<T>(props: FormikFieldRadioProps<T>): React.ReactNode;
// Any Object
// eslint-disable-next-line @typescript-eslint/naming-convention
function RadioFieldWithFormikInner<T>(props: FormikFieldRadioMultipleProps<T>): React.ReactNode;

// eslint-disable-next-line @typescript-eslint/naming-convention
function RadioFieldWithFormikInner<T>({
  name,
  validateOnChange,
  onChange,
  value: valueFromProps,
  ...props
}: RadioFieldWithFormikPropsInner<T>) {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<T>(name);
  const { validateOnChange: formikValidateOnChange, validateOnBlur: formikValidateOnBlur } = useFormikContext<any>();
  const { isLoading, disabled } = useFieldContext();

  const setValueToFormik = useCallback(
    async (value: T) => {
      void setTouched(true, false);
      await setValue(
        value,
        isNil(validateOnChange) ? formikValidateOnChange || formikValidateOnBlur : validateOnChange
      );
    },
    [setTouched, setValue, validateOnChange, formikValidateOnBlur, formikValidateOnChange]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: (string | boolean) & T) => {
      if (onChange) {
        onChange(e, value, () => setValueToFormik(value));
      } else {
        void setValueToFormik(value);
      }
    },
    [onChange, setValueToFormik]
  );

  return (
    // @ts-ignore
    <RadioField
      {...props}
      name={name}
      onChange={(e, value) => handleChange(e, value as (string | boolean) & T)}
      value={(valueFromProps ?? value) as string}
      error={props.error ? props.error : touched ? error : undefined}
      isLoading={props.isLoading || isLoading}
      disabled={props.disabled || disabled}
    />
  );
}

export const RadioFieldWithFormik = RadioFieldWithFormikInner;
