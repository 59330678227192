import React, { useCallback } from 'react';
import { range } from 'lodash';
import isNil from 'lodash/isNil';
import { FieldSkeleton } from '@components/field';
import FieldWrapper from '@components/field/field-wrapper';
import RadioOption, { RadioOptionProps, RadioSkeleton } from '@components/field/radio-field/radio-option';
import { FieldPropsBase } from '@components/field/types';
import { InputProps } from '@components/input';
import { MuiStack, StackProps } from '@components/stack';
import { commonCn } from '@utils/cn';

const EMPTY_ARRAY: [] = [];

const isStringBooleanValue = (value: string) => value === 'true' || value === 'false';
const isChecked = <T,>(fieldValue: string | boolean | T | null, optionValue: T) => {
  if (isNil(fieldValue)) return false;
  if (fieldValue === true || fieldValue === false) return fieldValue?.toString() === optionValue;
  return fieldValue === optionValue || fieldValue?.toString() === optionValue;
};

export type RadioFieldBaseProps = FieldPropsBase &
  Omit<InputProps, 'value' | 'defaultValue' | 'error' | 'onChange' | 'children'> & {
    direction?: 'column' | 'row';
    gap?: StackProps['gap'];
    row?: boolean;
  };

export type RadioFieldProps<T> = {
  children: React.ReactElement<RadioOptionProps<T>>[] | React.ReactElement<RadioOptionProps<T>>;
  value: string | boolean | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: string | boolean) => void;
};

export type RadioFieldMultipleProps<T> = {
  value: T;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: T) => void;
  options: RadioOptionProps<T>[];
  mapOptionToValue: (option: T) => string;
};

type RadioFieldCombinedPropsAllNever<T> = {
  [K in keyof RadioFieldProps<T> | keyof RadioFieldMultipleProps<T>]?: never;
};

type RadioFieldTypeProps<TType, T> = Omit<RadioFieldCombinedPropsAllNever<T>, keyof TType> & TType;

export type RadioFieldPropsInner<T> = RadioFieldBaseProps &
  (RadioFieldTypeProps<RadioFieldProps<T>, T> | RadioFieldTypeProps<RadioFieldMultipleProps<T>, T>);

// String
// eslint-disable-next-line @typescript-eslint/naming-convention
function RadioFieldInner<T>(
  props: RadioFieldBaseProps & RadioFieldTypeProps<RadioFieldProps<T>, T>,
  ref: React.ForwardedRef<HTMLDivElement>
): React.ReactNode;

// Any Object
// eslint-disable-next-line @typescript-eslint/naming-convention
function RadioFieldInner<T>(
  props: RadioFieldBaseProps & RadioFieldTypeProps<RadioFieldMultipleProps<T>, T>,
  ref: React.ForwardedRef<HTMLDivElement>
): React.ReactNode;

// eslint-disable-next-line @typescript-eslint/naming-convention
function RadioFieldInner<T>(
  {
    label,
    labelProps,
    required,
    infoText,
    helperText,
    error,
    children,
    value,
    onChange,
    disabled,
    className,
    wrapperClassName,
    direction = 'column',
    gap,
    row = true,
    isLoading,
    highlight,
    options,
    mapOptionToValue,
    name,
    ...rest
  }: RadioFieldPropsInner<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const childrenArray = typeof children === 'object' ? (Array.isArray(children) ? children : [children]) : EMPTY_ARRAY;
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const localValue = e.target.value;
      if (isStringBooleanValue(localValue)) return onChange(e, (localValue === 'true') as any);
      if (mapOptionToValue) {
        return onChange(e, options.find((option) => mapOptionToValue(option.value) === localValue)!.value);
      }
      onChange(e, localValue);
    },
    [mapOptionToValue, onChange, options]
  );

  const showHighlight = highlight && (isNil(value) || value === '');

  if (isLoading)
    return (
      <RadioFieldSkeleton {...{ label, helperText, error, numItems: childrenArray.length, gap, row, direction }} />
    );

  return (
    <FieldWrapper
      direction={direction}
      className={wrapperClassName}
      {...{
        label,
        labelProps: {
          ...labelProps,
          style: {
            marginBottom: '0',
            ...labelProps?.style,
          },
        },
        required,
        infoText,
        helperText,
        error,
        inputId: rest.id,
      }}
      ref={ref}
    >
      {({ errorAriaAttributes, helperTextAttributes, id }) => (
        <MuiStack
          className={commonCn('ps-ml-[1px]', className)}
          gap={gap ?? (row ? 2 : 1)}
          direction={row ? 'row' : 'column'}
          id={id}
        >
          {children
            ? childrenArray?.map((child, index) => {
                const checked = isChecked(value, child.props.value);

                const newProps: RadioOptionProps<T> & { key: number } = {
                  name: name,
                  highlight: showHighlight,
                  disabled: child.props.disabled ?? disabled,
                  error: child.props.error || !!error,
                  checked: checked,
                  onChange: handleChange,
                  ...child.props,
                  key: index,
                  ...errorAriaAttributes,
                  ...helperTextAttributes,
                };

                return React.cloneElement(child, newProps);
              })
            : options?.map((optionProps, index) => {
                const checked = mapOptionToValue(value) === mapOptionToValue(optionProps.value);
                return (
                  <RadioOption
                    checked={checked}
                    onChange={handleChange}
                    highlight={showHighlight}
                    mapOptionToValue={mapOptionToValue}
                    name={name}
                    {...optionProps}
                    {...errorAriaAttributes}
                    {...helperTextAttributes}
                    key={index}
                  />
                );
              })}
        </MuiStack>
      )}
    </FieldWrapper>
  );
}

const RadioFieldSkeleton = ({
  label,
  error,
  gap,
  row,
  helperText,
  numItems,
  direction,
}: Omit<RadioFieldBaseProps, 'children'> & { numItems: number }) => (
  <FieldSkeleton
    direction={direction}
    hasLabel={!!label}
    hasError={!!error && typeof error === 'string'}
    hasHelperText={!!helperText}
  >
    <MuiStack gap={gap ?? (row ? 2 : 1)} direction={row ? 'row' : 'column'}>
      {range(numItems).map((index) => {
        return <RadioSkeleton key={index} hasLabel />;
      })}
    </MuiStack>
  </FieldSkeleton>
);

const MemoizedRadioFieldInner = React.memo(React.forwardRef(RadioFieldInner));
MemoizedRadioFieldInner.displayName = 'RadioField';

export const RadioField = MemoizedRadioFieldInner as unknown as typeof RadioFieldInner & {
  Skeleton: typeof RadioFieldSkeleton;
};

Object.assign(RadioField, {
  Skeleton: RadioFieldSkeleton,
});
