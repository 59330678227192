import React, { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@components/field';
import { useFieldContext } from '@components/field/field-context';
import { PhoneNumberFieldProps, PhoneNumberFieldWithFormikProps } from '@components/field/phone-number-field/types';

export const PhoneNumberFieldWithFormik: React.FC<PhoneNumberFieldWithFormikProps> = (props) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<string | null | undefined>(props.name);
  const { handleBlur } = useFormikContext<any>();
  const { onChange, onBlur } = props;
  const { isLoading, disabled } = useFieldContext();

  const handleChangeCallback: PhoneNumberFieldProps['onChange'] = useCallback(
    (e, value) => {
      const defaultAction = () => {
        void setTouched(true, false);
        void setValue(value);
      };
      if (onChange) onChange(e, value, defaultAction);
      else defaultAction();
    },
    [onChange, setTouched, setValue]
  );

  const handleBlurCallback: NonNullable<PhoneNumberFieldProps['onBlur']> = useCallback(
    (e) => {
      const defaultAction = () => {
        handleBlur(e);
      };
      if (onBlur) onBlur(e, defaultAction);
      else defaultAction();
    },
    [onBlur, handleBlur]
  );

  return (
    <PhoneNumberFieldMemorized
      {...{
        ...props,
        value: props.value ?? value ?? null,
        name: props.name,
        onChange: handleChangeCallback,
        onBlur: handleBlurCallback,
        error: props.error ? props.error : touched ? error : undefined,
        isLoading: props.isLoading || isLoading,
        disabled: props.disabled || disabled,
      }}
    />
  );
};

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  defaultCountryCode = '+421',
  onFocus,
  onBlur,
  onChange,
  value,
  ...props
}) => {
  return (
    <TextField
      placeholder={defaultCountryCode}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === '') onChange(null, defaultCountryCode);
        if (onFocus) setTimeout(() => onFocus(e), 0);
      }}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === defaultCountryCode) onChange(null, null);
        if (onBlur) setTimeout(() => onBlur(e), 0);
      }}
      onChange={(e) => {
        if (e.target.value !== '' && !/^[+][0-9\b]{0,16}$/.test(e.target.value)) return;

        onChange(e, e.target.value ?? null);
      }}
      value={value ?? ''}
      {...props}
    />
  );
};
const PhoneNumberFieldMemorized = React.memo(PhoneNumberField);

export { PhoneNumberFieldMemorized as PhoneNumberField };
