import React from 'react';
import { cva } from 'class-variance-authority';
import { AlertCircle, Check, X as Close } from 'react-feather';
import IconButton from '@components/icon-button';
import { DEFAULT_VIEW, TabsView } from '@components/tabs/utils';
import { commonCn } from '@utils/cn';

const tabStyles = cva(
  'ps-inline-flex ps-cursor-pointer ps-items-center ps-justify-center ps-whitespace-nowrap ps-border-0 ps-bg-transparent ps-font-sans ps-text-md ps-transition-colors disabled:ps-pointer-events-none disabled:ps-opacity-50',
  {
    variants: {
      view: {
        primary: [
          'ps-relative ps-px-0 ps-py-[7.5px] ps-font-semibold ps-leading-[20px] ps-text-gray-700',
          'after:ps-absolute after:ps-bottom-[-2px] after:ps-left-0 after:ps-right-0 after:ps-h-[2px] after:ps-rounded-full data-[state=active]:after:ps-bg-blue data-[state=active]:after:ps-content-[""]',
          'data-[state=active]:ps-border-blue data-[state=active]:ps-text-blue',
        ],
        secondary: [
          'ps-px-1.5 ps-py-[14px] ps-leading-[20px] ps-text-black',
          'data-[state=active]:ps-rounded-t-[4px] data-[state=active]:ps-bg-gray-200 data-[state=active]:ps-font-bold',
          // 'ps-tracking-wider data-[state=active]:ps-tracking-normal',
        ],
        'secondary-contrast': [
          'ps-px-1.5 ps-py-[14px] ps-leading-[20px]',
          'data-[state=active]:ps-rounded-t-[4px] data-[state=active]:ps-bg-white data-[state=active]:ps-font-bold',
          // 'ps-tracking-wider data-[state=active]:ps-tracking-normal',
        ],
      },
      status: { error: [''], success: [''] },
      isDragging: { true: ['ps-opacity-50'], false: '' },
    },
    compoundVariants: [
      {
        view: 'primary',
        status: 'error',
        class: ['data-[state=active]:ps-text-red data-[state=active]:after:ps-bg-red'],
      },
      {
        view: 'primary',
        status: 'success',
        class: ['data-[state=active]:ps-text-green data-[state=active]:after:ps-bg-green'],
      },
    ],
    defaultVariants: {
      view: DEFAULT_VIEW,
      isDragging: false,
    },
  }
);

const STATUS_ICONS = {
  error: AlertCircle,
  success: Check,
};
const STATUS_CLASSES = {
  error: 'ps-text-red-500',
  success: 'ps-text-green',
};

export const TabUi = React.forwardRef<
  React.ElementRef<'button'>,
  React.ComponentPropsWithoutRef<'button'> & {
    view?: TabsView;
    status?: 'error' | 'success';
    leftAdornment?: React.ReactNode;
    rightAdornment?: React.ReactNode;
    onClose?: (e: React.MouseEvent) => void;
    isDragging?: boolean;
  }
>(({ view, className, children, onClose, status, leftAdornment, rightAdornment, isDragging, ...props }, ref) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const StatusIcon = status && STATUS_ICONS[status];

  // reserve space for bold text
  // const childrenWithReservedSpace = (
  //   <div className={'flex flex-col items-center'}>
  //     <div className={'ps-invisible ps-h-[1px] ps-font-bold ps-text-transparent'}>{children}</div>
  //     {children}
  //   </div>
  // );

  return (
    <button ref={ref} className={commonCn(tabStyles({ view, status, isDragging }), className)} {...props}>
      {leftAdornment && <div className={'ps-mr-[10px]'}>{leftAdornment}</div>}
      {StatusIcon && <StatusIcon className={commonCn('ps-mr-[10px]', STATUS_CLASSES[status])} size={'14px'} />}
      {children}
      {rightAdornment && <div className={'ps-ml-[10px]'}>{rightAdornment}</div>}
      {onClose && (
        <IconButton
          className={'ps-my-[-10px] -ps-mr-1 ps-ml-[3px] ps-text-gray-900'}
          size={'small'}
          shape={'circular'}
          onClick={onClose}
        >
          <Close size={'16px'} />
        </IconButton>
      )}
    </button>
  );
});
TabUi.displayName = 'TabUi';
