import React from 'react';
import { Button, ButtonProps } from '@components';
import Stack from '@components/stack/stack';
import { Typography } from '@components/typography';

export const FilesSelectUploadButton: React.FC<ButtonProps> = (props) => {
  return (
    <Stack direction={'row'} className={'ps-mt-2'} gap={2} alignItems="center">
      <Button {...props} />
      {props.loading && <Typography variant={'body2'}>Nahrávam...</Typography>}
    </Stack>
  );
};
