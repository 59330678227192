import React from 'react';
import { FormikConfig, FormikProps, FormikValues, isEmptyChildren, isFunction, useFormik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import * as Yup from 'yup';
import { EnhancedFormikProvider } from '@components/enhanced-formik';
import { FieldContextValue } from '@components/field';
import { useNavigationInterceptor } from '@hooks/use-navigation-interceptor';
import { useScrollToError } from '@hooks/use-scroll-to-error';
import { formikAsyncValidateSchema } from '@utils';

export interface EnhancedFormikPropsV2<
  Values extends FormikValues = FormikValues,
  Schema extends Yup.AnySchema = Yup.AnySchema,
> extends Omit<FormikProps<Values>, 'onSubmit' | 'validationSchema' | 'validate'> {
  render?: never;
  interceptNavigation?: boolean;
  navigationInterceptorKey?: string;
  scrollToError?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  validationSchema?: Schema | (() => Schema);
  onSubmit: (values: Yup.InferType<Schema>, formikHelpers: FormikHelpers<Values>) => void | Promise<any>;
}

export interface EnhancedFormikConfigV2<
  Values extends FormikValues = FormikValues,
  Schema extends Yup.AnySchema = Yup.AnySchema,
> extends Omit<FormikConfig<Values>, 'onSubmit' | 'validationSchema' | 'validate'> {
  render?: never;
  interceptNavigation?: boolean;
  navigationInterceptorKey?: string;
  scrollToError?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  validationSchema?: Schema | (() => Schema);
  onSubmit: (values: Yup.InferType<Schema>, formikHelpers: FormikHelpers<Values>) => void | Promise<any>;
}

export const useEnhancedFormikV2 = <
  Values extends FormikValues = FormikValues,
  Schema extends Yup.AnySchema = Yup.AnySchema,
>({
  interceptNavigation = true,
  navigationInterceptorKey,
  scrollToError = true,
  isLoading = false,
  disabled = false,
  validationSchema,
  onSubmit,
  ...rest
}: EnhancedFormikConfigV2<Values, Schema>) => {
  const getValidationSchema = () => (isFunction(validationSchema) ? validationSchema() : validationSchema);

  const customSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    const valSchema = getValidationSchema();
    try {
      const validatedValues = await valSchema?.validate(values, { stripUnknown: true, context: values });
      await onSubmit(validatedValues, formikHelpers);
    } catch (e) {
      return;
    }
  };

  const formik = useFormik({
    ...rest,
    onSubmit: customSubmit,
    validate: async (values) => {
      const valSchema = getValidationSchema();
      if (!valSchema) return {};

      const { errors } = await formikAsyncValidateSchema(valSchema, values);
      const debugEnabled = localStorage.getItem('debug') === 'true';
      if (debugEnabled) console.debug('useEnhancedFormikV2:errors', errors);

      return errors;
    },
  });

  const randomKey = React.useId();
  useNavigationInterceptor(
    formik.dirty && interceptNavigation,
    `${navigationInterceptorKey ?? randomKey}<-useEnhancedFormik`
  );
  useScrollToError(scrollToError, formik);

  return { ...formik, fieldContext: { isLoading, disabled } };
};

export const EnhancedFormikV2 = <
  Values extends FormikValues = FormikValues,
  Schema extends Yup.AnySchema = Yup.AnySchema,
>(
  props: EnhancedFormikConfigV2<Values, Schema> & Omit<FieldContextValue, 'children'>
) => {
  const { component, children, innerRef } = props;
  const randomKey = React.useId();
  const enhancedFormik = useEnhancedFormikV2({
    ...props,
    navigationInterceptorKey: `${props.navigationInterceptorKey ?? randomKey}<-EnhancedFormik`,
  });

  React.useImperativeHandle(innerRef, () => enhancedFormik);

  const renderChildren = () => {
    if (component) {
      return React.createElement(component as any, enhancedFormik);
    } else if (isFunction(children)) {
      type ChildrenAsFunction = (formik: FormikProps<Values>) => React.ReactNode;
      return (children as ChildrenAsFunction)(enhancedFormik);
    } else if (!isEmptyChildren(children)) {
      return React.Children.only(children);
    }
    return null;
  };

  return <EnhancedFormikProvider value={enhancedFormik}>{renderChildren()}</EnhancedFormikProvider>;
};
