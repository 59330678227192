import React, { FC, ReactNode, useContext, useMemo, useState } from 'react';
import { cva } from 'class-variance-authority';
import { FormikContext } from 'formik';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Section } from '@components/section';
import { IconStatus } from '@components/status-icon';
import { commonCn } from '@utils/cn';

export interface CardProps {
  collapsable?: boolean;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: React.Dispatch<React.SetStateAction<boolean>>;
  title?: ReactNode;
  children?: ReactNode;
  titleIcon?: ReactNode;
  name?: string;
  status?: IconStatus;
  color?: 'primary' | 'secondary';
  className?: string;
  elevation?: 0 | 1 | 2 | 3;
}

const cardStyles = cva('ps-rounded-md ps-bg-gray-100 ps-p-3', {
  variants: {
    elevation: {
      0: '',
      1: 'ps-shadow-sm',
      2: 'ps-shadow-md',
      3: 'ps-shadow-lg',
    },
    color: {
      primary: `ps-border ps-bg-white ps-text-black`,
      secondary: 'ps-bg-gray-200',
    },
  },
  compoundVariants: [],
  defaultVariants: {
    color: 'primary',
  },
});

export const Card: FC<CardProps> = ({
  collapsable,
  children,
  color = 'primary',
  title,
  defaultOpen = false,
  status,
  name,
  className,
  titleIcon,
  onOpenChange,
  elevation = 0,
  open,
}) => {
  const { errors } = useContext(FormikContext) || {};
  const showError = useMemo(() => name && get(errors, name), [errors, name]);
  const finalStatus = status || (showError && 'error');

  const [localOpen, setLocalOpen] = useState<boolean>(defaultOpen);
  const isOpen = !isNil(open) ? open : localOpen;

  return (
    <div className={commonCn(cardStyles({ color, elevation }), className)}>
      {collapsable ? (
        <Section
          collapsable
          hideDivider
          title={title}
          open={isOpen}
          defaultOpen={defaultOpen}
          name={name}
          onOpenChange={onOpenChange ?? setLocalOpen}
          status={finalStatus}
          titleIcon={titleIcon}
        >
          {children}
        </Section>
      ) : title ? (
        <Section title={title} status={finalStatus} titleIcon={titleIcon}>
          {children}
        </Section>
      ) : (
        children
      )}
    </div>
  );
};
