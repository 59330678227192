import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { commonCn } from '@utils/cn';

export const LinkStyles = commonCn(
  'ps-select-none ps-text-base ps-font-semibold',
  'focus:ps-text-blue-800 focus:ps-outline-none',
  'hover:ps-underline',
  'active:ps-text-blue-700',
  'ps-cursor-pointer ps-text-blue-500',
  'aria-disabled:ps-pointer-events-none aria-disabled:ps-text-gray-500',
  'ps-inline-flex ps-items-center ps-gap-[4px]'
);

export type RounterLinkProps = React.ComponentPropsWithoutRef<typeof RouterLink>;

export interface LinkProps extends Omit<RounterLinkProps, 'to'> {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  to?: string;
  href?: string;
  className?: string;
}

const LinkText: FC<React.ComponentPropsWithRef<'span'> & { disabled?: boolean }> = ({ children, disabled }) => {
  return (
    <span aria-disabled={disabled} className={LinkStyles}>
      {children}
    </span>
  );
};

const LinkInner: React.FC<LinkProps> = ({ className, to, disabled, startIcon, endIcon, children, ...props }) => {
  const isChildrenString = typeof children === 'string';

  const resultClassName = commonCn(className, isChildrenString && LinkStyles);

  const content = (
    <>
      {startIcon}
      {children}
      {endIcon}
    </>
  );

  return to ? (
    <RouterLink aria-disabled={disabled} className={resultClassName} to={to} {...props}>
      {content}
    </RouterLink>
  ) : (
    <a aria-disabled={disabled} className={resultClassName} {...props}>
      {content}
    </a>
  );
};

export const Link = Object.assign(LinkInner, {
  Text: LinkText,
});
