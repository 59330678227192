import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { typographyVariants } from '@components';
import { commonCn } from '@utils';

export const Description = React.forwardRef<
  React.ElementRef<typeof RadixDialog.Description>,
  React.ComponentPropsWithoutRef<typeof RadixDialog.Description>
>(({ className, ...props }, ref) => (
  <RadixDialog.Description ref={ref} className={commonCn(typographyVariants(), className)} {...props} />
));

Description.displayName = RadixDialog.Description.displayName;
