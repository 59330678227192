import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { BlockerFunction, useBlocker } from 'react-router-dom';
import { Button } from '@components/button';
import { Modal } from '@components/modal';
import { Typography } from '@components/typography';
import { useNavigationInterceptor } from '@hooks/use-navigation-interceptor';
import { NavigationInterceptorContext } from '@utils/navigation-interceptor';

export const NavigationInterceptorProvider = ({
  children,
  debug = false,
}: {
  children: React.ReactNode;
  debug?: boolean;
}) => {
  const interceptorsRef = React.useRef(new Map<string, boolean>());

  const registerInterceptor = useCallback(
    (id: string) => {
      if (interceptorsRef.current.has(id)) throw new Error(`Interceptor with id ${id} already exists`);
      interceptorsRef.current.set(id, false);
    },
    [interceptorsRef]
  );

  const updateInterceptor = useCallback(
    (id: string, block: boolean) => {
      interceptorsRef.current.set(id, block);
    },
    [interceptorsRef]
  );

  const removeInterceptor = useCallback(
    (id: string) => {
      interceptorsRef.current.delete(id);
    },
    [interceptorsRef]
  );

  const shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (nextLocation.state?.preventNavigationInterception) return false;
      const isBlocked = [...interceptorsRef.current.values()].some(Boolean);
      return isBlocked && currentLocation.pathname !== nextLocation.pathname;
    },
    [interceptorsRef]
  );
  const blocker = useBlocker(shouldBlock);

  // Prevent page reload and tab close when there are unsaved changes (default browser confirmation modal will be shown)
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const isBlocked = [...interceptorsRef.current.values()].some(Boolean);
      if (isBlocked) event.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [interceptorsRef]);

  useEffect(() => {
    if (!debug) return;

    const printInterceptors = () => console.log('Navigation interceptors:', interceptorsRef.current);
    const interval = setInterval(printInterceptors, 1000);

    return () => clearInterval(interval);
  }, [interceptorsRef, debug]);

  const contextValue = React.useMemo(
    () => ({ registerInterceptor, updateInterceptor, removeInterceptor }),
    [registerInterceptor, updateInterceptor, removeInterceptor]
  );

  return (
    <NavigationInterceptorContext.Provider value={contextValue}>
      {children}
      <Modal open={blocker && blocker.state === 'blocked'} onOpenChange={blocker.reset}>
        <Modal.Body>
          <Modal.Header endButton={<Modal.Close />}>Neuložené údaje vo formulári</Modal.Header>
          <Modal.Content>
            <Typography>
              Rozhodli ste sa odísť zo stránky, kde máte vo formulári neuložené údaje. Odchodom zo stránky o tieto údaje
              prídete. Chcete naozaj odísť zo stránky?
            </Typography>
          </Modal.Content>
          <Modal.Footer className={'ps-justify-end'}>
            <Button onClick={blocker.proceed} variant={'outlined'}>
              Odísť zo stránky
            </Button>
            <Button onClick={blocker.reset} autoFocus>
              Ostať na stránke
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </NavigationInterceptorContext.Provider>
  );
};

export const FormikNavigationInterceptor = ({ interceptorKey }: { interceptorKey: string }) => {
  const { dirty } = useFormikContext();
  const randomKey = React.useId();

  return (
    <NavigationInterceptor
      block={dirty}
      interceptorKey={`${interceptorKey ?? randomKey}<-FormikNavigationInterceptor`}
    />
  );
};

export const NavigationInterceptor = ({ block, interceptorKey }: { block: boolean; interceptorKey: string }) => {
  const randomKey = React.useId();
  useNavigationInterceptor(block, `${interceptorKey ?? randomKey}<-NavigationInterceptor`);

  return null;
};
