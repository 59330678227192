import React from 'react';
import { ChevronDown, ChevronUp, X } from 'react-feather';
import { IconButton } from '@components/icon-button';
import { commonCn } from '@utils/cn';

export const RightAdornmentComponentInner = (
  {
    clearButton,
    setOpen,
    isOpen,
    handleClear,
    disabled,
  }: {
    clearButton: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    handleClear: (event: React.SyntheticEvent) => void;
    disabled?: boolean;
  },
  ref: React.ForwardedRef<HTMLDivElement | null>
) => {
  return (
    <div className={'ps-flex ps-justify-end'} ref={ref}>
      {clearButton && (
        <IconButton
          data-testid={'clear-button'}
          disabled={disabled}
          className={commonCn(
            'ps-invisible ps-h-3 ps-w-3 ps-p-[3px] group-hover:ps-visible group-focus:ps-visible',
            isOpen && 'ps-visible'
          )}
          onClick={handleClear}
        >
          <X />
        </IconButton>
      )}
      <IconButton
        disabled={disabled}
        onClick={(event) => {
          setOpen((prevState) => !prevState);
          event.stopPropagation();
          event.preventDefault();
        }}
        className={'ps-h-3 ps-w-3 ps-p-[3px]'}
      >
        {!isOpen ? <ChevronDown /> : <ChevronUp />}
      </IconButton>
    </div>
  );
};

export const RightAdornmentComponent = React.forwardRef(RightAdornmentComponentInner);
