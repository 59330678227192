import React, { useId, useRef } from 'react';
import { useMountedState } from 'react-use';
import { useIsPlaintextContenteditableSupported } from '@hooks/use-is-plaintext-contenteditable-supported';
import { SegmentSettings, SegmentsState } from './types';
import {
  AlphaNumericRegex,
  NumericRegex,
  getPaddedValue,
  getSegmentMaxLength,
  isFullContentSelected,
  moveCaretToEnd,
} from './utils';

export type UseBaseSegmentedInputOptions = {
  value: SegmentsState;
  onValueChange: (value: SegmentsState) => void;
  disabled?: boolean;
};

export const useBaseSegmentedInput = (segmentsSettings: SegmentSettings[], options: UseBaseSegmentedInputOptions) => {
  const isMounted = useMountedState();
  const isPlaintextContenteditableSupported = useIsPlaintextContenteditableSupported();
  const { value: segmentsValues, onValueChange, disabled } = options ?? {};

  const inputId = useId();
  const segmentsRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const segmentsValuesRef = useRef<SegmentsState>(segmentsValues);
  segmentsValuesRef.current = segmentsValues;
  const onSegmentChange = (index: number, value: string | null) => {
    const newValue = [...segmentsValuesRef.current];
    newValue[index] = value;
    onValueChange(newValue);
  };

  const moveToSegment = (index: number) => segmentsRefs.current[index]?.focus();

  const handleCharacterInput = (index: number, char: string) => {
    const segment = segmentsSettings[index];
    const maxLength = getSegmentMaxLength(segment);
    const currentValue = isFullContentSelected() ? '' : segmentsValuesRef.current[index] ?? '';

    if (segment.type === 'text') {
      if (!AlphaNumericRegex.test(char)) return;

      const maybeNewValue = currentValue + char;

      if (maxLength && maybeNewValue.length > maxLength) return moveToSegment(index + 1);
      onSegmentChange(index, maybeNewValue);
      moveCaretToEnd();
      if (maxLength && maybeNewValue.length === maxLength) moveToSegment(index + 1);
    } else if (segment.type === 'number') {
      if (!NumericRegex.test(char)) return;

      let maybeNewValue = currentValue + char;

      if (currentValue === '0' && char === '0') return;
      if (currentValue === '0' && char !== '0') {
        maybeNewValue = char;
        if ((currentValue + char).length === maxLength) {
          onSegmentChange(index, maybeNewValue);
          return moveToSegment(index + 1);
        }
      }
      if (segment.max && Number(maybeNewValue) > segment.max) maybeNewValue = char;

      // edge case, should not happen
      if (maxLength && maybeNewValue.length > maxLength) return moveToSegment(index + 1);

      const isNextDigitPossible = (() => {
        if (segment.max && maxLength && maybeNewValue.length !== maxLength) {
          const maybeNewValueAsNumber = Number(maybeNewValue);
          const maxWithoutLastDigit = Math.floor(segment.max / 10);
          if (maybeNewValueAsNumber > maxWithoutLastDigit) return false;
        }
        return true;
      })();

      onSegmentChange(index, maybeNewValue);
      moveCaretToEnd();

      if ((maxLength && maybeNewValue.length === maxLength) || !isNextDigitPossible) moveToSegment(index + 1);
    }
  };

  const handlePasteString = async (target: EventTarget, str: string) => {
    for (let i = 0; i < str.length; i++) {
      if (!isMounted()) return;
      const char = str[i];

      if (i === 0 && document.activeElement !== target) (target as HTMLSpanElement).focus();
      const currentSegmentIndex = segmentsRefs.current.findIndex((el) => el === document.activeElement);
      if (currentSegmentIndex === -1) return;

      handleCharacterInput(currentSegmentIndex, char);
      await new Promise((resolve) => setTimeout(resolve, 5));
    }
  };

  const contentEditable = isPlaintextContenteditableSupported ? 'plaintext-only' : 'true';

  const segments = segmentsSettings.map((segment, index) => {
    const maxLength = getSegmentMaxLength(segment);

    return {
      props: {
        id: `${inputId}${index}`,
        ref: (el: HTMLSpanElement | null) => (segmentsRefs.current[index] = el),
        placeholder: segment.placeholder,
        role: segment.type === 'text' ? 'textbox' : 'spinbutton',
        contentEditable: (disabled ? false : contentEditable) as any,
        suppressContentEditableWarning: true,
        'aria-valuemin': segment.type === 'number' ? segment.min : undefined,
        'aria-valuemax': segment.type === 'number' ? segment.max : undefined,
        'aria-valuenow': segment.type === 'number' ? Number(segmentsValues[index]) : undefined,
        'tab-index': 0,
        spellCheck: false,
        autoCapitalize: 'off',
        autoCorrect: 'off',
        autoComplete: 'off',
        inputMode: segment.type === 'text' ? ('text' as const) : ('numeric' as const),
        disabled: disabled,
        className:
          'ps-relative before:ps-absolute before:ps-text-transparent before:ps-w-fit before:ps-inset-0 before:ps-content-[attr(placeholder)] before:ps-pointer-events-none empty:before:ps-static empty:before:ps-text-gray-700 ' +
          'focus:ps-border-none focus:ps-outline-none active:ps-border-none active:ps-ring-none active:ps-outline-none ' +
          'ps-mx-[1px] ps-rounded-sm focus:ps-bg-blue-100 focus:ps-ring-blue-100 focus:ps-ring-2 selection:ps-bg-blue-100',
        style: {
          caretColor: 'transparent',
          minWidth: `${maxLength ?? 1}ch`,
        },
        children: segmentsValues[index] ? getPaddedValue(segment, segmentsValues[index]!) : null,
        onCopy: (e: React.ClipboardEvent<HTMLSpanElement>) => {
          e.preventDefault();
          try {
            navigator.clipboard.writeText(
              segmentsSettings
                .map((segment, index) => getPaddedValue(segment, segmentsValues[index] ?? '') + (segment.divider ?? ''))
                .join('')
            );
          } catch (e) {
            console.error(e);
          }
        },
        onCut: (e: React.ClipboardEvent<HTMLSpanElement>) => e.preventDefault(),
        onDrop: async (e: React.DragEvent<HTMLSpanElement>) => {
          e.preventDefault();
          if (disabled) return;
          await handlePasteString(e.target, e.dataTransfer.getData('text/plain'));
        },
        onPaste: async (e: React.ClipboardEvent<HTMLSpanElement>) => {
          e.preventDefault();
          if (disabled) return;
          await handlePasteString(e.target, e.clipboardData.getData('text/plain'));
        },
        onFocus: (e: React.FocusEvent<HTMLSpanElement>) => {
          if (disabled) return;
          requestAnimationFrame(() => {
            if (window.getSelection && document.createRange) {
              const range = document.createRange();
              range.selectNodeContents(e.target);
              const sel = window.getSelection();
              sel?.removeAllRanges();
              sel?.addRange(range);
            } else if ((document.body as any).createTextRange) {
              const range = (document.body as any).createTextRange();
              range?.moveToElementText(e.target);
              range?.select();
            }
          });
        },
        onBlur: () => {
          if (window.getSelection) window.getSelection()?.removeAllRanges();
          else if ((document as any).selection) (document as any).selection.empty();
        },
        onKeyDown: (e: React.KeyboardEvent<HTMLSpanElement>) => {
          if (disabled) return;
          const currentValue = segmentsValues[index] ?? '';

          if (e.key === 'ArrowRight') {
            e.preventDefault();
            return moveToSegment(index + 1);
          } else if (e.key === 'ArrowLeft') {
            e.preventDefault();
            return moveToSegment(index - 1);
          } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (segment.type === 'number') {
              const step = e.shiftKey ? 10 : 1;
              let newValue = Number(currentValue) + step;
              if (segment.max !== undefined && newValue > segment.max) newValue = segment.min ?? segment.max;
              if (segment.min !== undefined && newValue < segment.min) newValue = segment.min ?? segment.max;
              onSegmentChange(index, newValue.toString());
            }
            return;
          } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (segment.type === 'number') {
              const step = e.shiftKey ? 10 : 1;
              let newValue = Number(currentValue) - step;
              if (segment.max !== undefined && newValue > segment.max) newValue = segment.max ?? segment.min;
              if (segment.min !== undefined && newValue < segment.min) newValue = segment.max ?? segment.min;
              onSegmentChange(index, newValue.toString());
            }
            return;
          } else if (e.key === 'Backspace') {
            e.preventDefault();

            if (isFullContentSelected() || e.ctrlKey || e.metaKey) return onSegmentChange(index, null);

            if (currentValue && currentValue.length > 0) {
              const newValue = currentValue.slice(0, -1);
              return onSegmentChange(index, newValue === '' ? null : newValue);
            } else {
              return moveToSegment(index - 1);
            }
          } else if (e.key.length > 1 && e.key !== 'Enter') {
            // other special keys except enter
            return;
          } else if (e.ctrlKey || e.metaKey) {
            // combination: ctrl + c, ctrl + v, ctrl + a, etc.
            return;
          }

          e.preventDefault();
          handleCharacterInput(index, e.key);
        },
      },
      dividerProps: segment.divider && {
        children: segment.divider,
        className: 'ps-select-none ps-whitespace-pre',
        'aria-hidden': true,
      },
    };
  });

  const containerProps = {
    ref: containerRef,
    role: 'group',
    onClick: (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === containerRef.current) focus();
    },
  };

  const focus = () => {
    const firstNonFilledSegment = segmentsValuesRef.current.findIndex((el) => el === null || el === '');

    if (firstNonFilledSegment !== -1) segmentsRefs.current[firstNonFilledSegment]?.focus();
    else segmentsRefs.current[0]?.focus();
  };

  const focusSegment = (index: number) => segmentsRefs.current[index]?.focus();

  return [segments, containerProps, { focusSegment, focus }] as const;
};
