import React from 'react';
import { FormikProvider, FormikValues } from 'formik';
import { useEnhancedFormik } from '@components/enhanced-formik/enhanced-formik';
import { FormikField } from '@components/field';

export type EnhancedFormikProviderProps<Values extends FormikValues = FormikValues> = React.PropsWithChildren<{
  value: ReturnType<typeof useEnhancedFormik<Values>>;
}>;

export const EnhancedFormikProvider = <Values extends FormikValues = FormikValues>({
  children,
  value,
}: EnhancedFormikProviderProps<Values>) => {
  const { fieldContext, ...formikContextValue } = value;
  return (
    <FormikProvider value={formikContextValue}>
      <FormikField.Context {...fieldContext}>{children}</FormikField.Context>
    </FormikProvider>
  );
};
