import React, { ReactNode, SyntheticEvent } from 'react';
import isNil from 'lodash/isNil';
import AutocompleteList from '@components/field/autocomplete/autocomplete/autocomplete-list';
import useAutocomplete from '@components/field/autocomplete/autocomplete/use-autocomplete';
import { RightAdornmentComponent } from '@components/field/autocomplete/right-adornment-component';
import FieldWrapper from '@components/field/field-wrapper';
import { FieldSkeleton } from '@components/field/skeleton';
import { FieldPropsBase } from '@components/field/types';
import Input, { InputProps } from '@components/input';
import { Popover } from '@components/popover';
import { commonCn } from '@utils/cn';

export interface Option {
  name: string;
  code: string | number;
}

export interface OptionWithOriginal<T> extends Option {
  original: T;
}

export type AutocompleteFieldPropsBase<T> = FieldPropsBase & {
  getOptionLabel?: (option: T) => string;
  getOptionCode?: (option: T) => string | number;
  filterOptions?: (option: OptionWithOriginal<T>) => boolean;
  options: T[];
  clearButton?: boolean;
  onClear?: () => void;
  value: T | null;
  onChange: (e: SyntheticEvent, value: T | null) => void;
  optionsLoading?: boolean;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: 'input' | 'reset' | 'clear') => void;
  placeholder?: string;
  ref?: React.ForwardedRef<HTMLInputElement>;
  size?: InputProps['size'];
  isLoading?: boolean;
  renderOption?: (
    option: T,
    filteredListOption: Option,
    handleElementSelect: (e: React.SyntheticEvent, el: Option) => void,
    focused: boolean
  ) => ReactNode;
  rightAdornment?: ReactNode;
  OptionWrapperComponent?: React.FunctionComponent<{ children?: ReactNode; optionsCount: number }>;
  className?: string;
  minMenuHeight?: number;
};

const DefaultOptionWrapperComponent: React.FunctionComponent<{ children?: ReactNode; optionsCount: number }> = ({
  children,
}) => children;

export const AutocompleteInner = <T,>(
  {
    value,
    options = [],
    getOptionLabel: propsGetOptionLabel,
    getOptionCode: propsGetOptionCode,
    filterOptions: propsFilterOptions,
    clearButton = true,
    onClear: propsOnClear,
    onChange,
    isLoading,
    onInputChange,
    optionsLoading,
    disabled,
    placeholder,
    rightAdornment,
    className,
    renderOption,
    OptionWrapperComponent,
    minMenuHeight,
    ...rest
  }: AutocompleteFieldPropsBase<T>,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const { label, labelProps, required, infoText, helperText, error, ...other } = rest;
  const {
    isOpen,
    inputValue,
    focused,
    inputRef,
    virtuosoRef,
    handleElementSelect,
    handleClear,
    switchOpen,
    onInputKeyDown,
    filteredList,
    setOpen,
    setInputValue,
    menuHeight,
    getOptionLabel,
  } = useAutocomplete<T>({
    options,
    propsGetOptionLabel,
    propsGetOptionCode,
    propsFilterOptions,
    propsOnClear,
    onChange,
    value,
    minMenuHeight,
  });

  if (isLoading) {
    return <FieldSkeleton hasLabel={!!label} hasError={!!error} hasHelperText={!!helperText} isTextarea={false} />;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const WrapperComponent = OptionWrapperComponent || DefaultOptionWrapperComponent;

  return (
    <div className="ps-relative ps-w-full">
      <div className={'ps-flex ps-items-center'}>
        <Popover open={isOpen} onOpenChange={switchOpen}>
          <Popover.Trigger className={'ps-w-full ps-border-none ps-bg-transparent'} asChild={true}>
            <FieldWrapper
              {...{
                label,
                labelProps: {
                  ...labelProps,
                  onClick: () => {
                    switchOpen();
                    inputRef?.current?.focus();
                  },
                },
                required,
                infoText,
                helperText,
                error,
              }}
            >
              {({ errorAriaAttributes, helperTextAttributes, id }) => (
                <Input
                  className={commonCn('ps-group', className)}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    setInputValue(inputValue);
                    if (onInputChange) onInputChange(event, inputValue, 'input');
                  }}
                  value={isOpen ? inputValue : value ? getOptionLabel(value) : ''}
                  placeholder={value ? getOptionLabel(value) : placeholder ?? ''}
                  {...errorAriaAttributes}
                  {...helperTextAttributes}
                  {...other}
                  id={id}
                  onBlur={(e) => {
                    if (e.relatedTarget?.role !== 'dialog') {
                      setInputValue('');
                      if (onInputChange) onInputChange(e, '', 'reset');
                    }
                  }}
                  rightAdornment={
                    rightAdornment ? (
                      rightAdornment
                    ) : (
                      <RightAdornmentComponent
                        isOpen={isOpen}
                        setOpen={setOpen}
                        clearButton={clearButton && !isNil(value)}
                        handleClear={handleClear}
                        disabled={disabled}
                      />
                    )
                  }
                  onKeyDown={onInputKeyDown}
                  disabled={disabled}
                  aria-selected={'true'}
                  autoComplete={'off'}
                  ref={ref ?? (inputRef as React.ForwardedRef<HTMLInputElement>)}
                  onFocus={switchOpen}
                  onClick={() => setOpen(true)}
                />
              )}
            </FieldWrapper>
          </Popover.Trigger>
          <Popover.Content
            asChild={true}
            className={'ps-p-0'}
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
          >
            <div className={'ps-m-0 ps-w-[--radix-popover-trigger-width] ps-list-none ps-overflow-y-auto'}>
              <div className={'ps-rounded-md ps-border-[1px] ps-border-solid ps-border-gray-500 ps-bg-white'}>
                <WrapperComponent optionsCount={filteredList.length}>
                  <AutocompleteList
                    {...{
                      focused,
                      optionsLoading,
                      filteredList,
                      handleElementSelect,
                      options,
                      virtuosoRef,
                      menuHeight,
                      renderOption,
                    }}
                  />
                </WrapperComponent>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      </div>
    </div>
  );
};

const MemoizedAutocompleteInner = React.memo(React.forwardRef(AutocompleteInner));
MemoizedAutocompleteInner.displayName = 'Autocomplete';

export const Autocomplete = MemoizedAutocompleteInner as unknown as typeof AutocompleteInner & {
  Skeleton: typeof FieldSkeleton;
};

Object.assign(Autocomplete, {
  Skeleton: FieldSkeleton,
});
