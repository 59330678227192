import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { MoreVertical } from 'react-feather';
import { TabProps } from '@components/tabs/tab/tab';
import { TabUi } from '@components/tabs/tab/tab.ui';
import { TabsContext, getLeftAdornment } from '@components/tabs/utils';
import { commonCn } from '@utils/cn';

export const DragHandle: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({ className, ...props }) => {
  return (
    <div tabIndex={0} className={commonCn('-ps-my-1.5 ps-flex ps-cursor-grab ps-py-1.5', className)} {...props}>
      <MoreVertical size={'16px'} className={'ps-mr-[-10px]'} />
      <MoreVertical size={'16px'} />
    </div>
  );
};

export const TabSortable = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Trigger>, Omit<TabProps, 'sortable'>>(
  ({ value, leftAdornment, ...props }, ref) => {
    const { view } = React.useContext(TabsContext);
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value });

    const style = {
      ...props.style,
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const dragHandle = <DragHandle {...listeners} className={isDragging ? 'ps-text-blue' : undefined} />;

    const leftAdornmentComponent = getLeftAdornment(leftAdornment, dragHandle);

    return (
      <TabsPrimitive.Trigger
        ref={(r) => {
          setNodeRef(r);
          if (ref) (ref as React.MutableRefObject<HTMLButtonElement | null>).current = r;
        }}
        value={value}
        asChild
      >
        <TabUi
          view={view}
          ref={setNodeRef}
          {...props}
          style={style}
          {...attributes}
          data-tab-value={value}
          isDragging={isDragging}
          leftAdornment={leftAdornmentComponent}
        />
      </TabsPrimitive.Trigger>
    );
  }
);
TabSortable.displayName = 'TabSortable';
