import { useMemo, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { UseMeasureRect as ReactUseUseMeasureRect, UseMeasureRef } from 'react-use/lib/useMeasure';

type UseMeasureRect = ReactUseUseMeasureRect & {
  scrollWidth: number;
  scrollHeight: number;
};
type UseMeasureResult<E extends Element = Element> = [UseMeasureRef<E>, UseMeasureRect];

export function useMeasure<E extends Element = Element>(): UseMeasureResult<E> {
  const [element, ref] = useState<E | null>(null);
  const [rect, setRect] = useState<UseMeasureRect>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    scrollWidth: 0,
    scrollHeight: 0,
  });

  const observer = useMemo(
    () =>
      new (window as any).ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (entries[0]) {
          const scrollWidth = entries[0].target.scrollWidth;
          const scrollHeight = entries[0].target.scrollHeight;
          const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect;
          setRect({ x, y, width, height, top, left, bottom, right, scrollWidth, scrollHeight });
        }
      }),
    []
  );

  useIsomorphicLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return [ref, rect];
}
