import React, { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import { CheckboxGroupField, CheckboxGroupProps } from '@components/field';
import { useFieldContext } from '@components/field/field-context';

export type CheckboxGroupFieldWithFormik<T> = Omit<CheckboxGroupProps<T>, 'name' | 'onChange' | 'value'> & {
  validateOnChange?: boolean;
  value?: T[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: T[], defaultAction: () => void) => void;
  name: string;
};

export const CheckboxGroupFieldWithFormik = <T,>(props: CheckboxGroupFieldWithFormik<T>) => {
  const { validateOnChange = true, onChange, name } = props;

  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<any>(name);
  const { validateOnChange: formikValidateOnChange, validateOnBlur: formikValidateOnBlur } = useFormikContext<any>();
  const { isLoading, disabled } = useFieldContext();

  const setValueToFormik = useCallback(
    async (value: T[]) => {
      void setTouched(true, false);
      await setValue(
        value,
        isNil(validateOnChange) ? formikValidateOnChange || formikValidateOnBlur : validateOnChange
      );
    },
    [setTouched, setValue, validateOnChange, formikValidateOnChange, formikValidateOnBlur]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, values: T[]) => {
      const defaultAction = () => setValueToFormik(values);

      if (onChange) onChange(e, values, defaultAction);
      else void defaultAction();
    },
    [onChange, setValueToFormik]
  );

  return (
    <CheckboxGroupField
      {...{
        ...props,
        value: props.value ?? value,
        error: props.error ? props.error : touched ? error : undefined,
        isLoading: props.isLoading || isLoading,
        disabled: props.disabled || disabled,
        onChange: handleChange,
      }}
    />
  );
};
