import React, { useState } from 'react';
import { Trash2 as TrashIcon } from 'react-feather';
import { Button, ConfirmationModal, IconButton, Tooltip } from '@components';

export interface ConfirmationDeleteButtonProps {
  id: string;
  isLoading?: boolean;
  iconButton?: boolean;
  buttonText?: string;
  buttonVariant?: 'outlined' | 'text';
  showIcon?: boolean;
  modalText: string;
  onConfirm: any;
  cancelText?: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactElement;
}

export const ConfirmationDeleteButton: React.FC<ConfirmationDeleteButtonProps> = ({
  id,
  showIcon = true,
  children,
  className,
  disabled = false,
  buttonVariant = 'outlined',
  ...props
}) => {
  // state
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          ...children.props,
          id: children.props.id ?? id,
          onClick: (event: React.SyntheticEvent) => {
            children.props.onClick && children.props.onClick(event);
            setShowModal(true);
          },
        })
      ) : props.iconButton ? (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <IconButton
              id={id}
              size="small"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <TrashIcon size={20} className={'text-black'} />
            </IconButton>
          </Tooltip.Trigger>
          <Tooltip.Content align="center">{props.buttonText}</Tooltip.Content>
        </Tooltip>
      ) : (
        <Button
          id={id}
          className={className}
          disabled={disabled}
          color="error"
          variant={buttonVariant}
          loading={props.isLoading}
          startIcon={showIcon ? <TrashIcon size={20} /> : undefined}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {props.buttonText ? props.buttonText : ''}
        </Button>
      )}

      <ConfirmationModal
        id={'confirm-delete'}
        type={'danger'}
        title={'Potvrdiť vymazanie'}
        modalText={props.modalText}
        confirmButtonText={'Vymazať'}
        cancelText={props.cancelText}
        onConfirm={() => {
          props.onConfirm();
          setShowModal(false);
        }}
        open={showModal}
        onClose={handleClose}
      />
    </>
  );
};

export default ConfirmationDeleteButton;
