import React from 'react';
import { commonCn } from '@utils/cn';

interface DividerProps {
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({ className }) => {
  return (
    <hr
      className={commonCn(
        'ps-my-1 ps-border ps-border-b-[1px] ps-border-t-0 ps-border-solid ps-border-gray-500',
        className
      )}
    />
  );
};

export default Divider;
