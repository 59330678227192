import React from 'react';
import { AlertProps } from './index';

export const CollapsableAlert: React.FC<AlertProps & { isOpen: boolean; setIsOpen: any }> = ({
  children,
  title,
  action,
  secondAction,
  isOpen,
  setIsOpen,
}) => {
  return (
    <div className={`ps-flex ps-w-full ps-cursor-pointer ps-flex-col`} onClick={() => setIsOpen(!isOpen)}>
      {title && (
        <div className={`ps-text-md ps-font-bold`}>{typeof title === 'object' ? title : <span>{title}</span>}</div>
      )}

      <div
        className={`ps-transition-all ps-duration-150 ${isOpen ? 'ps-h-auto' : 'ps-h-0'} ps-overflow-hidden ps-text-md`}
      >
        {children && (
          <div className="ps-flex ps-items-center">
            <div className={'ps-mr-1 ps-flex ps-text-md'}>{children}</div>
            {action && !secondAction && (
              <div className={'ps-text-blue ps-cursor-pointer ps-text-md ps-font-semibold ps-underline'}>{action}</div>
            )}
          </div>
        )}
        {action && secondAction && (
          <div className={'ps-text-blue ps-cursor-pointer ps-text-md ps-font-semibold'}>
            <span className="ps-underline">{action}</span>
            <span className="ps-mx-1 ps-text-gray-900">•</span>
            <span className="ps-underline">{secondAction}</span>
          </div>
        )}
      </div>
    </div>
  );
};
