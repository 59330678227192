import React, { useCallback, useState } from 'react';
import { Option, OptionWithOriginal } from '@components/field';
import useAutocompleteBase from '@components/field/autocomplete/use-autocomplete-base';

interface AutocompleteHookProps<T> {
  options: T[];
  propsGetOptionLabel?: (option: T) => string;
  propsGetOptionCode?: (option: T) => string | number;
  propsFilterOptions?: (option: OptionWithOriginal<T>) => boolean;
  propsOnClear?: () => void;
  onChange: (e: React.SyntheticEvent, value: T | null) => void;
  value: T | null;
  minMenuHeight?: number;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: 'input' | 'reset' | 'clear') => void;
}

const useAutocomplete = <T,>({
  options,
  propsGetOptionLabel,
  propsGetOptionCode,
  propsFilterOptions,
  propsOnClear,
  onChange,
  value,
  minMenuHeight,
  onInputChange,
}: AutocompleteHookProps<T>) => {
  const {
    getOptionLabel,
    getOptionCode,
    isOpen,
    inputValue,
    inputRef,
    virtuosoRef,
    setOpen,
    setInputValue,
    handleClear,
    filteredList,
    menuHeight,
  } = useAutocompleteBase({
    options,
    getOptionLabel: propsGetOptionLabel,
    getOptionCode: propsGetOptionCode,
    filterOptions: propsFilterOptions,
    onClear: propsOnClear ? propsOnClear : () => {},
    minMenuHeight,
    onInputChange,
  });

  const currentlyFocused = filteredList.findIndex((el) => value && el.code === getOptionCode(value));
  const [focused, setFocused] = useState(currentlyFocused === -1 ? 0 : currentlyFocused || 0);

  const handleElementSelect = useCallback(
    (e: React.SyntheticEvent, el?: Option) => {
      const valueIndex = options.findIndex((option) => getOptionCode(option) === el?.code);
      const val = valueIndex > -1 ? options[valueIndex] : null;
      onChange(e, val);
      inputRef.current?.focus();
      if (valueIndex > -1) setFocused(valueIndex || 0);
      setInputValue('');
    },
    [options, onChange, inputRef, setInputValue, getOptionCode]
  );

  const switchOpen = useCallback(() => {
    setOpen((value) => !value);
  }, [setOpen]);

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowUp') {
        setFocused((prevState) => {
          const index = prevState - 1 < 0 ? prevState : prevState - 1;
          virtuosoRef.current?.scrollToIndex({ index, align: 'center' });
          return index;
        });
      }
      if (e.key === 'ArrowDown') {
        setFocused((prevState) => {
          const index = options.length === prevState + 1 ? prevState : prevState + 1;
          virtuosoRef.current?.scrollToIndex({ index, align: 'center' });
          return index;
        });
      }
      if (e.key === 'Enter') {
        handleElementSelect(e, filteredList[focused]);
      }
      // it’s condition to check for numbers and letters
      if (((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90)) && !isOpen) {
        setOpen(true);
        setInputValue(value ? getOptionLabel(value) + e.key : e.key);
        e.preventDefault();
      }
      if (e.key === 'Backspace' && !isOpen) {
        setOpen(true);
        setInputValue(value ? getOptionLabel(value).slice(0, -1) : '');
        e.preventDefault();
      }
      if (e.key === 'Enter') {
        setOpen((prevState) => !prevState);
      }
      if (e.key === 'Tab' && inputValue === '') {
        setInputValue(value ? getOptionLabel(value) : '');
        e.preventDefault();
      }
    },
    [
      isOpen,
      inputValue,
      virtuosoRef,
      options.length,
      handleElementSelect,
      filteredList,
      focused,
      setOpen,
      setInputValue,
      value,
      getOptionLabel,
    ]
  );

  return {
    isOpen,
    inputValue,
    focused,
    inputRef,
    virtuosoRef,
    handleElementSelect,
    handleClear,
    switchOpen,
    onInputKeyDown,
    filteredList,
    setOpen,
    setInputValue,
    setFocused,
    menuHeight,
    getOptionLabel,
    getOptionCode,
  };
};

export default useAutocomplete;
