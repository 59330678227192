import { SegmentSettings } from './types';

export const makeInitialSegmentsValue = (
  segmentsSettings: SegmentSettings[],
  value?: string | null,
  mapValueToSegments?: (value: string) => (string | null)[]
) => {
  if (value && mapValueToSegments) {
    return mapValueToSegments(value).map((segmentValue, index) => {
      const type = segmentsSettings[index].type;
      if (type === 'text') return segmentValue;
      else if (type === 'number') return segmentValue ? Number(segmentValue).toString() : null;
      else return segmentValue;
    });
  }
  return segmentsSettings.map(() => null);
};

export const setHtmlInputValue = (el: HTMLInputElement | null | undefined, value: string | null) => {
  const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;

  if (!el || !valueSetter) return;

  valueSetter?.call(el, value);
  const inputEvent = new Event('input', { bubbles: true, cancelable: false });
  el.dispatchEvent(inputEvent);
};

export const getSegmentMaxLength = (segment: SegmentSettings) => {
  if (segment.type === 'number') return segment.max ? (Math.log(segment.max) * Math.LOG10E + 1) | 0 : undefined;
  else return segment.max;
};

export const getPaddedValue = (segment: SegmentSettings, value: string) => {
  const maxLength = getSegmentMaxLength(segment);
  return maxLength && segment.padWith ? value?.padStart(maxLength, segment.padWith) : value;
};

export const moveCaretToEnd = () => {
  if (!window.getSelection) return;

  const sel = window.getSelection();
  if (sel && sel.focusNode) sel?.collapseToEnd();
};

export const isFullContentSelected = () => {
  if (!window.getSelection) return false;
  const sel = window.getSelection();
  try {
    const range = sel?.getRangeAt(0);
    return range?.startOffset === 0 && range?.endOffset !== 0;
  } catch (e) {
    return false;
  }
};

export const AlphaNumericRegex = /[0-9a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]/;
export const NumericRegex = /[0-9]/;
