import React from 'react';
import { Divider } from '@components';
import { commonCn } from '@utils';

export const Footer = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div>
    <Divider className={'ps-my-0'} />
    <div className={commonCn('ps-flex ps-gap-2 ps-p-2', className)} {...props}>
      {children}
    </div>
  </div>
);
