import React from 'react';
import ProsightSpinner from './prosight-spinner';

export const LoadingScreen: React.FC = () => {
  return (
    <div className="w-full">
      <ProsightSpinner />
    </div>
  );
};

export default LoadingScreen;
