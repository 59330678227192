import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { useMeasure } from 'react-use';
import { commonCn } from '@utils/cn';

const DEFAULT_VARIANT = 'blue';
const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;

const SLIDER_VARIANTS = {
  blue: {
    range: 'ps-from-white ps-to-blue',
    thumb: 'ps-bg-blue hover:ps-ring-blue/25 focus-visible:ps-ring-blue/25',
  },
  red: {
    range: 'ps-from-white ps-to-red-500',
    thumb: 'ps-bg-red-500 hover:ps-ring-red-500/25 focus-visible:ps-ring-red-500/25',
  },
  green: {
    range: 'ps-from-white ps-to-green',
    thumb: 'ps-bg-green hover:ps-ring-green/25 focus-visible:ps-ring-green/25',
  },
  orange: {
    range: 'ps-from-white ps-to-orange',
    thumb: 'ps-bg-orange hover:ps-ring-orange/25 focus-visible:ps-ring-orange/25',
  },
  gray: {
    range: 'ps-from-white ps-to-gray',
    thumb: 'ps-bg-gray hover:ps-ring-gray/25 focus-visible:ps-ring-gray/25',
  },
};

type SlideVariant = keyof typeof SLIDER_VARIANTS;
type Mark = { value: number; label?: string };
type ResponsiveMarksObject = {
  [key: number]: Mark[];
};

type MarksProps = { marks: Mark[]; min: number; max: number; className?: string };
const Marks = React.forwardRef<HTMLDivElement, MarksProps>(({ marks, min, max, className }, ref) => {
  return (
    <div ref={ref} className={commonCn('ps-relative ps-mx-[9px] ps-h-[20px]', className)}>
      {marks.map((mark, index) => {
        const position = ((mark.value - min) / (max - min)) * 100;
        return (
          <span
            key={`${mark.value}-${index}`}
            style={{ left: `${position}%` }}
            className={
              'ps-absolute ps-translate-x-[-50%] ps-whitespace-nowrap ps-font-sans ps-text-md ps-text-gray-700'
            }
          >
            {mark.label ?? mark.value}
          </span>
        );
      })}
    </div>
  );
});
Marks.displayName = 'Marks';

const ResponsiveMarks = ({ marks, ...props }: { marks: ResponsiveMarksObject; min: number; max: number }) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const breakpoint = Object.keys(marks).findLast((key) => width > parseInt(key));

  return <Marks marks={breakpoint ? marks[parseInt(breakpoint)] : []} ref={ref} {...props} />;
};

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    variant?: SlideVariant;
    marks?: Mark[] | ResponsiveMarksObject;
  }
>(({ className, variant = DEFAULT_VARIANT, marks, min = DEFAULT_MIN, max = DEFAULT_MAX, ...props }, ref) => {
  const thumbsAmount = (props.value || props.defaultValue)?.length ?? 1;

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={commonCn(
        'ps-flex ps-w-full ps-cursor-pointer ps-touch-none ps-select-none ps-flex-col ps-gap-1',
        className
      )}
      min={min}
      max={max}
      {...props}
    >
      <div className={'ps-relative ps-flex ps-w-full ps-items-center'}>
        <SliderPrimitive.Track className="ps-relative ps-h-[5px] ps-w-full ps-grow ps-overflow-hidden ps-rounded-full ps-bg-gray-400">
          <SliderPrimitive.Range
            className={`ps-absolute ps-h-full ps-bg-gradient-to-r ${SLIDER_VARIANTS[variant].range}`}
          />
        </SliderPrimitive.Track>
        {Array.from({ length: thumbsAmount }).map((_, i) => (
          <SliderPrimitive.Thumb
            key={i}
            className={`ps-block ps-h-[18px] ps-w-[18px] ps-rounded-full ps-transition-colors ps-transition-shadow hover:ps-ring-4 focus-visible:ps-outline-none focus-visible:ps-ring-8 disabled:ps-pointer-events-none disabled:ps-opacity-50 ${SLIDER_VARIANTS[variant].thumb}`}
          />
        ))}
      </div>
      {Array.isArray(marks) ? (
        <Marks marks={marks} min={min} max={max} />
      ) : marks ? (
        <ResponsiveMarks marks={marks} min={min} max={max} />
      ) : null}
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
