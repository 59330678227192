import { useEffect, useState } from 'react';

export const useIsLoadingDelay = (isLoadingArr: boolean[], delay: number = 500) => {
  const [isLoading, setIsLoading] = useState(false);

  const isLoadingArrResult = !isLoadingArr.every((e) => !e);

  useEffect(() => {
    if (isLoadingArrResult) return setIsLoading(true);

    const timeout = setTimeout(() => setIsLoading(isLoadingArrResult), delay);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [isLoadingArrResult]);

  return isLoading;
};
