import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { useAutosizeTextArea } from '@components/textarea/use-autoresize-text-area';
import { useForwardedRef } from '@hooks/use-forwarded-ref';
import { commonCn } from '@utils/cn';

export const DEFAULT_SIZE = 'md';
const DEFAULT_TEXT_ALIGN = 'left';

const textareaVariants = cva(
  'ps-flex ps-w-full ps-cursor-text ps-items-center ps-rounded-[4px] ps-border ps-border-solid ps-border-gray ps-bg-white ps-text-gray-900 placeholder:ps-text-gray focus-within:ps-border-blue focus-within:ps-ring-2 focus-within:ps-ring-blue/25 hover:ps-border-blue',
  {
    variants: {
      error: {
        true: 'ps-border-red-500 focus-within:ps-border-red-500 focus-within:ps-ring-red-500/25 hover:ps-border-red-500',
      },
      highlight: {
        true: 'ps-bg-yellow-50',
      },
      disabled: {
        true: 'focus-within:ps-ring-none ps-border-gray ps-bg-gray-300 ps-text-gray-600 focus-within:ps-border-none hover:ps-border-gray',
      },
      size: {
        sm: 'ps-px-1 ps-text-md',
        md: 'ps-px-2 ps-text-lg sm:ps-text-md',
        lg: 'ps-px-3',
      },
      textAlign: {
        left: 'ps-text-left',
        center: 'ps-text-center',
        right: 'ps-text-right',
      },
    },
    defaultVariants: {
      error: false,
      size: DEFAULT_SIZE,
      textAlign: DEFAULT_TEXT_ALIGN,
    },
  }
);

const textareaTextareaVariants = cva(
  'ps-m-0 ps-w-full ps-resize-none ps-border-0 ps-border-transparent ps-bg-transparent ps-p-0 ps-font-sans ps-text-inherit ps-outline-none ps-ring-0 focus:ps-border-transparent focus:ps-ring-0',
  {
    variants: {
      size: {
        sm: 'ps-py-[5.75px]',
        md: 'ps-py-[10.75px]',
        lg: 'ps-py-[14.5px]',
      },
    },
    defaultVariants: {
      size: DEFAULT_SIZE,
    },
  }
);

export type TextareaVariantsProps = VariantProps<typeof textareaVariants>;

export interface TextareaProps extends Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'size' | 'ref'> {
  rightAdornment?: React.ReactNode;
  rightAdornmentSpacing?: number;
  leftAdornment?: React.ReactNode;
  leftAdornmentSpacing?: number;
  highlight?: TextareaVariantsProps['highlight'];
  error?: TextareaVariantsProps['error'];
  textAlign?: TextareaVariantsProps['textAlign'];
  size?: TextareaVariantsProps['size'];
  rows?: number;
  maxRows?: number;
  minRows?: number;
  inputClassName?: string;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      rightAdornment,
      rightAdornmentSpacing = 0.5,
      leftAdornment,
      leftAdornmentSpacing = 0.5,
      rows = 1,
      error,
      maxRows,
      minRows,
      inputClassName,
      ...props
    },
    forwardedRef
  ) => {
    const textareaRef = useForwardedRef(forwardedRef);

    const invalid = !!error || props['aria-invalid'];

    const clickOnInput = (e: React.MouseEvent) => {
      if ((e.target as HTMLElement)?.tagName === 'TEXTAREA') return;

      e.stopPropagation();
      if (textareaRef && typeof textareaRef === 'object') {
        textareaRef.current?.click();
        textareaRef.current?.focus();
      }
    };

    useAutosizeTextArea({
      textareaRef,
      value: props.value,
      maxRows: maxRows,
      minRows: minRows ?? rows,
      size: props.size,
    });

    return (
      <div
        className={commonCn(
          textareaVariants({
            className,
            disabled: props.disabled,
            highlight: props.highlight,
            size: props.size,
            textAlign: props.textAlign,
            error: !!invalid,
          })
        )}
        onClick={clickOnInput}
      >
        {leftAdornment && (
          <div className={`ps-select-none`} style={{ marginRight: `${leftAdornmentSpacing * 8}px` }}>
            {leftAdornment}
          </div>
        )}
        <TextareaTextarea
          textareaRef={textareaRef}
          rows={rows}
          {...props}
          aria-invalid={!!invalid}
          className={commonCn(
            textareaTextareaVariants({ size: props.size, className: inputClassName }),
            'text-align-inherit ps-h-auto'
          )}
        />
        {rightAdornment && (
          <div className={`ps-select-none`} style={{ marginLeft: `${rightAdornmentSpacing * 8}px` }}>
            {rightAdornment}
          </div>
        )}
      </div>
    );
  }
);
Textarea.displayName = 'Input';

interface InputInputProps extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'size'> {
  textareaRef: React.MutableRefObject<HTMLTextAreaElement | null>;
}

const TextareaTextarea: React.FC<InputInputProps> = ({ textareaRef, ...props }) => {
  return <textarea ref={textareaRef} {...props} />;
};

export default Textarea;
