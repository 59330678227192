import React, { useEffect, useState } from 'react';
import Tooltip from '@components/tooltip';
import { useForwardedRef } from '@hooks/use-forwarded-ref';
import { commonCn } from '@utils/cn';

export interface TruncatedTextProps {
  children?: string;
  className?: string;
  contentClassName?: string;
}

export const TruncatedText = React.forwardRef<HTMLSpanElement, TruncatedTextProps>(
  ({ children: text = '', className, contentClassName }, ref) => {
    const truncateElement = useForwardedRef(ref);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
      setIsTruncated((truncateElement.current?.scrollWidth ?? 0) > (truncateElement.current?.offsetWidth ?? 0));
    }, [text, truncateElement]);

    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <span
            ref={truncateElement}
            className={commonCn('ps-m-0 ps-inline-block ps-max-w-full ps-p-0 ps-truncate ', className)}
          >
            {text}
            {'\u2009'} {/*added space character, because overflow hidden is clipping character with diacritics*/}
          </span>
        </Tooltip.Trigger>
        {isTruncated && (
          <Tooltip.Content className={commonCn('', contentClassName)} align={'start'}>
            {text}
          </Tooltip.Content>
        )}
      </Tooltip>
    );
  }
);
TruncatedText.displayName = 'TruncatedText';

export default TruncatedText;
