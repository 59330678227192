import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

export const removeSpaces = (value: string) => {
  if (isNil(value)) return '';

  const nextValue = isString(value) ? value : String(value);

  return nextValue.replace(/\s/g, '');
};
