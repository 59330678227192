import React from 'react';
import { Paperclip, Trash2 as TrashIcon } from 'react-feather';
import { Card } from '@components/card';
import { IconButton } from '@components/icon-button';
import Stack from '@components/stack/stack';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import { bytesToSize } from '@utils/bytes-to-size';
import { commonCn } from '@utils/cn';

export interface FilesListProps {
  color?: 'primary' | 'secondary';
  className?: string;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

export const FilesList: React.FC<FilesListProps> = ({ color = 'primary', files, setFiles, className }) => {
  if (files.length === 0) return null;

  return (
    <Card color={color} elevation={0} className={commonCn('ps-mt-2 ps-px-3 ps-py-2', className)}>
      <Stack gap={1}>
        {files.map((file, i) => (
          <Stack
            key={`${file.name}-${i}`}
            alignItems="center"
            gap={2}
            direction={'row'}
            className={'ps-w-full'}
            data-element-type={'uploaded-document'}
          >
            <Paperclip size={18} className={'ps-text-black'} />
            <Stack gap={1} className={'ps-flex-1'} direction={'row'}>
              <Typography
                variant="body2"
                as={'a'}
                href={URL.createObjectURL(file)}
                target={'_blank'}
                className={'hover:ps-cursor-pointer hover:ps-underline'}
              >
                {file.name}
              </Typography>
              <Typography variant="body2">{`(${bytesToSize(file.size)})`}</Typography>
            </Stack>
            <Tooltip>
              <Tooltip.Trigger asChild>
                <IconButton
                  onClick={() => {
                    const filesCopy = [...files];
                    filesCopy.splice(i, 1);
                    setFiles(filesCopy);
                  }}
                  className={'-ps-mr-1'}
                >
                  <TrashIcon size={20} className={'ps-text-black'} />
                </IconButton>
              </Tooltip.Trigger>
              <Tooltip.Content align={'start'}>Vymazať</Tooltip.Content>
            </Tooltip>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};
