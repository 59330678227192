import React from 'react';
import { Checkbox, CheckboxProps } from '@components/checkbox';
import { FieldSkeleton } from '@components/field';
import { FieldPropsBase } from '@components/field/types';
import HelperText from '@components/helper-text';
import Label from '@components/label';
import Skeleton from '@components/skeleton';
import { commonCn } from '@utils/cn';

export type CheckboxFieldCommonProps = Omit<FieldPropsBase, 'error'> &
  Omit<CheckboxProps, 'onChange' | 'value' | 'checked' | 'error'> & {
    error?: boolean | string;
    hideTextErrorMessage?: boolean;
  };

export type CheckboxFieldSingleProps = {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export type CheckboxFieldMultipleControlledProps<T> = {
  multiple: true;
  values: T[];
  value: T;
  mapValueToString?: (value: T) => string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, values: T[]) => void;
};

type CheckboxFieldCombinedPropsAllNever<T> = {
  [K in keyof CheckboxFieldSingleProps | keyof CheckboxFieldMultipleControlledProps<T>]?: never;
};

type CheckboxFieldTypeProps<TType, T> = Omit<CheckboxFieldCombinedPropsAllNever<T>, keyof TType> & TType;

type CheckboxFieldPropsInner<T> = CheckboxFieldCommonProps &
  (
    | CheckboxFieldTypeProps<CheckboxFieldSingleProps, T>
    | CheckboxFieldTypeProps<CheckboxFieldMultipleControlledProps<T>, T>
  );

export type CheckboxFieldInGroupProps<T> = CheckboxFieldCommonProps &
  Omit<CheckboxFieldTypeProps<CheckboxFieldMultipleControlledProps<T>, T>, 'multiple' | 'values' | 'onChange'>;

export type CheckboxFieldPropsSingle<T> = CheckboxFieldCommonProps &
  CheckboxFieldTypeProps<CheckboxFieldSingleProps, T>;
export type CheckboxFieldPropsMultiple<T> = CheckboxFieldCommonProps &
  CheckboxFieldTypeProps<CheckboxFieldMultipleControlledProps<T>, T>;

// Single
// eslint-disable-next-line @typescript-eslint/naming-convention
function CheckboxFieldInner<T>(
  props: CheckboxFieldPropsSingle<T>,
  ref: React.ForwardedRef<HTMLInputElement>
): React.ReactNode;

// Multiple Controlled
// eslint-disable-next-line @typescript-eslint/naming-convention
function CheckboxFieldInner<T>(
  props: CheckboxFieldPropsMultiple<T>,
  ref: React.ForwardedRef<HTMLInputElement>
): React.ReactNode;

// eslint-disable-next-line @typescript-eslint/naming-convention
function CheckboxFieldInner<T>(
  {
    isLoading,
    label,
    labelProps,
    required = true,
    error,
    helperText,
    infoText,
    checked,
    defaultChecked,
    values,
    value,
    onChange,
    multiple,
    mapValueToString,
    hideTextErrorMessage = false,
    ...rest
  }: CheckboxFieldPropsInner<T>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  if (multiple && typeof value !== 'string' && !mapValueToString) {
    throw new Error('mapValueToString is required when value is not a string');
  }

  const randomId = React.useId();
  const errorId = React.useId();
  const helperTextId = React.useId();
  rest.id ??= randomId;

  const errorAriaAttributes = error ? { error: true, 'aria-invalid': true, 'aria-errormessage': errorId } : undefined;
  const helperTextAttributes = helperText ? { 'aria-describedby': helperTextId } : undefined;

  const actualChecked = React.useMemo(() => {
    if (!multiple) return checked ?? false;
    else {
      if (typeof value === 'string') return values.includes(value);
      else {
        if (mapValueToString) return values.some((k) => mapValueToString(k) === mapValueToString(value));
        else throw new Error('mapValueToString is required when value is not a string');
      }
    }
  }, [multiple, values, value, checked, mapValueToString]);
  const actualOnChanged: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (e) => {
      if (!multiple) {
        if (onChange) onChange(e, e.target.checked);
        return;
      } else {
        const checked = e.target.checked;
        const newValues = (() => {
          if (typeof value === 'string') {
            return checked ? [...values, value] : values.filter((k) => k !== value);
          } else {
            if (mapValueToString) {
              if (checked) return [...values, value];
              else return values.filter((k) => mapValueToString(k) !== mapValueToString(value));
            } else throw new Error('mapValueToString is required when value is not a string');
          }
        })();

        if (onChange) onChange(e, newValues);
      }
    },
    [multiple, onChange, values, value, mapValueToString]
  );

  if (isLoading) return <CheckboxField.Skeleton {...{ label, error, helperText }} />;

  return (
    <div className={'ps-flex ps-flex-col ps-gap-1'}>
      {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}
      <div className={commonCn('ps-flex ps-items-center ps-gap-1', !label && 'ps-justify-center')}>
        <Checkbox
          checked={actualChecked}
          defaultChecked={defaultChecked}
          onChange={actualOnChanged}
          {...errorAriaAttributes}
          {...helperTextAttributes}
          {...rest}
          ref={ref}
        />
        {label && (
          <Label
            {...{ ...labelProps, required, infoText, error: !!error, htmlFor: rest.id, disabled: rest.disabled }}
            className={commonCn(
              '-ps-mt-[6px] ps-leading-[1.3]',
              rest.disabled ? 'ps-pointer-events-none ps-cursor-default' : 'ps-cursor-pointer'
            )}
          >
            {label}
          </Label>
        )}
      </div>
      {error && !hideTextErrorMessage && typeof error === 'string' && (
        <HelperText variant="error" id={errorId}>
          {error}
        </HelperText>
      )}
    </div>
  );
}

const MemoizedCheckboxFieldInner = React.memo(React.forwardRef(CheckboxFieldInner));
MemoizedCheckboxFieldInner.displayName = 'CheckboxField';

export const CheckboxField = MemoizedCheckboxFieldInner as unknown as typeof CheckboxFieldInner & {
  ref?: React.ForwardedRef<HTMLInputElement>;
  Skeleton: React.FC<CheckboxFieldCommonProps>;
};
Object.assign(CheckboxField, {
  Skeleton: (props: CheckboxFieldCommonProps) => (
    <FieldSkeleton
      hasError={!!props.error && typeof props.error === 'string'}
      hasHelperText={!!props.helperText}
      hasLabel={false}
    >
      <div className={commonCn('ps-flex ps-items-center ps-gap-1', !props.label && 'ps-justify-center')}>
        <Skeleton variant="rounded" width={16} height={16} animation="wave" />
        {!!props.label && <Skeleton variant="rounded" width={100} height={12} animation="wave" />}
      </div>
    </FieldSkeleton>
  ),
});
