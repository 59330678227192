import React, { FocusEventHandler, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import isNil from 'lodash/isNil';
import { Autocomplete } from '@components/field';
import {
  AutocompleteFieldPropsWithFormik,
  AutocompleteFieldPropsWithFormikDefault,
  AutocompleteOptionConstrain,
  AutocompleteValueConstrain,
} from '@components/field/autocomplete/autocomplete-with-formik/types';
import { useFieldContext } from '@components/field/field-context';

const geOptionFromValueDefault = <T extends AutocompleteOptionConstrain, S extends AutocompleteValueConstrain>(
  value: S | null,
  options: T[]
) => {
  if (value === null) return null;
  const option = options.find((el) => el.code === value);
  return option ?? null;
};

const getOptionCodeDefault = (option: any) => option.code;

/*
 * We are having 2 types of autocomplete props:
 * AutocompleteFieldPropsWithFormikDefault - if you provide options with option type { code: AutocompleteValueConstrain } you, don't need to define
 *  properties (getOptionLabel, geOptionFromValue, getOptionCode)m there will be used Default functions for handling.
 *
 * AutocompleteFieldPropsWithFormik - If you want to pass object that does not satisfy type AutocompleteOptionConstrain you need to
 *  provide (getOptionLabel, geOptionFromValue, getOptionCode)
 * */

// eslint-disable-next-line @typescript-eslint/naming-convention
export function AutocompleteWithFormik<T extends AutocompleteOptionConstrain>(
  props: AutocompleteFieldPropsWithFormikDefault<T>
): React.ReactNode;

// eslint-disable-next-line @typescript-eslint/naming-convention
export function AutocompleteWithFormik<T, S extends AutocompleteValueConstrain>(
  props: AutocompleteFieldPropsWithFormik<T, S>
): React.ReactNode;

// eslint-disable-next-line @typescript-eslint/naming-convention
export function AutocompleteWithFormik<T extends AutocompleteOptionConstrain, S extends AutocompleteValueConstrain>({
  onChange,
  geOptionFromValue = geOptionFromValueDefault,
  getOptionCode = getOptionCodeDefault as (option: T) => S,
  onClear,
  isLoading: propsIsLoading,
  ...props
}: AutocompleteFieldPropsWithFormik<T, S> | AutocompleteFieldPropsWithFormikDefault<T>): React.ReactElement {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<S | null>(props.name);

  const { isLoading, disabled } = useFieldContext();

  const handleClear = useCallback(() => {
    const defaultAction = () => {
      void setTouched(true, false);
      void setValue(null);
    };
    if (onClear) onClear(defaultAction);
    else defaultAction();
  }, [onClear, setTouched, setValue]);

  const handleChange = useCallback(
    (e: React.SyntheticEvent, option: T | null) => {
      const val = !isNil(option) && getOptionCode ? getOptionCode(option) : null;
      const defaultAction = () => {
        void setTouched(true, false);
        void setValue(val);
      };

      if (onChange) onChange(e, val, defaultAction);
      else defaultAction();
    },
    [getOptionCode, onChange, setTouched, setValue]
  );

  const handleFocus: FocusEventHandler<HTMLDivElement> = useCallback(() => {
    void setTouched(true, false);
  }, [setTouched]);

  const resultValue = useMemo(() => {
    return geOptionFromValue(value, props.options);
  }, [geOptionFromValue, props.options, value]);

  return (
    <Autocomplete
      {...{
        ...props,
        isLoading: propsIsLoading || isLoading,
        value: resultValue,
        onChange: handleChange,
        onFocus: handleFocus,
        onClear: handleClear,
        getOptionCode,
        error: props.error ? props.error : touched ? error : undefined,
        disabled: props.disabled || disabled,
      }}
    />
  );
}
