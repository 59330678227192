import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@components/typography';
import { commonCn } from '@utils/cn';

export interface ReadMoreTypographyProps {
  text: string;
  readMoreText?: string;
  readLessText?: string;
  containerHeight?: string;
  onAction?: () => void;
  lines?: number;
  className?: string;
}

export const ReadMoreTypography: React.FC<ReadMoreTypographyProps> = (props) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = containerRef.current;
    if (!el) return;

    setIsOverflowing(el.scrollHeight > el.clientHeight);
  }, [props.lines, props.text]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const {
    text = '',
    readMoreText = 'Čítať ďalej',
    readLessText = 'Skryť',
    lines = 3,
    onAction = toggleExpand,
    className,
  } = props;

  return (
    <div className={commonCn(`ps-flex ${expanded && 'ps-flex-col'}`, className)}>
      <div ref={containerRef} className={`ps-flex ps-overflow-hidden `}>
        <div
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: !expanded ? lines : undefined,
          }}
        >
          <Typography variant={'body2'}>{text}</Typography>
        </div>
      </div>
      {isOverflowing ? (
        <div className={commonCn('ps-flex', expanded && 'ps-mt-1')}>
          <a
            className={'ps-cursor-pointer ps-whitespace-nowrap ps-text-base ps-text-blue ps-underline'}
            onClick={expanded ? toggleExpand : onAction}
          >
            {expanded ? readLessText : readMoreText}
          </a>
        </div>
      ) : null}
    </div>
  );
};
