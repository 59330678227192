import { FormikErrors } from 'formik';
import get from 'lodash/get';
import set from 'lodash/set';
import * as Yup from 'yup';

export function formikAsyncValidateSchema<FormState extends object>(schema: Yup.AnySchema, values: FormState) {
  return new Promise<{ errors: FormikErrors<FormState>; hasErrors: boolean }>((resolve) => {
    schema
      .validate(values, { abortEarly: false, strict: false, context: values })
      .then(() => resolve({ errors: {}, hasErrors: false }))
      .catch((yupErr) => {
        resolve({
          errors: yupErr.inner.reduce((memo: any, { path, message }: { path: string; message: string }) => {
            !get(memo, path) && set(memo, path, message);
            return memo;
          }, {}),
          hasErrors: true,
        });
      });
  });
}
