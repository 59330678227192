import React from 'react';
import { DropzoneState } from 'react-dropzone';
import { commonCn } from '@utils/cn';

export interface FilesSelectRootProps extends DropzoneState {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const FilesSelectRoot = React.forwardRef<HTMLDivElement, FilesSelectRootProps>(
  ({ children, className, ...dropzoneState }, ref) => {
    return (
      <div
        className={commonCn(
          `ps-rounded-md ps-border-2 ps-border-dashed ps-border-black ps-bg-white ps-px-3 ps-py-2 ps-outline-none 
        ${
          dropzoneState.isDragActive
            ? 'ps-bg-gray-100 ps-opacity-75'
            : 'ps-cursor-pointer ps-opacity-75 hover:ps-bg-gray-100'
        }`,
          className
        )}
        {...dropzoneState.getRootProps()}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

FilesSelectRoot.displayName = 'FilesSelectRoot';
