import React from 'react';
import { TabProps } from '@components/tabs';

export function getLeftAdornment(leftAdornment: TabProps['leftAdornment'], dragHandle?: React.ReactElement) {
  if (!leftAdornment) return dragHandle;
  if (React.isValidElement(leftAdornment)) return leftAdornment;
  if (typeof leftAdornment === 'function') return leftAdornment({ dragHandle });
  return leftAdornment;
}

export const DEFAULT_VIEW = 'primary';
export type TabsView = 'primary' | 'secondary' | 'secondary-contrast';

export const TabsContext = React.createContext<{ view: TabsView; value?: string | null }>({
  view: DEFAULT_VIEW,
});
