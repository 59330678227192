import React, { FC } from 'react';
import {
  BarController,
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  Chart as ChartJS,
  type ChartOptions,
  ChartTypeRegistry,
  Legend,
  LinearScale,
  Point,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import merge from 'lodash/merge';
import { Bar } from 'react-chartjs-2';
import { commonCn, numberFormat } from '@utils';

interface StackedBarChartProps {
  data: any;
  height?: number;
  width?: number;
  options?: ChartOptions<'bar'>;
  isCustomLegend?: boolean;
  className?: string;
}

export const BarChart: FC<StackedBarChartProps> = ({
  data,
  isCustomLegend = false,
  className,
  options: customOptions,
  ...other
}) => {
  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: !isCustomLegend,
        position: 'bottom',
        onClick: undefined,
      },
      tooltip: {
        intersect: false,
        position: 'nearest',
        callbacks: {
          label: (context) => context.dataset.label + ': ' + numberFormat.money(context.raw as string),
        },
      },
    },
    interaction: {
      mode: 'index',
    },

    scales: {
      x: {
        min: 0,
        stacked: true,
      },
      y: {
        min: 0,
        stacked: true,
      },
    },
  };

  const centerGraph = {
    id: 'tooltipNote',
    beforeDatasetsDraw: (
      chart: ChartJS<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint | null)[], unknown>
    ) => {
      if (chart.data.labels && chart.data.labels.length > 1) return;

      chart.data.datasets.forEach((dataset, index) => {
        const meta = chart.getDatasetMeta(index);
        chart.getDatasetMeta(index).data.forEach((bar) => {
          if (bar.x && meta.yScale?.width) {
            bar.x = (chart.chartArea.width + chart.chartArea.left) / 2;
          }
        });
      });
    },
  };

  const opt: ChartOptions<'bar'> = merge(options, customOptions);
  ChartJS.register(CategoryScale, LinearScale, PointElement, BarController, BarElement, Title, Tooltip, Legend);

  return (
    <div className={commonCn('ps-bg-white', className)}>
      <Bar data={data} options={opt} {...other} plugins={[centerGraph]} />
    </div>
  );
};

export default BarChart;
