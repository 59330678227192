import React from 'react';
import { Info as InfoOutlined } from 'react-feather';
import { Button, Tooltip, TruncatedText } from '@components';
import { Typography } from '@components/typography';
import { commonCn } from '@utils/cn';

export interface LabelProps extends Omit<React.HTMLAttributes<HTMLLabelElement>, 'ref' | 'color'> {
  required?: boolean;
  secondaryAction?: { text: string; callback: React.MouseEventHandler };
  children?: string | React.ReactElement;
  infoText?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  htmlFor?: string;
}

export const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ children, error, disabled, required = false, secondaryAction, infoText, className, ...props }, ref) => {
    return (
      <Typography
        variant={'label'}
        className={commonCn(
          '-ps-my-[3px] -ps-mb-[5px] ps-inline-flex ps-max-w-full ps-leading-[18px]',
          error && 'ps-text-red-500',
          disabled && 'ps-text-gray-600',
          className
        )}
        ref={ref}
        {...props}
      >
        {typeof children === 'string' ? <TruncatedText>{children}</TruncatedText> : children}
        {!required && <span className={'ps-text-gray-600'}>&nbsp;(nepovinné)</span>}
        {secondaryAction && (
          <div>
            <Button
              variant={'text'}
              onClick={secondaryAction.callback}
              className={'-ps-my-[6.5px] -ps-mr-1 ps-h-3 ps-px-1 ps-py-[5px] ps-leading-[14px]'}
            >
              {secondaryAction.text}
            </Button>
          </div>
        )}
        {infoText && (
          <Tooltip>
            <Tooltip.Trigger asChild>
              <InfoOutlined
                className={'ps-ml-1 !ps-cursor-pointer ps-text-gray-600 hover:ps-text-gray-700'}
                size={20}
              />
            </Tooltip.Trigger>
            <Tooltip.Content>{infoText}</Tooltip.Content>
          </Tooltip>
        )}
      </Typography>
    );
  }
);
Label.displayName = 'Label';

export default Label;
