import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { type VariantProps, cva } from 'class-variance-authority';
import { WithPopupContextProps, commonCn, withPopupContext } from '@utils';

const DEFAULT_SIDE = 'right';
const DEFAULT_SIZE = 'sm';
const DEFAULT_TYPE = 'modal';

const BodyVariants = cva(
  'ps-max-w-full ps-fixed ps-flex ps-flex-col ps-bg-white ps-shadow-md ps-transition ps-ease-in-out data-[state=open]:ps-animate-in data-[state=closed]:ps-animate-out focus-visible:ps-outline-none',
  {
    variants: {
      side: {
        top: '',
        bottom: '',
        left: '',
        right: '',
      },
      type: {
        modal: 'ps-max-h-[80vh] ps-min-w-[90vw] ps-rounded-md',
        sidebar: '',
        navbar: 'ps-w-[270px]',
      },
      size: {
        sm: '',
        md: '',
        full: 'ps-w-full',
      },
    },
    compoundVariants: [
      { type: 'modal', size: 'sm', class: 'sm:ps-w-[600px] sm:ps-min-w-[600px]' },
      { type: 'modal', size: 'md', class: 'md:ps-w-[900px] md:ps-min-w-[900px]' },
      { type: 'sidebar', size: 'sm', class: 'ps-w-[632px]' },
      { type: 'sidebar', size: 'md', class: 'ps-w-[776px]' },

      {
        type: 'sidebar',
        side: 'top',
        class:
          'ps-max-h-screen ps-inset-x-0 ps-top-0 ps-border-b data-[state=closed]:ps-slide-out-to-top data-[state=open]:ps-slide-in-from-top',
      },
      {
        type: 'sidebar',
        side: 'bottom',
        class:
          'ps-max-h-screen ps-inset-x-0 ps-bottom-0 ps-border-t data-[state=closed]:ps-slide-out-to-bottom data-[state=open]:ps-slide-in-from-bottom',
      },
      {
        type: 'sidebar',
        side: 'left',
        class:
          'xs:max-w-xs ps-inset-y-0 ps-left-0 ps-h-full ps-border-r data-[state=closed]:ps-slide-out-to-left data-[state=open]:ps-slide-in-from-left',
      },
      {
        type: 'sidebar',
        side: 'right',
        class:
          'xs:max-w-xs ps-inset-y-0 ps-right-0 ps-h-full ps-border-l data-[state=closed]:ps-slide-out-to-right data-[state=open]:ps-slide-in-from-right',
      },
    ],
    defaultVariants: {
      side: DEFAULT_SIDE,
      size: DEFAULT_SIZE,
      type: DEFAULT_TYPE,
    },
  }
);

const BodyInner = React.forwardRef<
  React.ElementRef<typeof RadixDialog.Content>,
  React.ComponentPropsWithoutRef<typeof RadixDialog.Content> & VariantProps<typeof BodyVariants> & WithPopupContextProps
>(
  (
    { size = DEFAULT_SIZE, side = DEFAULT_SIDE, type = DEFAULT_TYPE, asChild, className, zIndex, style, ...rest },
    ref
  ) => {
    return (
      <RadixDialog.DialogPortal>
        <RadixDialog.DialogOverlay
          className={commonCn(
            'ps-fixed ps-inset-0 ps-overflow-y-auto ps-bg-black ps-bg-opacity-50 data-[state=open]:ps-animate-in data-[state=closed]:ps-animate-out data-[state=closed]:ps-fade-out-0 data-[state=open]:ps-fade-in-0',
            type === 'modal' && 'flex-center'
          )}
          style={{ zIndex }}
        >
          <RadixDialog.DialogContent
            ref={ref}
            className={commonCn(BodyVariants({ size, className, side, type }), className)}
            style={{ zIndex: zIndex + 1, ...style }}
            asChild={asChild}
            {...rest}
          />
        </RadixDialog.DialogOverlay>
      </RadixDialog.DialogPortal>
    );
  }
);
BodyInner.displayName = 'Body';

export const Body = withPopupContext(BodyInner);
