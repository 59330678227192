import { MutableRefObject, useLayoutEffect } from 'react';
import isNil from 'lodash/isNil';
import { DEFAULT_SIZE, TextareaProps, TextareaVariantsProps } from '@components/textarea/textarea';

const LINE_HEIGHT_MAPPING = {
  sm: 21,
  md: 23,
  lg: 25,
};

interface UseAutosizeTextAreaProps {
  textareaRef: MutableRefObject<HTMLTextAreaElement | null>;
  value: TextareaProps['value'];
  minRows: number;
  maxRows?: number;
  size?: TextareaVariantsProps['size'];
}

export const useAutosizeTextArea = ({ textareaRef, value, minRows, maxRows, size }: UseAutosizeTextAreaProps) => {
  const lineHeight = LINE_HEIGHT_MAPPING[size ?? DEFAULT_SIZE];

  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      if (isNil(maxRows)) {
        textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, minRows * lineHeight)}px`;
        return;
      }
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxRows * lineHeight);
      textareaRef.current.style.height = `${Math.max(newHeight, minRows * lineHeight)}px`;
    }
  }, [lineHeight, maxRows, minRows, textareaRef, value]);
};
