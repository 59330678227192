import React from 'react';

export function useStateWOReRender<T>(defaultValue: T) {
  const ref = React.useRef<T>(defaultValue);

  const get = () => ref.current;

  type TFunc = (current: T) => T;
  const set = (val: T | TFunc) => {
    const newValue = typeof val === 'function' ? (val as TFunc)(ref.current) : val;
    ref.current = newValue;
  };

  return { get, set };
}

export type StateWORerender<T> = ReturnType<typeof useStateWOReRender<T>>;
