import React from 'react';
import { HelperText } from '@components';
import { LabelWrapper } from '@components/field/label-wrapper';
import { commonCn } from '@utils/cn';
import { FieldPropsBase } from './types';

type FieldWrapperProps = Omit<FieldPropsBase, 'labelComponent'> & {
  children: (props: {
    errorAriaAttributes?: { 'aria-invalid': boolean; 'aria-errormessage': string };
    helperTextAttributes?: { 'aria-describedby': string };
    id: string;
  }) => React.ReactNode;
  inputId?: string;
  className?: string;
  direction?: 'column' | 'row';
};

export const FieldWrapper = React.forwardRef<HTMLDivElement, FieldWrapperProps>(
  (
    { label, labelProps, required, infoText, helperText, error, children, className, direction = 'column', ...rest },
    ref
  ) => {
    const randomId = React.useId();
    rest.inputId ??= randomId;

    const errorId = React.useId();
    const helperTextId = React.useId();
    const errorAriaAttributes = error ? { 'aria-invalid': true, 'aria-errormessage': errorId } : undefined;
    const helperTextAttributes = helperText ? { 'aria-describedby': helperTextId } : undefined;

    return (
      <div
        className={commonCn(
          'ps-flex ps-min-h-[20px] ps-gap-1',
          direction === 'column' ? 'ps-flex-col' : 'ps-flex-row ps-items-center',
          className
        )}
        ref={ref}
      >
        {label && (
          <LabelWrapper {...{ ...labelProps, required, infoText, htmlFor: rest.inputId }}>{label}</LabelWrapper>
        )}
        {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}

        {children({ errorAriaAttributes, helperTextAttributes, id: rest.inputId })}

        {error && (
          <HelperText variant="error" id={errorId}>
            {error}
          </HelperText>
        )}
      </div>
    );
  }
);

FieldWrapper.displayName = 'FieldWrapper';

export default FieldWrapper;
