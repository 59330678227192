import React, { FC } from 'react';
import { commonCn } from '@utils/cn';

export interface LinearProgressProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number;
  label?: boolean;
  rootClassName?: string;
  barClassName?: string;
  labelClassName?: string;
  rootStyle?: React.CSSProperties;
  barStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

export const LinearProgress: FC<LinearProgressProps> = ({
  value,
  label,
  rootClassName,
  barClassName,
  labelClassName,
  rootStyle,
  barStyle,
  labelStyle,
}) => {
  const progress = value ? `${value}%` : '0%';
  const isDone = value === 100;
  const progressBarColor = isDone ? 'ps-bg-green' : 'ps-bg-blue-600';
  const textColor = isDone ? 'ps-text-green' : 'ps-text-blue';
  const borderRadius = isDone ? 'ps-rounded' : 'ps-rounded-s';

  return (
    <div className={commonCn('ps-w-full ps-items-center')}>
      <div className={commonCn('ps-flex ps-items-center')}>
        {label && (
          <p className={commonCn('ps-m-0 ps-text-md ps-font-normal', textColor, labelClassName)} style={labelStyle}>
            {progress}
          </p>
        )}
        <div
          className={commonCn('ps-h-[5px] ps-flex-grow ps-rounded-2xl ps-bg-gray-400', rootClassName)}
          style={rootStyle}
        >
          <div
            className={commonCn('ps-h-full', progressBarColor, borderRadius, barClassName)}
            style={{ width: progress, ...barStyle }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LinearProgress;
