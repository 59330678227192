import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { commonCn } from '@utils/cn';
import { WithPopupContextProps, withPopupContext } from '@utils/popup-context';

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & WithPopupContextProps
>(({ className, align = 'center', sideOffset = 4, zIndex, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={commonCn(
        'ps-bg-popover ps-text-popover-foreground ps-data-[state=open]:ps-animate-in ps-data-[state=closed]:ps-animate-out ps-data-[state=closed]:ps-fade-out-0 ps-data-[state=open]:ps-fade-in-0 ps-data-[state=closed]:ps-zoom-out-95 ps-data-[state=open]:ps-zoom-in-95 ps-data-[side=bottom]:ps-slide-in-from-top-2 ps-data-[side=left]:ps-slide-in-from-right-2 ps-data-[side=right]:ps-slide-in-from-left-2 ps-data-[side=top]:ps-slide-in-from-bottom-2 ps-rounded-md ps-border ps-p-1 ps-shadow-md ps-outline-none',
        className
      )}
      {...props}
      style={{ zIndex, ...props.style }}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const PopoverContentWithPopupContext = withPopupContext(PopoverContent);

export const Popover = Object.assign(PopoverPrimitive.Root, {
  Anchor: PopoverPrimitive.Anchor,
  Trigger: PopoverPrimitive.Trigger,
  Content: PopoverContentWithPopupContext,
});
export default Popover;
