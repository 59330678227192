import React, { useCallback, useMemo, useRef, useState } from 'react';
import deburr from 'lodash/deburr';
import isNil from 'lodash/isNil';
import { VirtuosoHandle } from 'react-virtuoso';
import { OptionWithOriginal } from '@components/field';

interface AutocompleteBaseProps<T> {
  options: T[];
  getOptionLabel?: (option: T) => string;
  getOptionCode?: (option: T) => string | number;
  filterOptions?: (option: OptionWithOriginal<T>) => boolean;
  inputValue?: string;
  onClear?: () => void;
  minMenuHeight?: number;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: 'input' | 'reset' | 'clear') => void;
}

export const ELEMENT_HEIGHT = 37;
export const MIN_MENU_HEIGHT = 200;

const normalizeDiacritics = (text: string) => {
  return deburr(text).toLowerCase();
};

const useAutocompleteBase = <T>({
  options,
  getOptionLabel: propsGetOptionLabel,
  getOptionCode: propsGetOptionCode,
  filterOptions: propsFilterOptions,
  inputValue: initialInputValue = '',
  onClear: initialOnClear = () => {},
  onInputChange,
  minMenuHeight,
}: AutocompleteBaseProps<T>) => {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(initialInputValue);
  const inputRef: React.RefObject<HTMLInputElement | null> = useRef(null);
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const getOptionLabelInner = useCallback((option: T) => {
    return typeof option === 'string' || typeof option === 'number'
      ? option.toString()
      : ((option as any).name as string);
  }, []);

  const getOptionLabel = propsGetOptionLabel ?? getOptionLabelInner;

  const getOptionCodeInner = useCallback((option: T) => {
    return typeof option === 'string' || typeof option === 'number'
      ? option.toString()
      : ((option as any)?.code as string);
  }, []);
  const getOptionCode = propsGetOptionCode ?? getOptionCodeInner;

  const filterOptionsInner = useCallback(
    (option: OptionWithOriginal<T>) => normalizeDiacritics(option.name).includes(normalizeDiacritics(inputValue)),
    [inputValue]
  );
  const filterOptions = propsFilterOptions ?? filterOptionsInner;

  const list = useMemo(() => {
    return options.map((option) => ({ name: getOptionLabel(option), code: getOptionCode(option), original: option }));
  }, [options, getOptionLabel, getOptionCode]);

  const filteredList = useMemo(() => {
    return list.filter(filterOptions);
  }, [list, filterOptions]);

  const handleClear = useCallback(
    (event: React.SyntheticEvent) => {
      setInputValue('');
      if (onInputChange) onInputChange(event, '', 'input');
      initialOnClear();
    },
    [initialOnClear, onInputChange]
  );

  const resultMinMenuHeight = isNil(minMenuHeight) ? MIN_MENU_HEIGHT : minMenuHeight;

  const filteredListLength = filteredList.length === 0 ? 1 : filteredList.length;
  const menuHeight = Math.min(filteredListLength * ELEMENT_HEIGHT, resultMinMenuHeight);

  return {
    isOpen,
    inputValue,
    inputRef,
    virtuosoRef,
    setOpen,
    setInputValue,
    handleClear,
    filteredList,
    menuHeight,
    getOptionLabel,
    getOptionCode,
  };
};

export default useAutocompleteBase;
