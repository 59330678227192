import React, { useMemo, useState } from 'react';
import { format, isValid, parse } from 'date-fns';
import isNil from 'lodash/isNil';
import { Matcher } from 'react-day-picker';
import { Calendar as CalendarIcon, Copy, X } from 'react-feather';
import { Calendar } from '@components/calendar';
import { FieldSkeleton } from '@components/field';
import { getSegmentsRanges } from '@components/field/date/utils';
import FieldWrapper from '@components/field/field-wrapper';
import { FieldPropsBase } from '@components/field/types';
import IconButton from '@components/icon-button';
import { inputInputVariants, inputVariants } from '@components/input';
import { Popover } from '@components/popover';
import { useSegmentedInput } from '@hooks/use-segmented-input';
import { commonCn } from '@utils/cn';

export type DateFieldProps = FieldPropsBase & {
  /*
   * - *string* - valid date string in format yyyy-MM-dd
   * - *<empty string>* - value is empty, **act as trigger to clear input**
   * - *null* - is set when value is not valid, will preserve input inner value
   */
  value?: string | null;
  /*
   * - *string* - valid date string in format yyyy-MM-dd
   * - *<empty string>* - value is empty
   * - *null* - is set when value is not valid
   */
  onChange?: (value: string | null) => void;
  defaultValue?: string;
  inputProps?: React.ComponentPropsWithRef<'input'>;
  minDate?: Date;
  maxDate?: Date;
  disabledDays?: Matcher[];
  pickerOpenAt?: Date;
  disablePast?: boolean;
  disableFuture?: boolean;
};

const transformDate = (date: string) => {
  const [year, month, day] = date.split('-');
  if (!year || !month || !day || !date) return null;
  return `${day}.${month}.${year}`;
};

const DateFieldInner = (
  {
    name,
    defaultValue,
    value,
    onChange,
    inputProps,
    label,
    labelProps,
    required,
    infoText,
    helperText,
    error,
    highlight,
    disabled,
    id,
    isLoading,
    pickerOpenAt,
    maxDate,
    minDate,
    disabledDays,
    disablePast,
    disableFuture,
  }: DateFieldProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const maybeValueAsDate = parse(value ?? '', 'yyyy-MM-dd', new Date());
  const valueAsDate = isValid(maybeValueAsDate) ? maybeValueAsDate : null;

  const segmentsMinMax = getSegmentsRanges(valueAsDate, { minDate, maxDate });

  const [segments, containerProps, ariaInputProps, { handleClear, handleChangeValue }] = useSegmentedInput(
    [
      { placeholder: 'dd', type: 'number', ...segmentsMinMax.day, padWith: '0', divider: '.' },
      { placeholder: 'mm', type: 'number', ...segmentsMinMax.month, padWith: '0', divider: '.' },
      { placeholder: 'yyyy', type: 'number', ...segmentsMinMax.year, padWith: '0' },
    ],
    {
      name: name,
      onValueChange: onChange,
      defaultValue: defaultValue,
      value: value,
      inputRef: inputProps?.ref,
      disabled: disabled,
      mapValueToSegments: (value) => {
        const [year, month, day] = value.split('-').map((el) => (Number.isNaN(Number(el)) ? '' : el));
        return [day, month, year];
      },
      mapSegmentsToValue: (segments: (string | null)[]) => {
        const day = segments[0] ? segments[0].padStart(2, '0') : null;
        const month = segments[1] ? segments[1].padStart(2, '0') : null;
        const year = segments[2] ? segments[2].padStart(4, '0') : null;

        return `${year}-${month}-${day}`;
      },
      isValid: (value, segments) => {
        const [day, month, year] = segments;
        const date = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
        const segmentsValid = segments.every((el) => el === null) || isValid(date);
        if (!segmentsValid) return false;

        const dateValue = parse(value ?? '', 'yyyy-MM-dd', new Date());
        return !value || isValid(dateValue);
      },
    }
  );

  const minDateAsNumber = Number(minDate ? minDate : disablePast ? new Date() : undefined);
  const maxDateAsNumber = Number(maxDate ? maxDate : disableFuture ? new Date() : undefined);
  const pickerOpenAtAsNumber = Number(pickerOpenAt);
  const calendarProps = useMemo(() => {
    const disabledDatesMatchers = disabledDays ? [...disabledDays] : [];
    if (minDate) disabledDatesMatchers.push({ before: minDate });
    if (maxDate) disabledDatesMatchers.push({ after: maxDate });

    const middleDate = minDate && maxDate && new Date((minDateAsNumber + maxDateAsNumber) / 2);
    const defaultMonth = pickerOpenAt ?? valueAsDate ?? (middleDate || new Date());

    return {
      disable: disabledDatesMatchers,
      fromMonth: minDate,
      toMonth: maxDate,
      defaultMonth,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDateAsNumber, maxDateAsNumber, disabledDays, value, pickerOpenAtAsNumber]);

  if (isLoading) {
    return <FieldSkeleton hasLabel={!!label} hasError={!!error} hasHelperText={!!helperText} isTextarea={false} />;
  }

  const formattedDate = transformDate(value ?? '');

  const showHighlight = highlight && (isNil(value) || value === '');
  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <Popover.Anchor asChild>
        <FieldWrapper {...{ label, labelProps, required, infoText, helperText, error, inputId: id, ref }}>
          {({ errorAriaAttributes, helperTextAttributes, id }) => (
            <div
              className={commonCn(
                inputVariants({
                  error: Boolean(ariaInputProps['aria-invalid']) || Boolean(errorAriaAttributes?.['aria-invalid']),
                  highlight: showHighlight,
                  disabled,
                }),
                'ps-group ps-relative'
              )}
            >
              <input
                id={id}
                {...ariaInputProps}
                {...inputProps}
                {...errorAriaAttributes}
                {...helperTextAttributes}
                aria-invalid={Boolean(ariaInputProps['aria-invalid']) || Boolean(errorAriaAttributes?.['aria-invalid'])}
              />
              <div
                {...containerProps}
                className={commonCn(inputInputVariants({}), 'ps-relative ps-flex ps-items-center')}
              >
                <span aria-hidden={true}>&#8202;</span> {/* workaround to trap selectable region */}
                {segments.map((segment) => (
                  <React.Fragment key={segment.props.id}>
                    <span {...segment.props} />
                    {segment.dividerProps && <span {...segment.dividerProps} />}
                  </React.Fragment>
                ))}
                <span aria-hidden={true}>&#8202;</span> {/* workaround to trap selectable region */}
              </div>

              {formattedDate && (
                <div className={'ps-absolute ps-bottom-0 ps-left-12 ps-top-0 ps-flex ps-items-center ps-pr-2'}>
                  <IconButton
                    className={commonCn(
                      'ps-invisible group-focus-within:ps-visible group-hover:ps-visible ps-h-3 ps-w-3 ps-p-[3px]',
                      disabled && '!ps-text-gray-900 '
                    )}
                    onClick={() => navigator.clipboard.writeText(formattedDate)}
                  >
                    <Copy size={18} />
                  </IconButton>
                </div>
              )}

              <div className={'ps-absolute ps-bottom-0 ps-right-0 ps-top-0 ps-flex ps-items-center ps-pr-2'}>
                {handleClear && !disabled && (
                  <IconButton
                    onClick={handleClear}
                    role={'clear-input'}
                    data-testid={'clear-button'}
                    className={
                      'ps-invisible ps-h-3 ps-w-3 ps-p-[3px] group-focus-within:ps-visible group-hover:ps-visible'
                    }
                  >
                    <span className={'ps-sr-only'}>Clear input</span>
                    <X />
                  </IconButton>
                )}
                <Popover.Trigger asChild disabled={disabled}>
                  <IconButton
                    role={'open-picker'}
                    className={commonCn('ps-h-3 ps-w-3 ps-p-[3px]', disabled && '!ps-text-gray-900')}
                    data-testid={'open-picker-button'}
                  >
                    <span className={'ps-sr-only'}>Open datepicker</span>
                    <CalendarIcon size={18} />
                  </IconButton>
                </Popover.Trigger>
              </div>
            </div>
          )}
        </FieldWrapper>
      </Popover.Anchor>
      <Popover.Content
        align={'start'}
        onPointerDownOutside={(e) => e.target === document.body.parentElement && e.preventDefault()}
        className={'ps-bg-white'}
      >
        <Calendar
          mode="single"
          initialFocus
          selected={valueAsDate ?? undefined}
          onSelect={(date) => {
            if (date) handleChangeValue(format(date, 'yyyy-MM-dd'));
            setPopoverOpen(false);
          }}
          {...calendarProps}
        />
      </Popover.Content>
    </Popover>
  );
};

const MemoizedDateField = React.memo(React.forwardRef(DateFieldInner));
MemoizedDateField.displayName = 'DateField';

// export const DateField = Object.assign(MemoizedDateField, { Skeleton: FieldSkeleton });
export const DateField = MemoizedDateField;
export default DateField;
