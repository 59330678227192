import React, { FC } from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { commonCn } from '@utils/cn';

const AvatarVariants = cva(
  'ps-mr-1 ps-flex ps-flex-shrink-0 ps-items-center ps-justify-center ps-overflow-hidden ps-bg-gray-800 ps-text-center ps-text-lg ps-text-white',
  {
    variants: {
      variant: {
        square: 'ps-rounded-none',
        rounded: 'ps-rounded-full',
      },
      size: {
        xxs: 'ps-h-2 ps-w-2',
        xs: 'ps-h-3 ps-w-3',
        small: 'ps-h-4 ps-w-4',
        medium: 'ps-h-5 ps-w-5',
        large: 'ps-h-6 ps-w-6',
        xl: 'ps-h-7 ps-w-7',
      },
    },
    defaultVariants: {
      variant: 'rounded',
      size: 'small',
    },
  }
);

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof AvatarVariants> {
  children?: React.ReactNode;
  src?: string;
  alt?: string;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}

export const Avatar: FC<AvatarProps> = ({ children, size, src, alt, imgProps, variant, className, ...restProps }) => {
  const fallbackContent = children ? children : alt ? alt.charAt(0).toUpperCase() : '-';

  return (
    <div className={commonCn(AvatarVariants({ size, variant, className }), className)} {...restProps}>
      {src ? (
        <img
          src={src}
          alt={alt}
          className="ps-fit-full ps-w-fit ps-w-full ps-object-cover"
          {...imgProps}
          onError={(e) => {
            e.currentTarget.style.display = 'none';
            if (e.currentTarget.parentElement && typeof fallbackContent === 'string') {
              e.currentTarget.parentElement.textContent = fallbackContent;
            }
          }}
        />
      ) : (
        fallbackContent
      )}
    </div>
  );
};

export default Avatar;
