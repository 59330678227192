const wordDeclension = (value: number, textForms: string[]): string => {
  if (value === 1) return textForms[0];
  else if (value > 1 && value < 5) return textForms[1];
  else return textForms[2];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _monthsSuffix = (value: number) => wordDeclension(value, ['mesiac', 'mesiaca', 'mesiacov']);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _yearsSuffix = (value: number) => wordDeclension(value, ['rok', 'roky', 'rokov']);
