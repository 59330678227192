import React, { FC } from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  type ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import merge from 'lodash/merge';
import { Line } from 'react-chartjs-2';
import { commonCn, numberFormat } from '@utils';

interface LineChartProps {
  data: any;
  height?: number;
  options?: ChartOptions<'line'>;
  isCustomLegend?: boolean;
  className?: string;
}

export const LineChart: FC<LineChartProps> = ({
  data,
  isCustomLegend = false,
  className,
  options: customOptions,
  ...other
}) => {
  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        display: !isCustomLegend,
        position: 'bottom',
        onClick: undefined,
      },
      tooltip: {
        intersect: false,
        position: 'nearest',
        callbacks: {
          label: (context) => context.dataset.label + ': ' + numberFormat.money(context.raw as string),
        },
      },
    },
    interaction: {
      mode: 'index',
    },

    elements: {
      point: {
        radius: 0,
      },
    },

    scales: {
      x: {
        min: 0,
        ticks: {
          major: {
            enabled: true,
          },
        },
      },

      y: {
        min: 0,
        ticks: {
          major: {
            enabled: true,
          },
        },
      },
    },
  };

  const opt: ChartOptions<'line'> = merge(options, customOptions);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

  return (
    <div className={commonCn('ps-bg-inherit', className)}>
      <Line data={data} options={opt} {...other} />
    </div>
  );
};

export default LineChart;
