import React, { ReactNode, useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { IconButton, IconButtonProps } from '@components/icon-button';
import { MuiStack as Stack } from '@components/stack';
import { commonCn } from '@utils/cn';

export interface ScrollableWithArrowsProps {
  children: (props: { onScroll: React.UIEventHandler; ref: React.Ref<any> }) => ReactNode;
  className?: string;
  numberOfItems: number;
  leftOffset?: number;
  hideAll?: boolean;
  arrowsPosition?: 'start' | 'end' | 'center';
  chevron?: React.FC<ChevronProps>;
}

export interface ChevronProps extends Omit<IconButtonProps, 'type'> {
  type?: string;
}

const DefaultChevron: React.FC<ChevronProps> = ({ type = 'right', className, ...props }) => {
  return (
    <IconButton
      size={'medium'}
      {...props}
      className={commonCn('ps-pointer-events-auto ps-m-2 ps-shadow-md', className)}
    >
      {type === 'right' ? <ChevronRight /> : <ChevronLeft />}
    </IconButton>
  );
};

const ScrollableWithArrowsInner: React.FC<ScrollableWithArrowsProps> = ({
  children,
  className,
  numberOfItems = 10,
  leftOffset = 0,
  hideAll = false,
  arrowsPosition = 'center',
  chevron: Chevron = DefaultChevron,
}) => {
  const parent = useRef<HTMLElement>(null);

  const [leftVisible, setLeftVisible] = useState<boolean>(false);
  const [rightVisible, setRightVisible] = useState<boolean>(true);
  const [scrollPositions, setScrollPositions] = useState<number[]>([]);
  const [maxScrollWidth, setMaxScrollWidth] = useState<number>(0);

  useEffect(() => {
    const scrollWidth = (parent?.current?.scrollWidth ?? 0) - leftOffset;
    const newMaxScrollWidth = (parent?.current?.scrollWidth ?? 0) - (parent.current?.clientWidth ?? 0);
    const step = scrollWidth / numberOfItems;
    const currentScrollPos = parent?.current?.scrollLeft;

    const newScrollPositions = Array(numberOfItems)
      .fill(0)
      .map((_, index) => round(step * (index + 1)))
      .filter((pos) => {
        return pos <= newMaxScrollWidth;
      });

    if (isNil(currentScrollPos)) {
      setLeftVisible(false);
      setRightVisible(false);
      return;
    }

    setLeftVisible(currentScrollPos > minScroll);
    setRightVisible(currentScrollPos < newMaxScrollWidth);

    setScrollPositions(newScrollPositions);
    setMaxScrollWidth(newMaxScrollWidth);
  }, [parent.current?.scrollWidth, numberOfItems, leftOffset]);

  const gradientBoxWidth = 100;
  const minScroll = 0;

  const handleScroll = (event: any) => {
    const currentScrollPos = round(event.target.scrollLeft);
    const maxScrollWidth = (parent.current?.scrollWidth ?? 0) - (parent.current?.clientWidth ?? 0);

    setLeftVisible(currentScrollPos > minScroll);
    setRightVisible(currentScrollPos < maxScrollWidth);
  };

  const findNewScrollPositionRight = () => {
    const currentScrollPos = parent?.current?.scrollLeft ?? 0;
    return scrollPositions.find((scrollPos) => currentScrollPos < scrollPos) ?? maxScrollWidth;
  };

  const findNewScrollPositionLeft = () => {
    const currentScrollPos = parent?.current?.scrollLeft ?? 0;
    return (
      scrollPositions
        .slice()
        .reverse()
        .find((scrollPos) => currentScrollPos > scrollPos) ?? minScroll
    );
  };

  const scrollToCallback = (getScrollPos: () => number) => {
    return () => parent?.current?.scrollTo({ left: getScrollPos(), behavior: 'smooth' });
  };

  return (
    <Stack direction={'row'} className={commonCn('ps-relative', className)}>
      {leftVisible && !hideAll ? (
        <div
          className={
            'ps-pointer-events-none ps-absolute ps-top-0 ps-z-[4] ps-flex ps-h-full ps-items-center ps-justify-start ps-bg-gradient-to-r ps-from-white ps-to-transparent'
          }
          style={{ zIndex: 4, left: leftOffset, width: gradientBoxWidth, alignItems: arrowsPosition }}
        >
          <Chevron type={'left'} onClick={scrollToCallback(findNewScrollPositionLeft)} />
        </div>
      ) : null}

      {children({ onScroll: handleScroll, ref: parent })}

      {rightVisible && !hideAll ? (
        <div
          className={
            'ps-pointer-events-none ps-absolute ps-right-0 ps-top-0 ps-flex ps-h-full ps-justify-end ps-bg-gradient-to-r ps-from-transparent ps-from-30% ps-to-white ps-to-100%'
          }
          style={{ alignItems: arrowsPosition, width: gradientBoxWidth }}
        >
          <Chevron onClick={scrollToCallback(findNewScrollPositionRight)} />
        </div>
      ) : null}
    </Stack>
  );
};

export const ScrollableWithArrows = Object.assign(ScrollableWithArrowsInner, { Chevron: DefaultChevron });
