import * as RadixDialog from '@radix-ui/react-dialog';
import { Body } from '@components/modal/body';
import { Content } from '@components/modal/content';
import { Description } from '@components/modal/description';
import { Footer } from '@components/modal/footer';
import { Close, Header, Title } from '@components/modal/header';
import { Root } from '@components/modal/root';

export const Modal = Object.assign(Root, {
  Trigger: RadixDialog.Trigger,
  Close: Close,
  Content: Content,
  Body: Body,
  Header: Header,
  Footer: Footer,
  Title: Title,
  Description: Description,
});

export default Modal;
