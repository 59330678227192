import { useCallback, useMemo, useState } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { useStateInSearchParams } from '@hooks/use-state-is-search-params';

type UseModalStateProps = { persist?: boolean; defaultOpen?: boolean };
export type ModalState = { isOpen: boolean };

type UseModalStateWithDataProps<T> =
  | { persist?: boolean; defaultOpen?: true; defaultData: T }
  | { persist?: boolean; defaultOpen?: false; defaultData?: T };
export type ModalStateWithData<T> = { isOpen: true; data: T } | { isOpen: false; data: undefined };

const DEFAULT_OPEN = false as const;

const usePersistentModalState = <
  TState extends ModalState | ModalStateWithData<TModalStateData>,
  TModalStateData = TState extends ModalStateWithData<infer T> ? T : never,
>(
  id: string,
  options?: { defaultOpen?: boolean; defaultData?: TModalStateData }
) => {
  const [modalState, _setModalState] = useStateInSearchParams(`modal-${id}`, {
    isOpen: options?.defaultOpen ?? DEFAULT_OPEN,
    data: options?.defaultData,
  } as TState);

  const navigate = useNavigate();
  const { key: locationKey } = useLocation();

  const isFirstOpenRoute = window.history.length === 0 || locationKey === 'default';

  const setModalState = useCallback(
    (newState: TState, options?: NavigateOptions) => {
      // Close modal by navigating back if it's open
      if (modalState.isOpen && !newState.isOpen && !isFirstOpenRoute) return navigate(-1);

      _setModalState(newState, options);
    },
    [modalState.isOpen, _setModalState, navigate, isFirstOpenRoute]
  );

  return [modalState, setModalState] as const;
};

const useNonPersistentModalState = <
  TState extends ModalState | ModalStateWithData<TModalStateData>,
  TModalStateData = TState extends ModalStateWithData<infer T> ? T : never,
>(
  id: string,
  options?: { defaultOpen?: boolean; defaultData?: TModalStateData }
) => {
  const [modalState, setModalState] = useState({
    isOpen: options?.defaultOpen ?? DEFAULT_OPEN,
    data: options?.defaultData,
  } as TState);

  return [modalState, setModalState] as const;
};

export const useModalStateWithData = <TData>(id: string, options?: UseModalStateWithDataProps<TData>) => {
  const useInternalState = options?.persist ?? true ? usePersistentModalState : useNonPersistentModalState;
  const [modalState, setModalState] = useInternalState<ModalStateWithData<TData>>(id, options);

  const open = useCallback((data: TData) => setModalState({ isOpen: true, data }), [setModalState]);
  const close = useCallback(() => setModalState({ isOpen: false, data: undefined }), [setModalState]);
  const setData = useCallback(
    (data: TData) => {
      if (!modalState.isOpen) throw new Error('Cannot set data on a closed modal');
      setModalState({ isOpen: true, data }, { replace: true });
    },
    [modalState, setModalState]
  );

  return useMemo(() => ({ ...modalState, open, close, setData }), [close, modalState, open, setData]);
};

export const useModalState = (id: string, options?: UseModalStateProps) => {
  const useInternalState = options?.persist ?? true ? usePersistentModalState : useNonPersistentModalState;
  const [modalState, setModalState] = useInternalState<ModalState>(id, options);

  const open = useCallback(() => setModalState({ isOpen: true }), [setModalState]);
  const close = useCallback(() => setModalState({ isOpen: false }), [setModalState]);

  return useMemo(() => ({ ...modalState, open, close }), [close, modalState, open]);
};
