import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import isNil from 'lodash/isNil';
import {
  PaperPropsType,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableVariantsProps,
} from '@components';
import Typography from '@components/typography';
import { commonCn } from '@utils/cn';

// tableVariants({ size, className })

const EnhancedTableVariants = cva('', {
  variants: {
    variant: {
      default: '',
      primary: '[&_thead]:ps-bg-gray-200',
      primaryWhite: '[&_thead]:ps-bg-white',
      secondary:
        '[&_tr>:first-of-type:after]:ps-z-1 [&_tr>:first-of-type]:ps-border-r-1 [&_tr>:first-of-type]:ps-sticky  [&_tr>:first-of-type]:ps-left-0 [&_tr>:first-of-type]:ps-z-10 [&_tr>:first-of-type]:ps-bg-white [&_tr>:nth-child(even)]:ps-bg-gray-100',
    },
  },
});

const EnhancedTableContainerVariants = cva('', {
  variants: {
    variant: {
      default: '',
      primary: 'ps-rounded ps-border ps-border-solid ps-border-gray-400',
      primaryWhite: 'ps-rounded ps-border ps-border-solid ps-border-gray-400',
      secondary: '',
    },
  },
});

type EnhancedTableVariantsProps = VariantProps<typeof EnhancedTableVariants>;

export type EnhancedTableBodyProps = React.ComponentPropsWithoutRef<typeof TableContainer> &
  TableVariantsProps &
  EnhancedTableVariantsProps & {
    children: Array<React.ReactNode> | React.ReactNode;
    elevation?: PaperPropsType['elevation'];
    tableHead?: React.ReactNode;
    isLoading?: boolean;
    isHeaderLoading?: boolean;
    numberOfSkeletonRows?: number;
    emptyViewText?: string;
    isEmpty?: boolean;
    className?: string;
    containerClassName?: string;
  };

const EnhancedTableInner = (
  {
    isLoading = false,
    isHeaderLoading = false,
    children,
    numberOfSkeletonRows,
    emptyViewText = 'Žiadne údaje na zobrazenie',
    tableHead,
    isEmpty = false,
    size = 'medium',
    elevation = 0,
    className,
    containerClassName,
    variant = 'default',
    ...rest
  }: EnhancedTableBodyProps,
  ref: React.ForwardedRef<React.ElementRef<typeof TableContainer>>
) => {
  if (isLoading)
    return (
      <TableSkeleton
        numberOfSkeletonRows={Array.isArray(children) ? children?.length || numberOfSkeletonRows : numberOfSkeletonRows}
        tableHead={tableHead}
        size={size}
        variant={variant}
        elevation={elevation}
        isHeaderLoading={isHeaderLoading}
        containerClassName={containerClassName}
      />
    );
  if (isEmpty)
    return (
      <TableContainer
        elevation={elevation}
        className={commonCn(
          'ps-bg-white ps-p-3',
          EnhancedTableContainerVariants({ variant, className: containerClassName }),
          containerClassName
        )}
      >
        <Typography variant={'body2'}>{emptyViewText}</Typography>
      </TableContainer>
    );

  return (
    <TableContainer
      ref={ref}
      elevation={elevation}
      className={commonCn(
        'ps-bg-white',
        EnhancedTableContainerVariants({ variant, className: containerClassName }),
        containerClassName
      )}
      {...rest}
    >
      <Table size={size} className={commonCn(EnhancedTableVariants({ variant, className }), className)}>
        {tableHead}
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

type EnchancedTableSkeleton = Pick<
  EnhancedTableBodyProps,
  'numberOfSkeletonRows' | 'tableHead' | 'size' | 'variant' | 'containerClassName' | 'elevation' | 'isHeaderLoading'
>;

export const TableSkeleton: React.FC<EnchancedTableSkeleton> = ({
  numberOfSkeletonRows,
  tableHead,
  size,
  variant,
  elevation,
  isHeaderLoading,
  containerClassName,
}) => {
  return (
    <TableContainer
      elevation={elevation}
      className={commonCn(
        'ps-bg-white',
        EnhancedTableContainerVariants({ variant, className: containerClassName }),
        containerClassName
      )}
    >
      <Table size={size} className={commonCn(EnhancedTableVariants({ variant }))}>
        {isHeaderLoading || isNil(tableHead) ? (
          <TableHeader>
            <TableRow>
              <TableHead colSpan={100}>
                <Skeleton />
              </TableHead>
            </TableRow>
          </TableHeader>
        ) : (
          tableHead
        )}
        <TableBody>
          <TableRow>
            <TableCell colSpan={100} className={'[&_div:last-child]:ps-m-0'}>
              {[...Array(numberOfSkeletonRows).keys()].map((index) => {
                return <Skeleton className={'ps-mb-1'} key={index} height={size === 'small' ? 30 : 50} />;
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ForwardedEnhancedTable = React.forwardRef(EnhancedTableInner);

export const EnhancedTable = ForwardedEnhancedTable as typeof ForwardedEnhancedTable & {
  Skeleton: typeof TableSkeleton;
};

Object.assign(EnhancedTable, {
  Skeleton: TableSkeleton,
});
