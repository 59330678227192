import React, { ReactNode } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import IconButton from '@components/icon-button';
import { commonCn } from '@utils/cn';
import { PopupContext, getPopupZIndex } from '@utils/popup-context';

export interface TopBarProps {
  logo?: ReactNode;
  onMobileNavOpen?: () => void;
  children?: ReactNode;
  className?: string;
}

const TOP_BAR_POPUP_LEVEL = 100;
export const TOP_BAR_HEIGHT = 64;

export const TopBar: React.FC<TopBarProps> = ({ logo, onMobileNavOpen, children, className }) => {
  return (
    <PopupContext.Provider value={{ popupLevel: TOP_BAR_POPUP_LEVEL }}>
      <header
        style={{ zIndex: getPopupZIndex(TOP_BAR_POPUP_LEVEL), height: TOP_BAR_HEIGHT }}
        className={commonCn(
          'ps-fixed ps-left-0 ps-right-0 ps-top-0 ps-flex ps-w-full ps-items-center ps-bg-gray-900 ps-pl-3 ps-pr-5 ps-text-white sm:ps-pl-3 sm:ps-pr-5',
          className
        )}
      >
        <div className="ps-border-r-solid ps-flex ps-h-full ps-max-w-[246px] ps-items-center ps-justify-center ps-border-r ps-border-r-gray-800 ps-pr-3">
          <IconButton
            onClick={onMobileNavOpen}
            size="large"
            className={'ps-text-[inherit] focus:ps-ring-blue lg:ps-hidden'}
          >
            <MenuIcon size={20} />
          </IconButton>
          <div className={'ps-hidden lg:ps-block'}>{logo}</div>
        </div>
        {children}
      </header>
    </PopupContext.Provider>
  );
};
