import React, { ReactNode, createContext, useContext } from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { Button, ButtonProps } from '@components/button';
import { commonCn } from '@utils/cn';

const toggleButtonVariants = cva('ps-flex ps-max-w-full ps-px-1.5 ps-py-1', {
  variants: {
    variant: {
      outlined: 'ps-rounded-lg ps-font-semibold ps-no-underline aria-selected:ps-bg-gray-100 ',
      contained:
        'ps-rounded-lg ps-bg-white !ps-font-normal ps-text-black ps-no-underline hover:ps-no-underline active:ps-no-underline aria-selected:ps-bg-blue aria-selected:ps-text-white',
    },
  },
  defaultVariants: {
    variant: 'outlined',
  },
});

const toggleButtonGroupVariants = cva(
  'ps-flex ps-w-auto ps-max-w-full ps-flex-row ps-items-end ps-gap-1 ps-rounded-xl ps-bg-gray-200 ps-p-0.5',
  {
    variants: {
      view: {
        primary: '',
        secondary: 'ps-bg-transparent ps-p-0',
      },
    },
    defaultVariants: {
      view: 'primary',
    },
  }
);

type ToggleButtonGroupProps = VariantProps<typeof toggleButtonGroupVariants> & {
  value: string | null;
  onChange: (value: string) => void;
  exclusive?: boolean;
  children: ReactNode;
  color?: 'primary' | 'error' | 'success' | 'gray' | null | undefined;
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'contained';
};

type ToggleButtonGroupContextType = {
  value: string | null;
  onChange: (value: string) => void;
  exclusive: boolean;
  color: 'primary' | 'error' | 'success' | 'gray' | null | undefined;
  variant?: 'outlined' | 'contained';
  disabled?: boolean;
  children: ReactNode;
};

// Create a context for the ToggleButtonGroup
const ToggleButtonGroupContext = createContext<ToggleButtonGroupContextType | undefined>(undefined);

const ToggleButtonGroupProvider: React.FC<ToggleButtonGroupContextType> = ({ ...props }) => {
  return <ToggleButtonGroupContext.Provider value={props}>{props.children}</ToggleButtonGroupContext.Provider>;
};

export const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  value,
  onChange,
  exclusive = true,
  children,
  color = 'primary',
  variant = 'outlined',
  view = 'primary',
  className,
  ariaLabel,
  disabled,
}) => {
  return (
    <ToggleButtonGroupProvider
      onChange={onChange}
      color={color}
      value={value}
      variant={variant || 'outlined'}
      exclusive={exclusive}
      disabled={disabled}
    >
      <div role="group" aria-label={ariaLabel} className={commonCn(toggleButtonGroupVariants({ view, className }))}>
        {children}
      </div>
    </ToggleButtonGroupProvider>
  );
};

interface ToggleButtonProps
  extends Omit<ButtonProps, 'onClick' | 'variant'>,
    VariantProps<typeof toggleButtonVariants> {
  value: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => void;
  selected?: boolean;
  disabled?: boolean;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  value,
  onClick,
  selected = false,
  children,
  className,
  variant = 'outlined',
  disabled,
}) => {
  const context = useContext(ToggleButtonGroupContext);

  if (!context) {
    throw new Error('ToggleButton must be used within a ToggleButtonGroup');
    return null;
  }

  const { onChange, exclusive, color, disabled: groupDisabled, variant: groupVariant, value: groupValue } = context;

  const handleClick = () => {
    if (exclusive) {
      value !== groupValue && onChange(value);
    } else {
      onChange(value);
    }
  };

  const isSelected = !selected ? value === groupValue : selected;
  const isDisabled = disabled || groupDisabled;

  return (
    <Button
      type="button"
      color={color}
      aria-selected={isSelected}
      variant={variant}
      disabled={isDisabled}
      className={commonCn(
        toggleButtonVariants({ variant, className }),
        'ps-h-auto ps-flex-1 ps-whitespace-normal ps-normal-case',
        !isSelected ? 'ps-text-gray-900 ps-font-normal ps-border-gray-200' : ''
      )}
      onClick={(event) => {
        if (onClick) onClick(event, value);
        else handleClick();
      }}
    >
      {children}
    </Button>
  );
};
