import { useEffect, useState } from 'react';

export function useIsPlaintextContenteditableSupported() {
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    const div = document.createElement('div');
    try {
      div.contentEditable = 'plaintext-only';
      setIsSupported(div.isContentEditable);
    } catch (e) {
      setIsSupported(false);
    }
  }, []);

  return isSupported;
}
