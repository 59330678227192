import React, { ReactNode, useState } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from '@components/button';
import { Spinner } from '@components/spinner';
import { TOP_BAR_HEIGHT } from '@components/top-bar';
import { Typography } from '@components/typography';
import { commonCn } from '@utils/cn';
import { WithPopupContextProps, withPopupContext } from '@utils/popup-context';

// To avoid topbar and menu overflow
export const MENU_COLLISION_TOP_PADDING = TOP_BAR_HEIGHT + 6;
const MENU_COLLISION_PADDING_DEFAULT = { top: MENU_COLLISION_TOP_PADDING };

export type DropdownMenuItem = Omit<React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>, 'onClick'> & {
  component: ReactNode;
  hidden?: boolean;
  onClick?: (
    event: React.SyntheticEvent,
    item: DropdownMenuItem,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  url?: string;
};

export type DropdownBaseProps<T> = {
  id: string;
  isLoading?: boolean;
  items?: DropdownMenuItem[];
  children: T;
  content?: ReactNode;
  className?: string;
  menuClassName?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export type DropdownMenuProps<T extends ReactNode> = T extends string
  ? DropdownBaseProps<T> & ButtonProps
  : DropdownBaseProps<T>;

export const DropdownMenu = <T extends ReactNode>({
  id,
  items,
  children,
  className,
  open,
  content,
  menuClassName,
  onOpenChange,
  isLoading,
  ...rest
}: DropdownMenuProps<T>) => {
  const [isOpenLocal, setIsOpenLocal] = useState<boolean>(!!open);

  const menuItems = isLoading
    ? [
        {
          component: (
            <div className={'ps-flex ps-items-center ps-justify-center ps-w-full'}>
              <Spinner size={'14'} />
            </div>
          ),
        } as DropdownMenuItem,
      ]
    : items;

  return (
    <DropdownMenuPrimitive.Root open={open ?? isOpenLocal} onOpenChange={onOpenChange ?? setIsOpenLocal}>
      <DropdownMenuPrimitive.Trigger className={className} asChild>
        {typeof children === 'string' ? (
          <Button id={id} className={className} {...rest}>
            {children}
          </Button>
        ) : (
          children
        )}
      </DropdownMenuPrimitive.Trigger>

      {content ? (
        content
      ) : (
        <DropdownMenuContent className={menuClassName}>
          {(menuItems ?? []).map((item, index) => {
            const { onClick, component, hidden = false, url, ...rest } = item;
            const isInternalUrl = (url: string | undefined) => {
              if (url) return url.startsWith('/');
            };
            const isInternal = isInternalUrl(url);
            if (hidden) return null;

            const content = (
              <DropdownMenuItem
                onClick={(event) => {
                  if (!isNil(open)) event.preventDefault();
                  if (onClick) onClick(event, item, setIsOpenLocal);
                  else if (!url) event.preventDefault();
                }}
                className={!onClick ? 'ps-cursor-default' : ''}
                {...rest}
              >
                {typeof component === 'string' ? <Typography>{component}</Typography> : component}
              </DropdownMenuItem>
            );

            return (
              <React.Fragment key={index}>
                {url ? (
                  <Link
                    to={url}
                    target={isInternal ? undefined : '_blank'}
                    rel={isInternal ? undefined : 'noopener noreferrer'}
                    key={index}
                  >
                    {content}
                  </Link>
                ) : (
                  content
                )}
              </React.Fragment>
            );
          })}
        </DropdownMenuContent>
      )}
    </DropdownMenuPrimitive.Root>
  );
};

const DropdownMenuContentInner = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & WithPopupContextProps
>(
  (
    {
      className,
      sideOffset = 0,
      children,
      align = 'start',
      zIndex,
      collisionPadding = MENU_COLLISION_PADDING_DEFAULT,
      ...props
    },
    ref
  ) => (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        align={align}
        collisionPadding={collisionPadding}
        className={commonCn(
          'ps-bg-popover ps-text-popover-foreground ps-max-h-[calc(var(--radix-dropdown-menu-content-available-height))] ps-min-w-[8rem] ps-overflow-auto ps-rounded-md ps-border ps-bg-white ps-py-1 ps-shadow-md',
          'data-[state=open]:ps-animate-in data-[state=open]:ps-fade-in-0 data-[state=open]:ps-zoom-in-95',
          'data-[state=closed]:ps-animate-out data-[state=closed]:ps-fade-out-0 data-[state=closed]:ps-zoom-out-95',
          'data-[side=bottom]:ps-slide-in-from-top-2 data-[side=left]:ps-slide-in-from-right-2 data-[side=right]:ps-slide-in-from-left-2 data-[side=top]:ps-slide-in-from-bottom-2',
          className
        )}
        {...props}
        style={{ zIndex, ...props.style }}
      >
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  )
);
DropdownMenuContentInner.displayName = DropdownMenuPrimitive.Content.displayName;

export const DropdownMenuContent = withPopupContext(DropdownMenuContentInner);

export const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={commonCn(
      'ps-flex ps-cursor-pointer ps-select-none ps-items-center ps-px-2 ps-py-0.5 ps-outline-none data-[disabled]:ps-pointer-events-none',
      'data-[disabled]:ps-opacity-50',
      'focus:ps-bg-accent focus:ps-text-accent',
      'hover:ps-bg-gray-100',
      className
    )}
    {...props}
  />
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
