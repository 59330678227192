import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { ArrowUp } from 'react-feather';
import { Paper, TypographyVariantsProps, typographyVariantsProsight } from '@components';
import { IconButton } from '@components/icon-button';
import { DEFUALT_TYPOGRAPHY_TABLE_HEAD } from '@components/typography/constants';
import { commonCn } from '@utils/cn';

const TableVariants = cva(
  'ps-w-full ps-caption-bottom ps-border-collapse ps-border-none ps-text-left [&_tbody>tr:last-child]:ps-border-b-0',
  {
    variants: {
      size: {
        medium: '[&_tr>td]:ps-p-2 [&_tr>th]:ps-p-2',
        small: '[&_tr>td]:ps-px-2 [&_tr>td]:ps-py-1 [&_tr>th]:ps-px-2 [&_tr>th]:ps-py-1',
      },
      defaultVariants: {
        size: 'medium',
      },
    },
  }
);

export type TableVariantsProps = VariantProps<typeof TableVariants>;

const TableContainer = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof Paper>>(
  ({ className, ...props }, ref) => (
    <Paper ref={ref} className={commonCn('ps-relative ps-w-full ps-overflow-auto ', className)} {...props} />
  )
);
TableContainer.displayName = 'TableContainer';

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & { size?: TableVariantsProps['size'] }
>(({ className, size = 'medium', ...props }, ref) => (
  <table ref={ref} className={TableVariants({ size: size, className })} {...props} />
));
Table.displayName = 'Table';

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <thead ref={ref} className={commonCn(className)} {...props} />
);
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <tbody ref={ref} className={commonCn(className)} {...props} />
);
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <tfoot ref={ref} className={commonCn(className)} {...props} />
);
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement> & { hover?: boolean }>(
  ({ className, hover, ...props }, ref) => (
    <tr
      ref={ref}
      className={commonCn(
        hover ? 'ps-cursor-pointer hover:ps-bg-gray-100' : '',
        'ps-border-b-[1px] ps-border-l-0 ps-border-r-0 ps-border-t-0 ps-border-solid ps-border-gray-400',
        className
      )}
      {...props}
    />
  )
);
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & TypographyVariantsProps
>(({ variant, className, gutterBottom, color, noWrap, align, ...props }, ref) => {
  return (
    <th
      className={commonCn(
        typographyVariantsProsight({
          variant: variant ?? DEFUALT_TYPOGRAPHY_TABLE_HEAD,
          className,
          gutterBottom,
          color,
          noWrap,
          align,
        }),
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

TableHead.displayName = 'TableHead';
const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & TypographyVariantsProps
>(({ variant, align, color, gutterBottom, noWrap, className, ...props }, ref) => {
  return (
    <td
      ref={ref}
      className={commonCn(
        typographyVariantsProsight({ variant, className, gutterBottom, color, noWrap, align }),
        className
      )}
      {...props}
    />
  );
});

TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => <caption ref={ref} className={commonCn(className)} {...props} />
);
TableCaption.displayName = 'TableCaption';

export type TableSortLabelProps = {
  active: boolean;
  direction?: 'asc' | 'desc';
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
};

const TableSortLabel: React.FC<TableSortLabelProps> = ({ className, active, children, direction, onClick }) => {
  return (
    <span
      onClick={onClick}
      className={commonCn('ps-group ps-flex ps-shrink-0 ps-cursor-pointer ps-items-center', className)}
    >
      {children}
      <IconButton size={'small'} className={commonCn(direction === 'desc' ? 'ps-rotate-180' : '')}>
        <ArrowUp className={active ? '' : 'ps-hidden  group-hover:ps-block'} />
      </IconButton>
    </span>
  );
};

export {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
  TableSortLabel,
};
