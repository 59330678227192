import React, { useCallback } from 'react';
import { useField } from 'formik';
import { useFieldContext } from '@components/field/field-context';
import { DateField, DateFieldProps } from './date-field';

export type DateFieldWithFormikProps = Omit<Partial<DateFieldProps>, 'onChange'> & {
  name: string;
  onChange?: (value: string | null, defaultAction: () => void) => void;
};

export const DateFieldWithFormik: React.FC<DateFieldWithFormikProps> = (props) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<any>(props.name);
  const { isLoading, disabled } = useFieldContext();

  const propsOnChange = props.onChange;
  const handleChange = useCallback(
    (value: string | null) => {
      const newValue = value === '' ? null : value;

      const defaultAction = () => {
        void setTouched(true, false);
        void setValue(newValue);
      };

      if (propsOnChange) propsOnChange(newValue, defaultAction);
      else defaultAction();
    },
    [setValue, setTouched, propsOnChange]
  );

  return (
    <DateField
      {...{
        ...props,
        value: props.value ?? value ?? '',
        onChange: handleChange,
        error: props.error ? props.error : touched ? error : undefined,
        isLoading: props.isLoading || isLoading,
        disabled: props.disabled || disabled,
      }}
    />
  );
};
