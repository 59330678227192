import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import { MultipleAutocompleteOptionConstrain } from '@components/field/autocomplete/multiple-autocomplete/multiple-autocomplete';
import useAutocompleteBase from '@components/field/autocomplete/use-autocomplete-base';

interface MultipleAutocompleteHookProps<T extends MultipleAutocompleteOptionConstrain> {
  value: T[] | null;
  onChange: (e: SyntheticEvent, value: T[]) => void;
  options?: T[];
  propsGetOptionLabel?: (option: T) => string;
  propsGetOptionCode?: (option: T) => string | number;
  propsOnClear?: () => void;
}

const useMultipleAutocomplete = <T extends MultipleAutocompleteOptionConstrain>({
  value: propValue,
  options: propsOptions = [],
  propsGetOptionLabel,
  propsGetOptionCode,
  propsOnClear,
  onChange,
}: MultipleAutocompleteHookProps<T>) => {
  const {
    getOptionLabel,
    getOptionCode,
    isOpen,
    inputValue,
    inputRef,
    virtuosoRef,
    setOpen,
    setInputValue,
    handleClear,
    filteredList,
    menuHeight,
  } = useAutocompleteBase<T>({
    options: propsOptions,
    getOptionLabel: propsGetOptionLabel,
    getOptionCode: propsGetOptionCode,
    onClear: propsOnClear,
  });

  const firstSelectedItem = useMemo(() => {
    const firstSelectedOption = first(propValue);
    const foundIdx = propsOptions.findIndex(
      (opt: T) => getOptionCode(opt) === (!isNil(firstSelectedOption) ? getOptionCode(firstSelectedOption) : null)
    );
    return foundIdx >= 0 ? foundIdx : 0;
  }, [getOptionCode, propValue, propsOptions]);

  const handleElementSelect = useCallback(
    (e: React.SyntheticEvent, option: T) => {
      const selectedOption = propsOptions.find((propsOpt) => getOptionCode(propsOpt) === getOptionCode(option));
      if (selectedOption && !propValue?.includes(selectedOption)) {
        onChange(e, !isNil(propValue) ? [...propValue, selectedOption] : [selectedOption]);
        setInputValue('');
      }
    },
    [propsOptions, propValue, getOptionCode, onChange, setInputValue]
  );

  const handleRemoveOption = useCallback(
    (e: React.SyntheticEvent, option: T) => {
      const updatedValues = propValue?.filter((value) => getOptionCode(value) !== getOptionCode(option));
      onChange(e, updatedValues ?? []);
    },
    [propValue, onChange, getOptionCode]
  );

  const toggleOption = useCallback(
    (e: React.SyntheticEvent, option: T) => {
      if (propValue?.find((value) => getOptionCode(value) === getOptionCode(option))) {
        handleRemoveOption(e, option);
      } else {
        handleElementSelect(e, option);
      }
    },
    [propValue, getOptionCode, handleRemoveOption, handleElementSelect]
  );

  return {
    firstSelectedItem,
    inputRef,
    virtuosoRef,
    isOpen,
    setOpen,
    inputValue,
    setInputValue,
    options: propsOptions,
    getOptionLabel,
    getOptionCode,
    filteredList,
    handleElementSelect,
    handleRemoveOption,
    toggleOption,
    menuHeight,
    handleClear,
  };
};

export default useMultipleAutocomplete;
