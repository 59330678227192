import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { PopupContext, usePopupContext } from '@utils/popup-context';

const MODAL_POPUP_LEVEL = 200;

export const Root: typeof RadixDialog.Root = (props) => {
  const { popupLevel } = usePopupContext();

  return (
    <PopupContext.Provider value={{ popupLevel: MODAL_POPUP_LEVEL + popupLevel }}>
      <RadixDialog.Root {...props} />
    </PopupContext.Provider>
  );
};
