export type FileAccept = 'pdf' | 'img' | 'ms-docs';

export const getAccept = (accept?: FileAccept[]) => {
  if (!accept) return;

  const acceptObj: Record<string, string[]> = {};
  if (accept.includes('pdf')) acceptObj['application/pdf'] = ['.pdf'];
  if (accept.includes('img')) {
    acceptObj['image/jpeg'] = ['.jpg', '.jpeg'];
    acceptObj['image/png'] = ['.png'];
    acceptObj['image/heic'] = ['.heic'];
  }
  if (accept.includes('ms-docs')) {
    acceptObj['application/msword'] = ['.doc', '.docx'];
    acceptObj['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = ['.doc', '.docx'];
    acceptObj['application/vnd.ms-excel'] = ['.xls', '.xlsx'];
    acceptObj['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = ['.xls', '.xlsx'];
  }
  return acceptObj;
};
