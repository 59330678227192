import React, { FC, ReactNode } from 'react';
import { commonCn } from '@utils/cn';

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  fixed?: boolean;
  maxWidth?: string | number | undefined;
  children?: ReactNode;
}

export const Container: FC<ContainerProps> = ({ className, fixed, maxWidth, style, children, ...props }) => {
  const defaultClasses = 'ps-mx-auto ps-w-full';
  const maxWidthClasses = fixed ? 'xl:ps-max-w-xl' : '';
  const combinedStyles = { maxWidth: maxWidth || '', ...style };

  return (
    <div className={commonCn(defaultClasses, maxWidthClasses, className)} style={combinedStyles} {...props}>
      {children}
    </div>
  );
};

export default Container;
