import type { Config } from 'tailwindcss';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const colors = {
  // IMPORTANT: when changing colors, make sure to update them in the theme.colors in `utils/cn.ts`
  transparent: 'transparent',
  current: 'currentColor',

  // Prosight Colors
  white: '#FFFFFF',
  black: '#0E1010',
  orange: {
    DEFAULT: '#F96D1F',
    50: '#FEF0E9',
    100: '#FDD2BA',
    200: '#FCBC98',
    300: '#FB9D69',
    400: '#FA8A4C',
    500: '#F96D1F',
    600: '#E3631C',
    700: '#B14D16',
    800: '#893C11',
    900: '#692E0D',
  },
  purple: {
    DEFAULT: '#8400F0',
    50: '#F3E6FE',
    100: '#D9B0FA',
    200: '#C68AF8',
    300: '#AD54F5',
    400: '#9D33F3',
    500: '#8400F0',
    600: '#7800DA',
    700: '#5E00AA',
    800: '#490084',
    900: '#370065',
  },
  gray: {
    DEFAULT: '#CFD2D9',
    50: '#FCFCFE',
    100: '#FBFBFD',
    200: '#F3F5F9',
    300: '#ECEFF4',
    400: '#E3E7EF',
    500: '#CFD2D9',
    600: '#A1A4AA',
    700: '#5F6164',
    800: '#3E4040',
    900: '#222323',
  },
  blue: {
    DEFAULT: '#3B79F7',
    50: '#EBF2FE',
    100: '#C2D5FD',
    200: '#A5C1FB',
    300: '#7CA5FA',
    400: '#6294F9',
    500: '#3B79F7',
    600: '#366EE1',
    700: '#2A56AF',
    800: '#204388',
    900: '#193368',
  },
  red: {
    DEFAULT: '#ed1e20',
    50: '#FDE9E9',
    100: '#F9B9BA',
    200: '#F79898',
    300: '#F3686A',
    400: '#F14B4D',
    500: '#ED1E20',
    600: '#D81B1D',
    700: '#A81517',
    800: '#821112',
    900: '#640D0D',
  },
  green: {
    DEFAULT: '#05be70',
    50: '#E6F9F1',
    100: '#B2EBD3',
    200: '#8CE1BD',
    300: '#58D39F',
    400: '#37CB8D',
    500: '#05BE70',
    600: '#05AD66',
    700: '#048750',
    800: '#03693E',
    900: '#02502F',
  },
  yellow: {
    DEFAULT: '#FFAB00',
    50: '#FFF7E6',
    100: '#FFE5B0',
    200: '#FFD88A',
    300: '#FFC754',
    400: '#FFBC33',
    500: '#FFAB00',
    600: '#E89C00',
    700: '#B57900',
    800: '#8C5E00',
    900: '#6B4800',
  },
} satisfies NonNullable<Config['theme']>['colors'];
