import React from 'react';
import { ClassValue } from 'clsx';
import { commonCn } from '@utils/cn';

export interface StackProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly' | 'stretch';
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  gap?: number | string;
  children?: React.ReactNode | React.ReactNode[];
  className?: ClassValue;
}

export const MuiStack = React.forwardRef<HTMLDivElement, StackProps>(
  ({ direction = 'column', justifyContent, alignItems, gap, className, children, ...rest }, ref) => {
    return (
      <div
        className={commonCn(
          'ps-flex',
          direction === 'row' && 'ps-flex-row',
          direction === 'column' && 'ps-flex-col',
          direction === 'row-reverse' && 'ps-flex-row-reverse',
          direction === 'column-reverse' && 'ps-flex-col-reverse',
          justifyContent && `ps-justify-${justifyContent}`,
          alignItems && `ps-items-${alignItems}`,
          className
        )}
        style={{ gap: typeof gap === 'number' ? gap * 8 : gap }}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
MuiStack.displayName = 'MuiStack';

export default MuiStack;
