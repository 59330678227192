import * as React from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import { Spinner } from '@components/spinner';
import { commonCn } from '@utils/cn';

const iconButtonVariants = cva(
  'ps-inline-flex ps-cursor-pointer ps-select-none ps-items-center ps-justify-center ps-border-0 ps-bg-transparent ps-bg-none ps-no-underline ps-transition-shadow hover:ps-ring-2 hover:ps-ring-offset-0 focus-visible:ps-outline-none focus-visible:ps-ring-2 focus-visible:ps-ring-offset-1 active:ps-ring-0 disabled:ps-pointer-events-none disabled:ps-text-gray disabled:ps-opacity-50',
  {
    variants: {
      color: {
        gray: 'hover:ps-ring-gray-900/25 focus-visible:ps-ring-gray-900/50 active:ps-shadow-defaultActive',
        blue: 'hover:ps-ring-blue/25 focus-visible:ps-ring-blue/50 active:ps-shadow-primaryActive',
        green: 'hover:ps-ring-green/25 focus-visible:ps-ring-green/50 active:ps-shadow-successActive',
        red: 'hover:ps-ring-red-500/25 focus-visible:ps-ring-red-500/50 active:ps-shadow-errorActive',
        orange: 'hover:ps-ring-orange/25 focus-visible:ps-ring-orange/50 active:ps-shadow-orangeActive',
      },
      variant: {
        contained: 'ps-text-white disabled:ps-bg-gray',
        outlined: 'ps-border ps-border-solid ps-bg-transparent disabled:ps-border-gray',
        text: 'hover:ps-bg-gray-300/25 hover:ps-ring-0 focus-visible:ps-ring-offset-0 active:ps-bg-gray-300/40',
      },
      size: {
        small: 'ps-h-[30px] ps-w-[30px] ps-px-[5px] ps-py-[5px]',
        medium: 'ps-h-[35px] ps-w-[35px] ps-px-[5px] ps-py-[5px]',
        large: 'ps-h-5 ps-w-5 ps-px-[5px] ps-py-[5px]',
      },
      shape: {
        rectangular: '',
        rounded: 'ps-rounded-[4px]',
        circular: 'ps-rounded-full',
      },
    },
    compoundVariants: [
      {
        color: 'gray',
        variant: 'contained',
        class:
          'hover:ps-bg-gray-800-dark active:ps-bg-gray-800-dark data-[state=open]:ps-bg-gray-800-dark ps-bg-gray-800',
      },
      {
        color: 'blue',
        variant: 'contained',
        class: 'hover:ps-bg-blue-dark active:ps-bg-blue-dark data-[state=open]:ps-bg-blue-dark ps-bg-blue',
      },
      {
        color: 'red',
        variant: 'contained',
        class: 'hover:ps-bg-red-500-dark active:ps-bg-red-500-dark data-[state=open]:ps-bg-red-500-dark ps-bg-red-500',
      },
      {
        color: 'green',
        variant: 'contained',
        class: 'hover:ps-bg--green-600 active:ps-bg--green-600 data-[state=open]:ps-bg--green-600 ps-bg-green',
      },
      {
        color: 'orange',
        variant: 'contained',
        class: 'ps-bg-orange hover:ps-bg-yellow-700 active:ps-bg-yellow-700 data-[state=open]:ps-bg-yellow-700',
      },
      {
        color: 'gray',
        variant: 'outlined',
        class: 'ps-border-gray-700 ps-text-black active:ps-bg-gray-800/5 data-[state=open]:ps-bg-gray-800/5',
      },
      {
        color: 'blue',
        variant: 'outlined',
        class: 'ps-border-blue ps-text-blue active:ps-bg-blue/5 data-[state=open]:ps-bg-blue/5',
      },
      {
        color: 'red',
        variant: 'outlined',
        class: 'ps-border-red-500 ps-text-red-500 active:ps-bg-red-500/5 data-[state=open]:ps-bg-red-500/5',
      },
      {
        color: 'green',
        variant: 'outlined',
        class: 'ps-border-green ps-text-green active:ps-bg-green/5 data-[state=open]:ps-bg-green/5',
      },
      {
        color: 'orange',
        variant: 'outlined',
        class: 'ps-border-orange ps-text-orange active:ps-bg-orange/5 data-[state=open]:ps-bg-orange/5',
      },
      {
        color: 'gray',
        variant: 'text',
        class: 'ps-text-black data-[state=open]:ps-bg-gray-100',
      },
      {
        color: 'blue',
        variant: 'text',
        class: 'ps-text-blue data-[state=open]:ps-bg-gray-100',
      },
      {
        color: 'red',
        variant: 'text',
        class: 'ps-text-red-500 data-[state=open]:ps-bg-gray-100',
      },
      {
        color: 'green',
        variant: 'text',
        class: 'ps-text-green data-[state=open]:ps-bg-gray-100',
      },
      {
        color: 'orange',
        variant: 'text',
        class: 'ps-text-orange data-[state=open]:ps-bg-gray-100',
      },
    ],
    defaultVariants: {
      color: 'gray',
      variant: 'text',
      size: 'small',
      shape: 'circular',
    },
  }
);

type IconButtonPropsInner<T extends React.ElementType = 'button'> = VariantProps<typeof iconButtonVariants> & {
  as?: T;
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

export const IconButtonInner = <T extends React.ElementType = 'button'>(
  { as, className, variant, color, size, shape, children, loading, disabled, ...props }: IconButtonProps<T>,
  ref: React.ForwardedRef<React.ComponentRef<T>>
) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Component = as || 'button';

  return (
    <Component
      className={commonCn(iconButtonVariants({ variant, color, size, shape, className }))}
      onMouseDown={(e: React.MouseEvent) => e && e.preventDefault()}
      disabled={disabled || loading}
      type={Component === 'button' ? (props as any).type ?? 'button' : undefined}
      ref={ref as any}
      {...props}
    >
      {loading ? (
        <>
          <Spinner size={size === 'small' ? 15 : 20} className={'ps-absolute'} />
          <div aria-hidden="true" className={'ps-invisible'}>
            {children}
          </div>
        </>
      ) : (
        children
      )}
    </Component>
  );
};

export type IconButtonProps<T extends React.ElementType = 'button'> = IconButtonPropsInner<T> &
  (T extends 'button'
    ? Omit<React.ComponentProps<'button'>, keyof IconButtonPropsInner<T>> & {
        ref?: React.ForwardedRef<HTMLButtonElement>;
      }
    : Omit<React.ComponentProps<T>, keyof IconButtonPropsInner<T>> & {
        ref?: React.ForwardedRef<T>;
      });

export const IconButton = React.forwardRef(IconButtonInner) as unknown as (<T extends React.ElementType = 'button'>(
  props: IconButtonProps<T>
) => ReturnType<typeof IconButtonInner>) & {
  displayName?: string;
};
IconButton.displayName = 'IconButton';

export { iconButtonVariants };
