import React from 'react';
import { Check } from 'react-feather';
import { Skeleton } from '@components/skeleton';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import { commonCn } from '@utils/cn';
import { colors as themeColors } from '@theme/colors';

type Orientation = 'horizontal' | 'vertical';
export type StepLabel = string | { title: React.ReactNode; subtitle?: React.ReactNode; tooltip?: React.ReactNode };
type StepColors = {
  active?: 'primary' | 'success';
  inactive?: 'gray' | 'white';
};
type NonCompletedIcon = 'checkmark' | 'number';

const DEFAULT_COLORS = {
  active: 'primary',
  inactive: 'gray',
} satisfies StepColors;

export type StepperStepUIProps = {
  label: StepLabel;
  index: number;
  onClick?: (stepIndex: number) => void;
  isLoading?: boolean;
  isActive?: boolean;
  color?: StepColors;
  isCompleted?: boolean;
  nonCompletedIcon?: NonCompletedIcon;
  showLabel?: boolean;
  gap?: number | string;
};

const StepperStep: React.FC<StepperStepUIProps> = ({
  label,
  index,
  onClick,
  isLoading = false,
  color,
  isActive,
  isCompleted,
  nonCompletedIcon = 'number',
  showLabel,
  gap = 12,
}) => {
  const colors = {
    active: {
      primary: 'ps-bg-blue',
      success: 'ps-bg-green',
    }[color?.active ?? DEFAULT_COLORS.active],
    inactive: {
      gray: 'ps-bg-gray-300',
      white: 'ps-bg-white',
    }[color?.inactive ?? DEFAULT_COLORS.inactive],
  };

  const bgColor = isActive || isCompleted ? colors.active : colors.inactive;

  const nonCompletedElement = {
    checkmark: <Check color={themeColors.gray['500']} size={20} />,
    number: index + 1,
  }[nonCompletedIcon];
  const stepIcon = isCompleted || isActive ? <Check color={`white`} size={20} /> : nonCompletedElement;

  const title = typeof label === 'string' ? label : label.title;
  const tooltip = typeof label === 'string' ? undefined : label.tooltip;

  const stepButton = (
    <button
      key={`${title}-${index}`}
      className={`ps-border-0 ps-bg-transparent ps-p-0 ps-font-normal ${onClick ? 'ps-cursor-pointer' : ''} `}
      disabled={!onClick}
      onClick={() => onClick?.(index)}
      type={'button'}
    >
      {isLoading ? (
        <Skeleton variant="circular" width={30} height={30} />
      ) : (
        <div className={'ps-flex ps-items-center'} style={{ gap: typeof gap === 'string' ? gap : `${gap}px` }}>
          <div className={`${bgColor} flex-center ps-h-[30px] ps-w-[30px] ps-rounded-full`}>
            <div className={'flex-center ps-text-lg ps-font-semibold ps-text-black'}>{stepIcon}</div>
          </div>
          {showLabel && <StepLabel label={label} />}
        </div>
      )}
    </button>
  );

  if (tooltip) {
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>{stepButton}</Tooltip.Trigger>
        <Tooltip.Content>{tooltip}</Tooltip.Content>
      </Tooltip>
    );
  }

  return stepButton;
};

export type StepperUIProps = {
  steps: Array<{
    label: StepLabel;
    isActive?: boolean;
    isCompleted?: boolean;
    isAllowed?: boolean;
  }>;
  onStepClick?: (step: number) => void;
  orientation?: Orientation;
  className?: string;
  loading?: boolean;
  color?: StepColors;
  showLabel?: 'active' | 'all' | 'none';
  nonCompletedIcon?: NonCompletedIcon;
};

const Stepper: React.FC<StepperUIProps> = ({
  steps,
  onStepClick,
  orientation = 'horizontal',
  className,
  loading = false,
  color,
  showLabel = 'active',
  nonCompletedIcon = 'number',
}) => {
  return (
    <div
      className={commonCn(
        `ps-justify-between`,
        orientation === 'vertical' ? 'ps-inline-flex ps-flex-col' : 'ps-flex ps-w-full ps-flex-row',
        className
      )}
    >
      {steps.map(({ label, isActive, isAllowed, isCompleted }, index) => {
        const showLine = index < steps.length - 1;
        const showLabelForStep = showLabel === 'all' || (showLabel === 'active' && isActive);

        return (
          <React.Fragment key={`${label}-${index}`}>
            <StepperStep
              label={label}
              index={index}
              onClick={isAllowed ? onStepClick : undefined}
              isLoading={loading}
              color={color}
              isActive={isActive}
              isCompleted={isCompleted}
              showLabel={showLabelForStep}
              gap={orientation === 'horizontal' ? 12 : 22}
              nonCompletedIcon={nonCompletedIcon}
            />
            {showLine && <SeparatorLine orientation={orientation} />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const SeparatorLine: React.FC<{ orientation: Orientation }> = ({ orientation }) => {
  if (orientation === 'horizontal')
    return (
      <div className={`ps-mx-1 ps-flex ps-w-full ps-items-center`}>
        <span
          className={'ps-display-block ps-w-full ps-border-0 ps-border-t-[1px] ps-border-solid ps-border-gray-500'}
        />
      </div>
    );

  return (
    <div className={`ps-my-1 ps-ml-[15px] ps-flex ps-h-full ps-items-center`}>
      <span
        className={'ps-display-block ps-min-h-[16px] ps-border-0 ps-border-l-[1px] ps-border-solid ps-border-gray-500'}
      />
    </div>
  );
};

const StepLabel: React.FC<{ label: StepLabel }> = ({ label }) => {
  if (typeof label === 'string') {
    return (
      <Typography variant={'h4'} className={'ps-whitespace-nowrap ps-font-semibold'}>
        {label}
      </Typography>
    );
  }

  return (
    <div className={'ps-flex ps-flex-col ps-items-start ps-gap-0.5'}>
      {typeof label.title === 'string' ? (
        <Typography variant={'h4'} className={'ps-whitespace-nowrap ps-font-semibold'}>
          {label.title}
        </Typography>
      ) : (
        label.title
      )}
      {typeof label.subtitle === 'string' ? (
        <Typography variant={'body2'} className={'ps-whitespace-nowrap ps-text-sm'}>
          {label.subtitle}
        </Typography>
      ) : (
        label.subtitle
      )}
    </div>
  );
};

export const StepperUI = {
  Stepper: Stepper,
  Step: StepperStep,
  SeparatorLine,
  StepLabel,
};
