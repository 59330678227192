import React, { FC } from 'react';
import { StepLabel, StepperUI, StepperUIProps } from '@components/stepper/stepper.ui';

export interface StepperProps extends Omit<StepperUIProps, 'steps'> {
  steps: Array<StepLabel>;
  activeStep: number;
  allowNextSteps?: boolean;
  maxAllowedStep?: number;
}

export const Stepper: FC<StepperProps> = ({ steps, activeStep, maxAllowedStep, allowNextSteps = false, ...props }) => {
  return (
    <StepperUI.Stepper
      {...props}
      steps={steps.map((step, index) => {
        const isAllowed = index < activeStep || allowNextSteps || (!!maxAllowedStep && index <= maxAllowedStep);
        const isActive = index === activeStep;
        const isCompleted = index < activeStep;
        const label = typeof step === 'string' ? { title: step, tooltip: step } : step;

        return { label, isActive, isCompleted, isAllowed };
      })}
    />
  );
};
