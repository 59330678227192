import { useContext, useEffect } from 'react';
import { FormikContext, FormikErrors } from 'formik';
import { flattenKeys } from '@utils/flatten-keys';

export const useScrollToError = (
  enabled: boolean,
  formik?: { submitCount: number; isValid: boolean; errors: FormikErrors<any> }
) => {
  const formikFromContext = useContext(FormikContext);
  const formikProps = formik ?? formikFromContext;

  useEffect(() => {
    if (!enabled) return;

    if (formikProps?.isValid) return;

    const fieldErrorNames = Object.keys(flattenKeys(formikProps.errors));
    const firstErrorFieldName = fieldErrorNames[0];
    if (!firstErrorFieldName) return;

    const fieldElement = document.querySelector(`input[name='${firstErrorFieldName}']`);
    const generalErrorElement = document.querySelector(`[data-error], [data-status="error"]`);

    const scrollToElement = fieldElement ?? generalErrorElement;
    scrollToElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [formikProps.submitCount]); // eslint-disable-line react-hooks/exhaustive-deps
};
