import React, { useState } from 'react';
import { cva } from 'class-variance-authority';
import { ChevronDown, ChevronUp } from 'react-feather';
import { IconButton } from '@components/icon-button';
import { Skeleton } from '@components/skeleton';
import { IconStatus, StatusIcon } from '@components/status-icon';
import { commonCn } from '@utils/cn';
import { CollapsableAlert } from './collapsable-alert';
import { SimpleAlert } from './simple-alert';

export interface AlertProps {
  severity?: 'error' | 'warning' | 'success' | 'info';
  showIcon?: boolean;
  collapsable?: boolean | null;
  title?: React.ReactNode | false;
  skeletonLoading?: boolean;
  compact?: boolean;
  children?: React.ReactNode;
  action?: React.ReactNode;
  secondAction?: React.ReactNode;
  className?: string;
}

export const AlertSeverities = ['error', 'warning', 'success', 'info'] as const;

const alertStyles = cva('ps-alert  ps-flex ps-items-center ps-justify-between ps-rounded ps-px-2 ps-py-2', {
  variants: {
    severity: {
      error: `ps-bg-red-50 ps-text-gray-900`,
      warning: 'ps-bg-yellow-50 ps-text-gray-900',
      success: 'ps-bg-green-100 ps-text-gray-900',
      info: 'ps-bg-gray-100 ps-text-gray-900',
    },
    compact: {
      true: 'ps-py-1',
      false: '',
    },
    action: {
      true: ` ps-space-x-1`, // Define the class for when action is present
      false: '', // Define the class for when action is not present
    },
  },
  compoundVariants: [],
  defaultVariants: {
    severity: 'success',
    action: false,
    compact: false,
  },
});

const AlertComponent: React.FC<AlertProps> = (props) => {
  const {
    severity = 'success',
    collapsable = null,
    title: titleProps = null,
    skeletonLoading = false,
    compact,
    children,
    showIcon = true,
    action,
    className,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const title = titleProps === false ? null : titleProps;

  if (skeletonLoading) return <AlertSkeleton className={className} />;

  return (
    <div
      role="alert"
      className={commonCn(
        alertStyles({ severity, compact, action: !!action }),
        'ps-flex ps-w-full ps-items-start ps-space-x-1',
        className
      )}
    >
      {showIcon ? (
        <div className="ps-flex ps-h-3 ps-items-center">{<StatusIcon status={severity as IconStatus} />}</div>
      ) : (
        false
      )}
      {!collapsable ? (
        <SimpleAlert {...props} title={title}>
          {children}
        </SimpleAlert>
      ) : (
        <div className={'ps-align-center ps-flex ps-w-full ps-justify-between'}>
          <CollapsableAlert {...props} isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
            {children}
          </CollapsableAlert>
          <div className={'-ps-my-[3px] ps-flex ps-items-start'}>
            <IconButton onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <ChevronUp className={'ps-text-gray-900'} /> : <ChevronDown className={'ps-text-gray-900'} />}
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export const AlertSkeleton: React.FC<{
  className?: string;
}> = ({ className }) => {
  return <Skeleton animation={'wave'} variant={'rounded'} height={60} className={className}></Skeleton>;
};

export const Alert = AlertComponent as typeof AlertComponent & {
  Skeleton: typeof AlertSkeleton;
};
Object.assign(Alert, {
  Skeleton: AlertSkeleton,
});
export default Alert;
