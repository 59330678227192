import React, { FC } from 'react';
import { ChevronsRight as ChevronRightIcon } from 'react-feather';
import IconButton from '@components/icon-button';

export interface DrawerToggleButtonProps {
  onOpen: any;
  onClose: any;
  open: boolean;
}

export const SidePanelToggleButton: FC<DrawerToggleButtonProps> = ({ onClose, onOpen, open }) => {
  return (
    <div className={'ps-absolute -ps-right-[15px] ps-top-12 ps-rounded-full  '}>
      <IconButton
        shape="circular"
        // size="large"
        className={
          'ps-border-[1px] ps-border-gray-300 ps-bg-white ps-text-gray-900 ps-shadow-md ps-transition-transform ps-transition-transform hover:ps-bg-blue hover:ps-text-white focus:ps-bg-blue-600 focus:ps-text-white  active:ps-bg-blue-600 active:ps-text-white active:ps-shadow-none aria-expanded:-ps-scale-x-100'
        }
        aria-expanded={open}
        onClick={open ? onClose : onOpen}
      >
        <ChevronRightIcon size={18} className={!open ? 'ps-mb-[1px] ps-ml-[2px]' : ''} />
        <span className={'ps-sr-only'}>Toggle sidebar open</span>
      </IconButton>
    </div>
  );
};
