import React, { FC, ReactNode } from 'react';
import { Tooltip } from '.';

type ContentProps = React.ComponentPropsWithoutRef<typeof Tooltip.Content>;
type ProsightTooltipProps = ContentProps & {
  children: React.ReactElement<any, any>;
  infoText?: ReactNode;
};

export const ProsightTooltip: FC<ProsightTooltipProps> = ({ infoText, children, ...rest }) => {
  if (!infoText) {
    return children;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Content {...rest}>{infoText}</Tooltip.Content>
    </Tooltip>
  );
};
