import { AutocompleteWithFormik } from '@components/field/autocomplete/autocomplete-with-formik';
import { MultipleAutocompleteWithFormik } from '@components/field/autocomplete/multiple-autocomplete-with-formik';
import { CheckboxFieldWithFormik } from '@components/field/checkbox-field/checkbox-field-with-formik';
import { CheckboxGroupFieldWithFormik } from '@components/field/checkbox-field/checkbox-group-field-with-formik';
import { DateFieldWithFormik } from '@components/field/date/date-field-with-formik';
import { FieldContext } from '@components/field/field-context';
import { NumberFieldWithFormik } from '@components/field/number-field/number-field-with-formik';
import { PhoneNumberFieldWithFormik } from '@components/field/phone-number-field/phone-number-field';
import { RadioFieldWithFormik } from '@components/field/radio-field/radio-field-with-formik';
import RadioOption from '@components/field/radio-field/radio-option';
import { FieldSkeleton } from '@components/field/skeleton';
import { MultilineTextWithFormik, TextFieldWithFormik } from '@components/field/text-field/text-field-with-formik';
import { HelperText as ProsightHelperText } from '@components/helper-text';
import { Label as ProsightLabel } from '@components/label';

export * from './skeleton';
export * from './text-field';
export * from './number-field';
export * from './checkbox-field';
export * from './autocomplete';
export * from './select';
export * from './radio-field';
export * from './date';
export * from './field-context';

export * from '@components/field/radio-field/radio-field-with-formik';
export * from '@components/field/checkbox-field/checkbox-field-with-formik';
export * from '@components/field/phone-number-field';

export const FormikField = {
  Label: ProsightLabel,
  HelperText: ProsightHelperText,
  Context: FieldContext,
  Skeleton: FieldSkeleton,
  Number: NumberFieldWithFormik,
  Text: TextFieldWithFormik,
  MultilineText: MultilineTextWithFormik,
  // Number: NumberFieldWithFormik,
  Radio: RadioFieldWithFormik,
  RadioOption: RadioOption,
  Date: DateFieldWithFormik,
  // Time: TimeFieldWithFormik,
  // DateTime: DateTimeFieldWithFormik,
  CheckboxGroup: CheckboxGroupFieldWithFormik,
  Checkbox: CheckboxFieldWithFormik,
  MultipleAutocomplete: MultipleAutocompleteWithFormik,
  Autocomplete: AutocompleteWithFormik,
  PhoneNumber: PhoneNumberFieldWithFormik,
};
