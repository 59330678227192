import React, { useCallback } from 'react';
import { useField } from 'formik';
import { useFieldContext } from '@components/field/field-context';
import { NumberField, NumberFieldProps } from './number-field';

export type NumberFieldWithFormikProps = Omit<Partial<NumberFieldProps>, 'onChange'> & {
  name: string;
  onChange?: (value: number | null, defaultAction: () => void) => void;
};

export const NumberFieldWithFormik: React.FC<NumberFieldWithFormikProps> = ({ onChange, ...props }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<number | null>(props.name);
  const { isLoading, disabled } = useFieldContext();

  const handleChange = useCallback<Required<NumberFieldProps>['onValueChange']>(
    (value, sourceInfo) => {
      // do not use onchange when value is set by props until all issue are resolved within eplan
      if (sourceInfo.source === 'prop') return;
      const notUndefinedValue = value.floatValue ?? null;
      const defaultAction = () => {
        void setTouched(true, false);
        void setValue(notUndefinedValue);
      };

      if (onChange) onChange(notUndefinedValue, defaultAction);
      else defaultAction();
    },
    [setValue, setTouched, onChange]
  );

  return (
    <NumberField
      {...props}
      value={props.value ?? value ?? ''}
      onValueChange={handleChange}
      error={props.error ? props.error : touched ? error : undefined}
      isLoading={props.isLoading || isLoading}
      disabled={props.disabled || disabled}
    />
  );
};
