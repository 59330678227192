import React, { ReactNode } from 'react';
import { Divider } from '@components/divider';
import { Modal } from '@components/modal';
import { SidePanelToggleButton } from '@components/nav-bar/side-panel-toggle-button';
import { commonCn } from '@utils/cn';

export interface NavBarProps {
  onMobileClose: () => void;
  isMobileNavOpen: boolean;
  onDrawerSwitch: (isOpen: boolean) => void;
  isOpen: boolean;
  logo: ReactNode;
  children?: ReactNode;
  className?: string;
}

export const NavBar: React.FC<NavBarProps> = ({
  logo,
  onMobileClose,
  isMobileNavOpen,
  onDrawerSwitch,
  isOpen,
  children,
  className,
}) => {
  const onOpen = () => onDrawerSwitch(true);
  const onClose = () => onDrawerSwitch(false);

  return (
    <>
      <Modal open={isMobileNavOpen} onOpenChange={(open) => !open && onMobileClose()}>
        <Modal.Body side={'left'} type={'navbar'} className={commonCn('ps-p-0', className)} asChild>
          <div className={'ps-flex ps-h-full ps-flex-col ps-mt-8'}>
            <div className={'ps-flex ps-justify-center ps-px-2 ps-pt-4 xs:ps-flex lg:ps-hidden ps-py-4'}>{logo}</div>
            <Divider className={'lg:ps-hidden'} />
            {children}
          </div>
        </Modal.Body>
      </Modal>

      <div
        className={commonCn(
          'ps-fixed ps-bottom-0 ps-left-0 ps-top-0 ps-hidden ps-overflow-visible ps-border-0 ps-border-r ps-border-solid ps-border-r-black/10 ps-bg-white ps-transition-[width] lg:ps-block',
          isOpen ? 'ps-w-[270px]' : 'ps-w-[30px]'
        )}
      >
        <SidePanelToggleButton {...{ open: isOpen, onClose, onOpen }} />

        <div
          className={commonCn(
            `ps-h-full ps-w-[270px] ps-pt-[64px] ps-transition-transform`,
            !isOpen && '-ps-translate-x-full',
            className
          )}
        >
          <div className={'ps-flex ps-h-full ps-flex-col'}>
            <div className={'ps-flex ps-justify-center ps-px-2 ps-pt-4 xs:ps-flex lg:ps-hidden'}>{logo}</div>
            <Divider className={'lg:ps-hidden'} />
            {children}
          </div>
          );
        </div>
      </div>
    </>
  );
};
