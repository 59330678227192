import React, { FC } from 'react';
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  type ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { commonCn, numberFormat } from '@utils';
import { MuiGrid as Grid } from '.';

interface ProsightChartProps {
  data: ChartData<'line'>;
  height?: number;
  options?: ChartOptions<'line'>;
  isCustomLegend?: boolean;
  className?: string;
}

export const ProsightChart: FC<ProsightChartProps> = ({
  data,
  isCustomLegend = false,
  className,
  options: customOptions,
  ...other
}) => {
  const options: ChartOptions<'line'> = customOptions
    ? customOptions
    : {
        responsive: true,
        plugins: {
          legend: {
            display: !isCustomLegend,
            position: 'bottom',
            onClick: undefined,
          },
          tooltip: {
            intersect: false,
            position: 'nearest',
            callbacks: {
              label: (context) => context.dataset.label + ': ' + numberFormat.money(context.raw as string),
            },
          },
        },
        interaction: {
          mode: 'index',
        },

        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          x: {
            min: 0,
            ticks: {
              major: {
                enabled: true,
              },
            },
          },

          y: {
            min: 0,
            ticks: {
              major: {
                enabled: true,
              },
            },
          },
        },
      };

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  return (
    <Grid className={commonCn('ps-bg-white', className)}>
      <Line data={data} options={options} {...other} />
    </Grid>
  );
};

export default ProsightChart;
