import React from 'react';
import { cva } from 'class-variance-authority';
import { AlertTriangle, Check, AlertCircle as ErrorOutlineOutlined, Info as InfoOutlined } from 'react-feather';

export interface StatusIconProps {
  status: IconStatus;
  size?: number;
}

export type IconStatus = 'success' | 'warning' | 'error' | 'info';

const iconStyles = cva('', {
  variants: {
    severity: {
      error: `ps-text-red`,
      warning: 'ps-text-yellow-600',
      success: 'ps-text-green-800',
      info: 'ps-text-blue-900',
    },
  },
  compoundVariants: [],
  defaultVariants: {
    severity: 'success',
  },
});

export const StatusIcon = ({ status, size = 24 }: { status: IconStatus; size?: number | string }) => {
  if (status === 'error') return <ErrorOutlineOutlined className={iconStyles({ severity: status })} size={size} />;
  if (status === 'warning') return <AlertTriangle className={iconStyles({ severity: status })} size={size} />;
  if (status === 'success') return <Check className={iconStyles({ severity: status })} size={size} />;
  if (status === 'info') return <InfoOutlined className={iconStyles({ severity: status })} size={size} />;
};
