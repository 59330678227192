import React from 'react';
import { FieldSkeleton } from '@components/field';
import FieldWrapper from '@components/field/field-wrapper';
import { FieldPropsBase } from '@components/field/types';
import Stack from '@components/stack';
import { CheckboxField, CheckboxFieldInGroupProps } from './checkbox-field';

export type CheckboxGroupProps<T> = FieldPropsBase & {
  value: T[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: T[]) => void;
  direction?: 'column' | 'row';
  options: CheckboxFieldInGroupProps<T>[];
  mapOptionValueToString?: (value: T) => string;
};

export const CheckboxGroupFieldInner = <T,>(
  {
    required,
    label,
    labelProps,
    helperText,
    disabled,
    onChange,
    value = [],
    infoText,
    highlight,
    direction = 'column',
    options = [],
    isLoading,
    error,
    mapOptionValueToString,
    ...rest
  }: CheckboxGroupProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  if (isLoading) {
    return (
      <CheckboxGroupField.Skeleton
        {...({ error, helperText, label, direction, options } as Partial<CheckboxGroupProps<T>>)}
      />
    );
  }

  return (
    <FieldWrapper
      {...{ label, required, labelProps, helperText, infoText, disabled, inputId: rest.id, error, ref }}
      direction={direction}
      id={rest.id}
    >
      {({ errorAriaAttributes, helperTextAttributes }) => (
        <Stack className={'ps-mt-0.5 ps-gap-1.5'} direction={direction}>
          {options?.map((option, index) => {
            const optionValueString =
              typeof option.value === 'string' ? option.value : mapOptionValueToString?.(option.value);
            return (
              <CheckboxField
                key={`${optionValueString}${index}`}
                {...{
                  ...option,
                  values: value,
                  onChange: onChange,
                  mapValueToString: mapOptionValueToString,
                  multiple: true,
                  required: true,
                  error: !!error,
                  disabled: disabled || option.disabled,
                  highlight: highlight || option.highlight,
                  isLoading: isLoading || option.isLoading,
                }}
                {...errorAriaAttributes}
                {...helperTextAttributes}
              />
            );
          })}
        </Stack>
      )}
    </FieldWrapper>
  );
};

const MemorizedCheckboxGroupField = React.memo(React.forwardRef(CheckboxGroupFieldInner));
MemorizedCheckboxGroupField.displayName = 'CheckboxGroupField';

export const CheckboxGroupField = MemorizedCheckboxGroupField as unknown as typeof CheckboxGroupFieldInner & {
  ref?: React.ForwardedRef<HTMLDivElement>;
  Skeleton: React.FC<Partial<CheckboxGroupProps<any>>>;
};
Object.assign(CheckboxGroupField, {
  Skeleton: (props: Partial<CheckboxGroupProps<any>>) => (
    <FieldSkeleton
      hasError={!!props.error}
      hasHelperText={!!props.helperText}
      hasLabel={!!props.label}
      direction={props.direction}
    >
      {(props.options ?? []).map((option, index) => (
        <CheckboxField.Skeleton key={`${option.value}${index}`} {...option} />
      ))}
    </FieldSkeleton>
  ),
});
