import React from 'react';
import { cva } from 'class-variance-authority';
import { commonCn } from '@utils/cn';

const PaperVariants = cva('ps-bg-white', {
  variants: {
    elevation: {
      0: '',
      1: 'ps-shadow-sm',
      2: 'ps-shadow-md',
      3: 'ps-shadow-lg',
    },
  },
  defaultVariants: {
    elevation: 1,
  },
});

type PaperComponentType = typeof Paper;
export type PaperPropsType = React.ComponentProps<PaperComponentType>;

const Paper = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & { elevation?: 0 | 1 | 2 | 3 }>(
  ({ elevation, className, children, ...rest }, ref) => {
    // Define elevation classes based on the prop value

    return (
      <div ref={ref} className={commonCn(PaperVariants({ elevation, className }))} {...rest}>
        {children}
      </div>
    );
  }
);

Paper.displayName = 'paper';
export default Paper;
