import React from 'react';
import { ArcElement, ChartData, Chart as ChartJS, ChartOptions, Tooltip, TooltipItem } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip);

export interface DoughnutChartProps {
  width?: number;
  height?: number;
  data: { label: string; value: number; color?: string }[];
  getTooltipItemLabel?: (item: TooltipItem<'doughnut'>) => string;
  options?: ChartOptions<'doughnut'>;
}

export const DoughnutChart: React.FC<DoughnutChartProps> = ({ data, width, height, getTooltipItemLabel, options }) => {
  const chartData: ChartData<'doughnut'> = React.useMemo(
    () => ({
      labels: data.map((item) => item.label),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: data.map((item, index) => {
            if (item.color) return item.color;
            const step = 360 / data.length;
            return `hsl(${step * index},60%,70%)`;
          }),
          borderWidth: 0,
        },
      ],
    }),
    [data]
  );

  const chartOptions: ChartOptions<'doughnut'> = {
    cutout: '65%',
    ...options,
    plugins: {
      tooltip: {
        callbacks: { label: getTooltipItemLabel },
      },
      legend: { display: false },
      ...options?.plugins,
    },
  };

  return <Doughnut width={width} height={height} data={chartData} options={chartOptions} />;
};
