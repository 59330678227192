import React from 'react';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { createPortal } from 'react-dom';
import { Check, X as Close } from 'react-feather';
import IconButton from '@components/icon-button';
import { DragHandle } from '@components/tabs/tab/tab-sortable';
import { TabUi } from '@components/tabs/tab/tab.ui';
import { TabsListUI } from '@components/tabs/tabs-list/tabs-list.ui';
import { TabsView, getLeftAdornment } from '@components/tabs/utils';
import { commonCn } from '@utils/cn';

export type SortableTabsListProps = {
  onSortConfirm?: (tabsOrder: string[], oldIndex?: number, newIndex?: number) => void;
  shouldConfirm?: boolean;
};

export const TabsListSortable = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    SortableTabsListProps & {
      view?: TabsView;
    }
>(({ className, children, onSortConfirm, view, shouldConfirm = false, ...props }, ref) => {
  const [hasNotSubmittedChange, setHasNotSubmittedChange] = React.useState(false);
  const [draggingTabValue, setDraggingTabValue] = React.useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const findTab = (tabValue: string) => {
    return React.Children.toArray(children).find((child) => {
      if (!React.isValidElement(child)) return false;
      return child.props.value === tabValue;
    }) as React.ReactElement | undefined;
  };

  const foundTab = draggingTabValue && findTab(draggingTabValue as string);
  const draggingTabComponent = foundTab ? (
    <TabUi
      {...foundTab.props}
      view={view}
      className={commonCn(foundTab.props.className, 'ps-rounded-[4px] ps-shadow-md')}
      data-state="active"
      leftAdornment={getLeftAdornment(foundTab.props.leftAdornment, <DragHandle className={'ps-text-blue'} />)}
    />
  ) : null;

  const initialItems =
    React.Children.map(children, (c) => {
      if (!React.isValidElement(c)) return null;
      return c.props.value;
    })?.filter(Boolean) ?? [];
  const [items, setItems] = React.useState<string[]>(initialItems);

  const handleConfirm = () => {
    onSortConfirm?.(items);
    setHasNotSubmittedChange(false);
  };

  const handleCancel = () => {
    setItems(initialItems);
    setHasNotSubmittedChange(false);
  };

  const currentItemsList = shouldConfirm ? items : initialItems;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({ active }) => {
        if (!active) return;
        setHasNotSubmittedChange(true);
        setDraggingTabValue(active.id as string);
      }}
      onDragEnd={({ active, over }) => {
        setDraggingTabValue(null);
        if (!shouldConfirm) setHasNotSubmittedChange(false);

        if (!active.id || !over?.id) return;
        if (active.id === over.id || !onSortConfirm) return;

        const oldIndex = currentItemsList.indexOf(active.id as string);
        const newIndex = currentItemsList.indexOf(over.id as string);
        const newItemsOrder = arrayMove(currentItemsList, oldIndex, newIndex);
        if (shouldConfirm) {
          setItems(newItemsOrder);
        } else {
          onSortConfirm(newItemsOrder, oldIndex, newIndex);
        }
      }}
    >
      <SortableContext
        items={currentItemsList.map((i) => ({ id: i as UniqueIdentifier }))}
        strategy={horizontalListSortingStrategy}
      >
        <TabsListUI
          ref={ref}
          className={commonCn(
            'ps-transition-all ps-duration-200',
            !shouldConfirm && 'ps-delay-300',
            hasNotSubmittedChange && 'ps-gap-2 ps-pb-1 ps-transition-none',
            className
          )}
          {...props}
        >
          {React.Children.toArray(children)
            .sort((a, b) => {
              if (!shouldConfirm) return 0;
              if (!React.isValidElement(a) || !React.isValidElement(b)) return 0;
              return items.indexOf(a.props.value) - items.indexOf(b.props.value);
            })
            .map((child) => {
              if (!React.isValidElement(child)) return null;
              return React.cloneElement<any>(child, {
                key: child.props.value,
                sortable: true,
                className: commonCn(child.props.className, hasNotSubmittedChange && 'ps-rounded-[4px]'),
              });
            })}
          {shouldConfirm && hasNotSubmittedChange && (
            <>
              <IconButton onClick={handleConfirm} className={'ps-self-center'} color={'blue'}>
                <Check size={'1rem'} />
              </IconButton>
              <IconButton onClick={handleCancel} className={'ps-self-center'} color={'red'}>
                <Close size={'1rem'} />
              </IconButton>
            </>
          )}
        </TabsListUI>
      </SortableContext>
      {createPortal(<DragOverlay>{draggingTabValue && draggingTabComponent}</DragOverlay>, document.body)}
    </DndContext>
  );
});
TabsListSortable.displayName = 'TabsListSortable';
