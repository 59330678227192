import React, { ReactNode } from 'react';
import { Avatar } from '@components';
import { DropdownMenu, DropdownMenuItem } from '@components/dropdown-menu';
import { Typography } from '@components/typography';
import { getInitials } from '@utils';
import { commonCn } from '@utils/cn';

export interface AccountProps {
  id?: string;
  className?: string;
  menuClassName?: string;
  user?: {
    photo?: string | null;
    avatar?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    display_name?: string | null;
  };
  menuItems?: DropdownMenuItem[];
  menuContent?: ReactNode;
}

export const Account: React.FC<AccountProps> = ({ user, menuItems, id, className, menuContent, menuClassName }) => {
  const randomId = React.useId();

  return (
    <DropdownMenu<ReactNode> id={id ?? randomId} items={menuItems} content={menuContent} menuClassName={menuClassName}>
      <button
        className={commonCn(
          'ps-flex ps-cursor-pointer ps-items-center ps-border-none ps-bg-transparent ps-text-md ps-text-[inherit] ps-ring-0',
          className
        )}
      >
        {user?.photo ? (
          <Avatar alt="obchodník" src={user.photo} className={'ps-mr-0'} />
        ) : (
          <Avatar alt="obchodník" src={user?.avatar || undefined} className={'ps-mr-0'}>
            {user ? getInitials(user.first_name + ' ' + user.last_name) : '-'}
          </Avatar>
        )}
        <Typography variant="h4" className={'ps-ml-[10px] ps-hidden ps-font-semibold ps-text-[inherit] md:ps-block'}>
          {user?.display_name}
        </Typography>
      </button>
    </DropdownMenu>
  );
};
