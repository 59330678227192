import * as React from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import { Spinner } from '@components';
import { Skeleton } from '@components/skeleton';
import { commonCn } from '@utils/cn';

export interface ButtonIconProps {
  size?: string | number;
}

type ButtonIcon = string | number | React.ReactElement<ButtonIconProps> | React.FC<ButtonIconProps>;

const buttonVariants = cva(
  [
    'ps-relative ps-inline-flex ps-cursor-pointer ps-select-none ps-items-center ps-justify-center ps-whitespace-nowrap ps-rounded-[4px] ps-border-0 ps-bg-transparent ps-bg-none ps-font-sans ps-text-[0.9375rem] ps-font-semibold ps-no-underline ps-transition-shadow',
    'hover:ps-ring-2 hover:ps-ring-offset-0 focus-visible:ps-outline-none focus-visible:ps-ring-2 focus-visible:ps-ring-offset-1 active:ps-ring-0 disabled:ps-pointer-events-none disabled:ps-text-gray-800 disabled:ps-opacity-50',
  ],
  {
    variants: {
      color: {
        primary: 'hover:ps-ring-blue/25 focus-visible:ps-ring-blue/50 active:ps-shadow-primaryActive',
        success: 'hover:ps-ring-green/25 focus-visible:ps-ring-green/50 active:ps-shadow-successActive',
        error: 'hover:ps-ring-red-500/25 focus-visible:ps-ring-red-500/50 active:ps-shadow-errorActive',
        gray: 'hover:ps-ring-gray-900/25 focus-visible:ps-ring-gray-900/50 active:ps-shadow-defaultActive',
      },
      variant: {
        contained: 'ps-text-white disabled:ps-bg-gray',
        outlined: 'ps-border ps-border-solid ps-bg-transparent disabled:ps-border-gray',
        text: [
          'ps-text-md ps-font-bold ps-uppercase ps-underline-offset-2 before:ps-rounded-[4px] hover:ps-underline hover:ps-decoration-1 hover:ps-ring-0',
          'focus-visible:ps-ring-offset-0 active:ps-relative active:ps-underline active:ps-decoration-2 active:ps-shadow-none',
          'data-[state=open]:ps-underline data-[state=open]:ps-decoration-2',
        ],
      },
      size: {
        default: 'ps-h-5 ps-px-3 ps-py-1.5',
        small: 'ps-h-3.5 ps-px-1 ps-py-0.5 ps-text-md',
      },
    },
    compoundVariants: [
      // text button has always the same size
      {
        variant: 'text',
        class: 'ps-h-[29px] ps-px-1 ps-py-1',
      },

      {
        color: 'primary',
        variant: 'contained',
        class: 'hover:ps-bg-blue-dark active:ps-bg-blue-dark ps-bg-blue data-[state=open]:ps-bg-blue',
      },
      {
        color: 'error',
        variant: 'contained',
        class: 'hover:ps-bg-red-500-dark active:ps-bg-red-500-dark ps-bg-red-500 data-[state=open]:ps-bg-red-500',
      },
      {
        color: 'success',
        variant: 'contained',
        class: 'hover:ps-bg--green-600 active:ps-bg--green-600 data-[state=open]:ps-bg--green-600 ps-bg-green-300',
      },
      {
        color: 'gray',
        variant: 'contained',
        class: 'hover:ps-bg-gray-800-dark active:ps-bg-gray-800-dark ps-bg-gray-800 data-[state=open]:ps-bg-gray-800',
      },
      {
        color: 'primary',
        variant: 'outlined',
        class: 'ps-border-blue ps-text-blue active:ps-bg-blue/5 data-[state=open]:ps-bg-blue/5',
      },
      {
        color: 'error',
        variant: 'outlined',
        class: 'ps-border-red-500 ps-text-red-500 active:ps-bg-red-500/5 data-[state=open]:ps-bg-red-500/5',
      },
      {
        color: 'success',
        variant: 'outlined',
        class: 'ps-border-green-300 ps-text-green active:ps-bg-green/5 data-[state=open]:ps-bg-green/5',
      },
      {
        color: 'gray',
        variant: 'outlined',
        class: 'ps-border-gray-700 ps-text-black active:ps-bg-gray-800/5 data-[state=open]:ps-bg-gray-800/5',
      },
      {
        color: 'primary',
        variant: 'text',
        class: 'ps-text-blue active:ps-bg-blue/10 data-[state=open]:ps-bg-blue/10',
      },
      {
        color: 'error',
        variant: 'text',
        class: 'ps-text-red-500 active:ps-bg-red-500/10 data-[state=open]:ps-bg-red-500/10',
      },
      {
        color: 'success',
        variant: 'text',
        class: 'ps-text-green-300 active:ps-bg-green/10 data-[state=open]:ps-bg-green/10',
      },
      {
        color: 'gray',
        variant: 'text',
        class: 'ps-text-black active:ps-bg-gray-800/10 data-[state=open]:ps-bg-gray-800/10',
      },
    ],
    defaultVariants: {
      color: 'primary',
      variant: 'contained',
      size: 'default',
    },
  }
);

const Icon: React.FC<
  {
    children: ButtonIcon;
  } & ({ start: true; end?: never } | { start?: never; end: true })
> = ({ children, start }) => {
  if (!children) return null;

  const spacingClass = start ? 'ps-mr-1' : 'ps-ml-1';
  const className = `${spacingClass} ps-inline-flex ps-items-center ps-justify-center`;

  if (React.isValidElement(children)) {
    const originalSize = children.props.size;
    return React.cloneElement(children, {
      size: originalSize ?? '1rem',
      // @ts-ignore
      className: commonCn(spacingClass, children.props.className),
    });
  }

  if (typeof children === 'object' || typeof children === 'function') {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const IconComponent = children;
    return (
      <span className={className}>
        <IconComponent size={'1rem'} />
      </span>
    );
  }

  return <span className={className}>{children}</span>;
};

export type ButtonInnerProps<T extends React.ElementType> = VariantProps<typeof buttonVariants> & {
  as?: T;
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  startIcon?: ButtonIcon;
  endIcon?: ButtonIcon;
};

export const ButtonInner = <T extends React.ElementType = 'button'>(
  { as, className, variant, color, size, children, startIcon, endIcon, loading, disabled, ...props }: ButtonProps<T>,
  ref: React.ForwardedRef<React.ComponentRef<T>>
) => {
  const buttonContent = (
    <>
      {startIcon && <Icon start>{startIcon}</Icon>}
      {children}
      {endIcon && <Icon end>{endIcon}</Icon>}
    </>
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Component = as || 'button';

  return (
    <Component
      className={commonCn(buttonVariants({ variant, color, size, className }), className)}
      onMouseDown={(e: React.MouseEvent) => e && e.preventDefault()}
      disabled={disabled || loading}
      ref={ref as any}
      type={Component === 'button' ? (props as any).type ?? 'button' : undefined}
      {...props}
    >
      {loading ? (
        <>
          <Spinner size={size === 'small' ? 15 : 20} className={'ps-absolute'} />
          <div aria-hidden="true" className={'ps-invisible'}>
            {buttonContent}
          </div>
        </>
      ) : (
        buttonContent
      )}
    </Component>
  );
};

export type ButtonProps<T extends React.ElementType = 'button'> = ButtonInnerProps<T> &
  (T extends 'button'
    ? Omit<React.ComponentProps<'button'>, keyof ButtonInnerProps<T>> & {
        ref?: React.ForwardedRef<HTMLButtonElement>;
      }
    : Omit<React.ComponentProps<T>, keyof ButtonInnerProps<T>> & {
        ref?: React.ForwardedRef<T>;
      });

export const Button = Object.assign(
  React.forwardRef(ButtonInner) as <T extends React.ElementType = 'button'>(
    props: ButtonProps<T>
  ) => ReturnType<typeof ButtonInner>,
  {
    displayName: 'Button',
    Skeleton: (props: ButtonProps) => (
      <Skeleton>
        <Button {...props} />
      </Skeleton>
    ),
  }
);

export { buttonVariants };
