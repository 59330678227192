import React from 'react';
import { FormikConfig, FormikProps, FormikValues, isEmptyChildren, isFunction, useFormik } from 'formik';
import { EnhancedFormikProvider } from '@components/enhanced-formik';
import { FieldContextValue } from '@components/field';
import { useNavigationInterceptor } from '@hooks/use-navigation-interceptor';
import { useScrollToError } from '@hooks/use-scroll-to-error';

export interface EnhancedFormikProps<Values extends FormikValues = FormikValues> extends FormikConfig<Values> {
  render?: never;
  interceptNavigation?: boolean;
  navigationInterceptorKey?: string;
  scrollToError?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

export const useEnhancedFormik = <Values extends FormikValues = FormikValues>({
  interceptNavigation = true,
  navigationInterceptorKey,
  scrollToError = true,
  isLoading = false,
  disabled = false,
  ...rest
}: EnhancedFormikProps<Values>) => {
  const formik = useFormik(rest);

  const randomKey = React.useId();
  useNavigationInterceptor(
    formik.dirty && interceptNavigation,
    `${navigationInterceptorKey ?? randomKey}<-useEnhancedFormik`
  );
  useScrollToError(scrollToError, formik);

  return { ...formik, fieldContext: { isLoading, disabled } };
};

export const EnhancedFormik = <Values extends FormikValues = FormikValues>(
  props: EnhancedFormikProps<Values> & Omit<FieldContextValue, 'children'>
) => {
  const { component, children, innerRef } = props;
  const randomKey = React.useId();
  const enhancedFormik = useEnhancedFormik({
    ...props,
    navigationInterceptorKey: `${props.navigationInterceptorKey ?? randomKey}<-EnhancedFormik`,
  });

  React.useImperativeHandle(innerRef, () => enhancedFormik);

  const renderChildren = () => {
    if (component) {
      return React.createElement(component as any, enhancedFormik);
    } else if (isFunction(children)) {
      type ChildrenAsFunction = (formik: FormikProps<Values>) => React.ReactNode;
      return (children as ChildrenAsFunction)(enhancedFormik);
    } else if (!isEmptyChildren(children)) {
      return React.Children.only(children);
    }
    return null;
  };

  return <EnhancedFormikProvider value={enhancedFormik}>{renderChildren()}</EnhancedFormikProvider>;
};
