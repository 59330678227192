import React, { ReactElement, ReactNode } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { IconButton } from '@components/icon-button';
import { commonCn } from '@utils/cn';

const ANIMATION_DURATION = 150;

// CollapsibleContext
const CollapsibleContext = React.createContext<{
  open: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
  contentId: string;
}>({ open: false, onOpenChange: () => {}, contentId: '' });
const useCollapsibleContext = () => React.useContext(CollapsibleContext);

// Hooks
function useDelayUnmount(isMounted: boolean, delayTime: number) {
  const [showDiv, setShowDiv] = React.useState(isMounted);
  React.useEffect(() => {
    let timeoutId: number;
    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime) as unknown as number;
    }
    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, showDiv]);
  return showDiv;
}

export type CollapsibleTriggerChildProps = {
  open: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
  icon: React.ElementType;
  buttonProps: { onClick: React.MouseEventHandler<any> };
};

// Components
export const CollapsibleTrigger = React.forwardRef<
  HTMLButtonElement,
  Omit<React.ComponentPropsWithoutRef<typeof IconButton>, 'children'> & {
    open?: boolean;
    onOpenChange?: React.Dispatch<React.SetStateAction<boolean>>;
    contentId?: string;
  } & (
      | { asChild: true; children: React.ReactElement | React.FC<CollapsibleTriggerChildProps> }
      | { asChild?: false; children?: never }
    )
>(({ open: openProps, onOpenChange: onOpenChangeProps, contentId, ...props }, ref) => {
  const context = useCollapsibleContext();
  const open = openProps ?? context.open;
  const onOpenChange = onOpenChangeProps ?? context.onOpenChange;

  const triggerAttributes = {
    'aria-controls': contentId ?? context.contentId,
    'aria-expanded': open,
  };

  if (props.children && React.isValidElement(props.children)) {
    const handleClick = (e: React.MouseEvent) => {
      const childOnClick = (props.children as ReactElement)?.props?.onClick;
      if (childOnClick) childOnClick(e);
      if (!e.defaultPrevented) onOpenChange((open) => !open);
    };

    return React.cloneElement(props.children as ReactElement, {
      ...triggerAttributes,
      onClick: handleClick,
      className: commonCn((props.children as ReactElement)?.props?.className, props.className),
    });
  }

  if (props.children && typeof props.children === 'function') {
    return props.children({
      open,
      onOpenChange,
      icon: open ? ChevronUp : ChevronDown,
      buttonProps: {
        onClick: () => onOpenChange((open) => !open),
        ...triggerAttributes,
      },
    });
  }

  return (
    <IconButton
      shape={'circular'}
      onClick={() => onOpenChange((open) => !open)}
      ref={ref}
      {...triggerAttributes}
      {...props}
    >
      {open ? <ChevronUp /> : <ChevronDown />}
    </IconButton>
  );
});
CollapsibleTrigger.displayName = 'CollapsibleTrigger';

const CollapsibleRoot: React.FC<{
  open?: boolean;
  onOpenChange?: React.Dispatch<React.SetStateAction<boolean>>;
  contentId?: string;
  defaultOpen?: boolean;
  children?: React.ReactNode;
}> = ({ children, contentId: propsContentId, open, defaultOpen = false, onOpenChange }) => {
  const [openState, onOpenStateChange] = React.useState<boolean>(defaultOpen);
  const randomId = React.useId();
  const contentId = propsContentId ?? randomId;

  return (
    <CollapsibleContext.Provider
      value={{ open: open ?? openState, onOpenChange: onOpenChange ?? onOpenStateChange, contentId }}
    >
      {children}
    </CollapsibleContext.Provider>
  );
};
CollapsibleRoot.displayName = 'CollapsibleRoot';

const CollapsibleContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & { keepMounted?: boolean }
>(({ className, children, keepMounted = false, ...props }, ref) => {
  const { open, contentId } = useCollapsibleContext();
  const showContent = useDelayUnmount(open, ANIMATION_DURATION);

  return (
    <div
      ref={ref}
      className={commonCn(
        'ps-grid ps-grid-rows-[0fr] ps-transition-[grid-template-rows] data-[state=open]:ps-grid-rows-[1fr]',
        !showContent && 'ps-opacity-0',
        className
      )}
      data-state={open ? 'open' : 'closed'}
      id={contentId}
      hidden={!open}
      aria-hidden={!open}
      {...props}
    >
      {(showContent || keepMounted) && <div className={'-ps-m-[2px] ps-overflow-hidden ps-p-[2px]'}>{children}</div>}
    </div>
  );
});
CollapsibleContent.displayName = 'CollapsibleContent';

export const Collapse: React.FC<{ in?: boolean; className?: string; children: ReactNode }> = (props) => {
  return (
    <CollapsibleRoot open={props.in}>
      <CollapsibleContent className={props.className}>{props.children}</CollapsibleContent>
    </CollapsibleRoot>
  );
};

export const Collapsible = Object.assign(CollapsibleRoot, {
  Trigger: CollapsibleTrigger,
  Content: CollapsibleContent,
});

export default Collapsible;
