import { _monthsSuffix, _yearsSuffix } from './suffix-formats';
import { FormatOptions } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
const _default: FormatOptions = {
  negativeToZero: false,
  onlySuffix: false,
  trailingZeros: false,
  suffix: '',
  decimalSeparator: ',',
  thousandsSeparator: '',
  precision: 2,
  nilValue: '–',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const rounded: FormatOptions = {
  ..._default,
  precision: 0,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const money: FormatOptions = {
  ..._default,
  trailingZeros: true,
  suffix: '\xa0€',
  thousandsSeparator: ' ',
  precision: 2,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const moneyRounded: FormatOptions = {
  ...money,
  trailingZeros: false,
  precision: 0,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const moneyNoNegative: FormatOptions = {
  ...money,
  negativeToZero: true,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const moneyRoundedNoNegative: FormatOptions = {
  ...moneyRounded,
  negativeToZero: true,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const percentage: FormatOptions = {
  ..._default,
  suffix: '\xa0%',
  precision: 2,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const years: FormatOptions = {
  ..._default,
  suffix: (numberValue) => '\xa0' + _yearsSuffix(numberValue),
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const months: FormatOptions = {
  ..._default,
  suffix: (numberValue) => '\xa0' + _monthsSuffix(numberValue),
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const yearsSuffix: FormatOptions = {
  ..._default,
  suffix: _yearsSuffix,
  nilValue: 'rokov',
  onlySuffix: true,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const monthsSuffix: FormatOptions = {
  ..._default,
  suffix: _monthsSuffix,
  nilValue: 'mesiacov',
  onlySuffix: true,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const presets = {
  _default,
  rounded,
  money,
  moneyRounded,
  moneyNoNegative,
  moneyRoundedNoNegative,
  percentage,
  years,
  months,
  yearsSuffix,
  monthsSuffix,
} as const;

export default presets;
