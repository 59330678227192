import React from 'react';
import { commonCn } from '@utils/cn';

export type GridProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
  item?: boolean;
  container?: boolean;
  gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly' | 'stretch';
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  alignContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly' | 'stretch';
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
  wrap?: 'wrap' | 'nowrap' | 'reverse';
  alignSelf?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
  direction?: 'row' | 'column';
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | true | 'auto';
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | true | 'auto';
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | true | 'auto';
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | true | 'auto';
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | true | 'auto';
};

export const MuiGrid: React.FC<GridProps> = ({
  container,
  item,
  gap = 0,
  justifyContent,
  alignItems,
  alignContent,
  justifyItems,
  wrap,
  alignSelf,
  direction,
  xs,
  sm,
  md,
  lg,
  xl,
  children,
  className,
  ...props
}) => {
  const containerCn = [
    `ps-grid-container`,
    gap !== undefined && `ps-grid-container-gap-${gap}`,
    justifyContent && `ps-justify-${justifyContent}`,
    alignItems && `ps-items-${alignItems}`,
    alignContent && `ps-content-${alignContent}`,
    justifyItems && `ps-justify-items-${justifyItems}`,
    alignSelf && `ps-self-${alignSelf}`,
    wrap &&
      {
        wrap: 'ps-flex-wrap',
        nowrap: 'ps-flex-nowrap',
        reverse: 'ps-flex-wrap-reverse',
      }[wrap],
    direction &&
      {
        row: 'ps-flex',
        column: 'ps-flex-col',
      }[direction],
  ];
  const itemCn = [
    'ps-grid-item',
    xs && typeof xs === 'number' && `ps-grid-item-xs-${xs}`,
    sm && typeof xs === 'number' && `ps-grid-item-sm-${sm}`,
    md && typeof xs === 'number' && `ps-grid-item-md-${md}`,
    lg && typeof xs === 'number' && `ps-grid-item-lg-${lg}`,
    xl && typeof xs === 'number' && `ps-grid-item-xl-${xl}`,
    xs === true && `ps-grid-item-xs`,
    sm === true && `ps-grid-item-sm`,
    md === true && `ps-grid-item-md`,
    lg === true && `ps-grid-item-lg`,
    xl === true && `ps-grid-item-xl`,
  ];

  return (
    <div className={commonCn(item && itemCn, container && containerCn, className)} {...props}>
      {children}
    </div>
  );
};

// Implementation using tailwindcss and CSS Grids
//
// export const Grid: React.FC<GridProps> = ({ container, item, gap = 0, xs, sm, md, lg, xl, children, ...props }) => {
//   const containerCn = [`ps-grid ps-w-full ps-grid-cols-12 ps-gap-${gap}`];
//   const itemCn = [
//     xs && `xs:ps-col-span-${xs}`,
//     sm && `sm:ps-col-span-${sm}`,
//     md && `md:ps-col-span-${md}`,
//     lg && `lg:ps-col-span-${lg}`,
//     xl && `xl:ps-col-span-${xl}`,
//   ];
//
//   return <div className={commonCn(item && itemCn, container && containerCn, props.className)}>{children}</div>;
// };
