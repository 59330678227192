/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { useForwardedRef } from '@hooks/use-forwarded-ref';
import { commonCn } from '@utils/cn';
import { DEFAULT_VARIANT, TYPOGRAPHY_VARIANT_TO_HTML_ELEMENT_MAP } from './constants';

export const typographyVariantsProsight = cva('ps-m-0 ps-font-sans ps-text-base ps-font-normal ps-leading-normal', {
  variants: {
    variant: {
      h1: 'ps-text-3xl ps-font-extrabold',
      h1thin: 'ps-text-3xl ps-font-normal',
      h2: 'ps-text-2xl ps-font-extrabold',
      h3: 'ps-text-lg ps-font-extrabold ps-tracking-[-0.05px]',
      h4: 'ps-text-base ps-font-bold ps-tracking-[-0.05px]',
      body1: 'ps-text-lg',
      body2: 'ps-text-base',
      label: 'ps-text-base ps-leading-[12px] ps-text-black',
    },
    gutterBottom: {
      true: 'ps-mb-[0.35em]',
      false: '',
    },
    color: {
      default: '',
      primary: 'ps-text-blue',
      secondary: 'ps-text-gray-700',
      error: 'ps-text-red-500',
      success: 'ps-text-green',
    },
    noWrap: {
      true: 'ps-whitespace-nowrap',
      false: '',
    },
    align: {
      center: 'ps-text-center',
      left: 'ps-text-left',
      right: 'ps-text-right',
      justify: 'ps-text-justify',
    },
  },
  // compoundVariants: [
  //   { variant: 'h1', gutterBottom: true, className: 'ps-mb-4' },
  //   { variant: 'h2', gutterBottom: true, className: 'ps-mb-3' },
  //   { variant: 'h3', gutterBottom: true, className: 'ps-mb-2' },
  //   { variant: 'h4', gutterBottom: true, className: 'ps-mb-2' },
  //   { variant: 'body1', gutterBottom: true, className: 'ps-mb-2' },
  //   { variant: 'body2', gutterBottom: true, className: 'ps-mb-2' },
  //   { variant: 'label', gutterBottom: true, className: 'ps-mb-1' },
  // ],
  defaultVariants: {
    color: 'default',
    variant: DEFAULT_VARIANT,
    gutterBottom: false,
    noWrap: false,
  },
});

export const typographyVariants = typographyVariantsProsight;
export type TypographyVariantsProps = VariantProps<typeof typographyVariants>;
export type TypographyVariant = NonNullable<TypographyVariantsProps['variant']>;

export interface TypographyPropsInner<As extends React.ElementType> extends TypographyVariantsProps {
  as?: As;
  children?: React.ReactNode;
  className?: string;
}

function TypographyInner<Variant extends TypographyVariant, As extends React.ElementType>(
  props: { as: As; variant: Variant; ref?: React.ForwardedRef<As> } & TypographyPropsInner<As> &
    Partial<Omit<React.ComponentPropsWithoutRef<As>, keyof TypographyPropsInner<As>>>,
  ref: React.ForwardedRef<As>
): React.ReactNode;
function TypographyInner<Variant extends TypographyVariant, As extends React.ElementType>(
  props: { as: As; variant?: never; ref?: React.ForwardedRef<As> } & TypographyPropsInner<As> &
    React.ComponentPropsWithoutRef<As>,
  ref: React.ForwardedRef<As>
): React.ReactNode;
function TypographyInner<
  Variant extends TypographyVariant = typeof DEFAULT_VARIANT,
  As extends React.ElementType = never,
>(
  props: {
    variant?: Variant;
    as?: never;
    ref?: React.ForwardedRef<React.ComponentRef<(typeof TYPOGRAPHY_VARIANT_TO_HTML_ELEMENT_MAP)[Variant]>>;
  } & TypographyPropsInner<As> &
    React.ComponentPropsWithoutRef<(typeof TYPOGRAPHY_VARIANT_TO_HTML_ELEMENT_MAP)[Variant]>,
  ref: React.ForwardedRef<(typeof TYPOGRAPHY_VARIANT_TO_HTML_ELEMENT_MAP)[Variant]>
): React.ReactNode;

function TypographyInner<As extends React.ElementType>(
  { as, variant, gutterBottom, color, noWrap, children, align, className, ...props }: TypographyPropsInner<As>,
  forwardedRef: React.ForwardedRef<As>
) {
  const ref = useForwardedRef(forwardedRef);
  const Component =
    as ||
    TYPOGRAPHY_VARIANT_TO_HTML_ELEMENT_MAP[
      (variant ?? DEFAULT_VARIANT) as keyof typeof TYPOGRAPHY_VARIANT_TO_HTML_ELEMENT_MAP
    ];

  return (
    <Component
      className={commonCn(typographyVariants({ variant, className, gutterBottom, color, noWrap, align }))}
      ref={ref as any}
      {...props}
    >
      {children}
    </Component>
  );
}

const TypographyInnerWithRef = React.forwardRef(TypographyInner as any);
TypographyInnerWithRef.displayName = 'Typography';

export type TypographyProps = React.ComponentPropsWithRef<typeof TypographyInner> & {
  children?: React.ReactNode;
};

export const Typography = TypographyInnerWithRef as unknown as typeof TypographyInner; // forward ref does not work with generic
export default Typography;
