import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image as UploadIcon } from 'react-feather';
import { Link } from '@components/link';
import { NavigationInterceptor } from '@components/navigation-interceptor';
import { Snackbar } from '@components/snackbar';
import Stack from '@components/stack/stack';
import { Typography } from '@components/typography';
import { FilesList } from './files-list';
import { FilesSelectRoot } from './files-select-root';
import { FilesSelectTitle, FilesSelectTitleProps } from './files-select-title';
import { FilesSelectUploadButton } from './files-select-upload-button';
import { FileAccept, getAccept } from './utils';

const Slots = {
  FilesList: FilesList,
  Title: FilesSelectTitle,
  Root: FilesSelectRoot,
  UploadButton: FilesSelectUploadButton,
  Input: 'input',
};
export type FilesUploadSlots = Partial<
  Omit<typeof Slots, 'UploadButton'> & {
    UploadButton?: typeof FilesSelectUploadButton | false;
  }
>;

export interface FilesSelectProps extends FilesSelectTitleProps {
  id: string;
  className?: string;
  dropzoneTitle?: string;
  onUpload?: (files: File[]) => Promise<void> | void;
  isUploading?: boolean;
  uploadButtonText?: string;
  maxFiles?: number;
  // in bytes
  maxSize?: number;
  color?: 'primary' | 'secondary';
  accept?: FileAccept[];
  initialFiles?: File[];
  files?: never;
  setFiles?: never;
  slots?: FilesUploadSlots;
  disabled?: boolean;
  multipleSelects?: boolean;
  interceptNavigation?: boolean;
  uploadOnDrop?: boolean;
}

export interface FileSelectControlledProps extends Omit<FilesSelectProps, 'files' | 'setFiles' | 'uploadButton'> {
  initialFiles?: never;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const FilesSelectComponent: React.FC<FilesSelectProps | FileSelectControlledProps> = ({
  id,
  className,
  title,
  helperText,
  dropzoneTitle,
  onUpload,
  isUploading: propsIsUploading,
  uploadButtonText = 'Nahrať dokumenty',
  maxFiles = 10,
  maxSize = 10e6,
  multipleSelects = false,
  color,
  accept,
  initialFiles,
  files: propsFiles,
  setFiles: propsSetFiles,
  slots,
  interceptNavigation = true,
  uploadOnDrop = false,
}) => {
  const [stateFiles, setStateFiles] = useState(initialFiles ?? []); // files prepared to upload
  const [isUploading, setIsUploading] = useState(false);

  const files = propsFiles ?? stateFiles;
  const setFiles = propsSetFiles ?? setStateFiles;

  const uploadField = async (files: File[]) => {
    if (!onUpload) return;

    setIsUploading(true);

    try {
      await onUpload(files);
      setFiles([]);
    } catch (e) {
      Snackbar.error(`Nahrávanie súborov zlyhalo.`);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUploadClick = () => {
    if (files.length === 0) return;

    void uploadField(files);
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    const filesCount = files.length + acceptedFiles.length;
    if (filesCount > maxFiles) return Snackbar.error(`Maximálne môžte nahrať ${maxFiles} súborov.`);

    await setFiles((prevFiles) => prevFiles.concat(acceptedFiles));

    void uploadField([...files, ...acceptedFiles]);
  };

  const dropzoneState = useDropzone({
    onDrop: handleDrop,
    maxSize: maxSize,
    maxFiles: maxFiles - files.length,
    multiple: maxFiles > 1,
    accept: getAccept(accept),
    onDropRejected: () => {
      Snackbar.error(`Súbor odmietnutý. Maximálna veľkosť súboru je ${maxSize / 1e6}MB.`);
    },
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const FilesList = slots?.FilesList ?? Slots.FilesList;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Title = slots?.Title ?? Slots.Title;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Input = slots?.Input ?? Slots.Input;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Root = slots?.Root ?? Slots.Root;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const UploadButton = slots?.UploadButton ?? Slots.UploadButton;

  return (
    <div className={className}>
      {interceptNavigation && (
        <NavigationInterceptor block={files.length > 0} interceptorKey={`hasUnsavedFiles(${id})`} />
      )}
      <Title title={title} helperText={helperText} />
      {title && <div className={'ps-mt-3'} />}
      <Root {...dropzoneState}>
        <Input {...dropzoneState.getInputProps()} />

        <Stack direction={'row'}>
          <Stack gap={3} className={`ps-flex-1 ${dropzoneTitle ? 'ps-py-0' : 'ps-py-2'}`}>
            {dropzoneTitle && <Typography className={'ps-font-bold'}>{dropzoneTitle}</Typography>}
            <Typography>
              Pretiahnite sem súbory alebo <Link>vyberte súbory</Link> z vášho počítača.
              {!multipleSelects ? ' (Maximálna veľkosť všetkých súborov spolu je 10MB)' : null}
            </Typography>
          </Stack>
          <UploadIcon />
        </Stack>
      </Root>
      <FilesList files={files} setFiles={setFiles} color={color} className="mt-3" />
      {UploadButton !== false && !uploadOnDrop && (
        <UploadButton
          id={`${id}-upload-button`}
          loading={propsIsUploading ?? isUploading}
          onClick={handleUploadClick}
          disabled={files.length === 0}
          className="mt-1"
        >
          {uploadButtonText}
        </UploadButton>
      )}
    </div>
  );
};

export const FilesSelect = FilesSelectComponent as typeof FilesSelectComponent & typeof Slots;
Object.assign(FilesSelect, Slots);
