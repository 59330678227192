import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const ThemeConfig = {
  colors: ['gray', 'blue', 'red', 'green', 'yellow', 'purple', 'orange'],
  spacing: ['xs', 'sm', 'md', 'lg', 'xl'],
};

const commonTwMerge = extendTailwindMerge({
  prefix: 'ps-',
  extend: {
    theme: ThemeConfig,
  },
});

const twMerge = extendTailwindMerge({
  extend: {
    theme: ThemeConfig,
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function commonCn(...inputs: ClassValue[]) {
  return commonTwMerge(clsx(inputs));
}
