import React from 'react';
import { FormikField } from '@components/field';
import Stack from '@components/stack/stack';
import { Typography } from '@components/typography';
import { commonCn } from '@utils/cn';

export interface FilesSelectTitleProps {
  title?: string;
  helperText?: string;
  className?: string;
}

export const FilesSelectTitle: React.FC<FilesSelectTitleProps> = ({ title, helperText, className }) => {
  if (!title) return null;

  return (
    <Stack gap={1}>
      <Typography className={commonCn(className)} variant="h4">
        {title}
      </Typography>
      {helperText && <FormikField.HelperText>{helperText}</FormikField.HelperText>}
    </Stack>
  );
};
