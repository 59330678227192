import React, { useCallback, useEffect, useState } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import first from 'lodash/first';
import last from 'lodash/last';
import toInteger from 'lodash/toInteger';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Skeleton, Typography } from '@components';
import { commonCn } from '@utils/cn';

const PaginatorVariants = cva('', {
  variants: {
    color: {
      gray: 'ps-bg-gray-100',
      white: 'ps-bg-white',
    },
  },
  defaultVariants: {
    color: 'gray',
  },
});

interface PaginatorProps extends VariantProps<typeof PaginatorVariants> {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  visibleTotal?: number;
  className?: string;
  labelRowsPerPage?: string;
  rowsPerPageOptions?: number[];
  onRowsPerPageChange: (onRowsPerPageValue: number) => void;
  rowsPageValue: number;
  isLoading?: boolean;
}

const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  className,
  totalPages,
  onPageChange,
  visibleTotal = 4,
  labelRowsPerPage = 'Počet riadkov na stranu',
  onRowsPerPageChange,
  rowsPageValue = 10,
  rowsPerPageOptions = [10, 25, 100],
  isLoading,
  color,
}) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const [visible, setVisiblePages] = useState<Array<number>>(
    Array.from({ length: visibleTotal < totalPages ? visibleTotal : totalPages }, (_, index) => index + 1)
  );

  // update visible page state when totalPages or visibleTotal changes
  useEffect(() => {
    setVisiblePages(
      Array.from({ length: visibleTotal < totalPages ? visibleTotal : totalPages }, (_, index) => index + 1)
    );
  }, [totalPages, visibleTotal]);

  const handleVisiblePages = useCallback(
    (page: number) => {
      // Calculate the range of visible pages
      let lastVisiblePage = last(visible) as number;
      let firstVisiblePage = first(visible) as number;

      // Adjust the first visible page if needed
      if (page > lastVisiblePage) {
        // Create an array of visible page numbers
        lastVisiblePage++;
        firstVisiblePage++;
        const newVisiblePages = Array.from(
          { length: lastVisiblePage - firstVisiblePage + 1 },
          (_, index) => firstVisiblePage + index
        );
        setVisiblePages(newVisiblePages);
      }

      if (page < firstVisiblePage) {
        // Create an array of visible page numbers
        lastVisiblePage--;
        firstVisiblePage--;
        const newVisiblePages = Array.from(
          { length: lastVisiblePage - firstVisiblePage + 1 },
          (_, index) => firstVisiblePage + index
        );
        setVisiblePages(newVisiblePages);
      }
    },
    [visible]
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage >= 1 && newPage <= totalPages) {
        onPageChange(newPage);
        handleVisiblePages(newPage);
      }
    },
    [handleVisiblePages, onPageChange, totalPages]
  );

  // if current page is grater than last_page redirect to last page
  useEffect(() => {
    if (currentPage > totalPages) {
      handlePageChange(totalPages);
    }
  }, [totalPages, currentPage, handlePageChange]);

  if (isLoading) return <PaginatorSkeleton />;

  return (
    <div className={commonCn('ps-flex ps-items-center ps-justify-between ps-py-1', className)}>
      <div className={'ps-inline-flex ps-items-center'}>
        <Typography className={'ps-mr-2 ps-whitespace-nowrap'}>{labelRowsPerPage}</Typography>
        <PaginatorSelect options={rowsPerPageOptions} onChange={onRowsPerPageChange} value={String(rowsPageValue)} />
      </div>
      {totalPages > 1 && (
        <div className="ps-flex ps-items-center ps-justify-center ">
          <button
            disabled={isFirstPage}
            className="ps-flex ps-cursor-pointer ps-items-center ps-border-none ps-bg-transparent"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <ChevronLeft size={20} />
          </button>

          {visible.map((pageNumber) => (
            <button
              key={pageNumber}
              className={commonCn(
                'ps-align-center ps-ease-in-out` ps-flex ps-h-3 ps-w-3 ps-cursor-pointer ps-select-none ps-justify-center ps-rounded-full ps-border-2 ps-border-transparent ps-bg-transparent ps-transition ps-duration-300',
                currentPage === pageNumber
                  ? PaginatorVariants({ color })
                  : 'ps-hover:ps-bg-blue-100 ps-hover:ps-text-white'
              )}
              onClick={() => handlePageChange(pageNumber)}
            >
              <Typography> {pageNumber}</Typography>
            </button>
          ))}
          <button
            className="ps-flex ps-cursor-pointer ps-items-center ps-border-none ps-bg-transparent"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={isLastPage}
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

const PaginatorSelect: React.FC<{
  options: number[];
  value: string;
  onChange: (value: number) => void;
}> = ({ options, value, onChange }) => {
  // Workaround to set width of the select based on the longest option
  const style: React.CSSProperties = {};
  const tmp = document.createElement('select');
  tmp.appendChild(document.createElement('option')).textContent = value;
  tmp.className = 'ps-opacity-0 ps-font-sans ps-text-base';
  document.body.appendChild(tmp);
  style.width = `${tmp.clientWidth + 3}px`;
  tmp.remove();

  return (
    <select
      className={commonCn(
        'ps-cursor-pointer ps-rounded ps-border ps-border-transparent ps-bg-transparent ps-p-0 ps-font-sans ps-text-base ps-outline-none ps-ring-0 hover:ps-border-blue focus:ps-border-blue focus:ps-ring-2 focus:ps-ring-blue/25'
      )}
      value={value}
      onChange={(e) => onChange(toInteger(e.target.value))}
      style={style}
    >
      {options.map((option) => (
        <option key={option} value={String(option)}>
          {option}
        </option>
      ))}
    </select>
  );
};

const PaginatorSkeleton = () => <Skeleton className={'ps-my-2'} height={20} />;

export default Paginator;
