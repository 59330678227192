import React, { useRef } from 'react';
import { Typography } from '@components/typography';
import { commonCn } from '@utils/cn';

export const QuestionnaireOptionMultiple = ({
  text,
  checked,
  disabled,
  onChange,
  value,
  textRef,
}: {
  text: string;
  checked: boolean;
  disabled: boolean;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: string | boolean) => void;
  textRef: React.ForwardedRef<'label'>;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <input
        type="checkbox"
        name={value}
        id={value}
        ref={inputRef}
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange(e, value === 'true' ? true : value === 'false' ? false : value);
        }}
        className="ps-peer ps-sr-only" // Hide the default radio input
      />
      <Typography
        ref={textRef}
        as={'label'}
        htmlFor={value}
        className={commonCn(
          `ps-block ps-w-full ps-cursor-pointer ps-whitespace-nowrap ps-rounded-md ps-border-[1px] ps-border-solid ps-border-gray-500 ps-bg-white ps-px-2 ps-py-[8.5px] peer-focus-visible:ps-bg-blue-50`,
          disabled ? 'ps-cursor-default ps-bg-gray-300 ps-text-gray-600' : '',
          checked && 'ps-border-blue-600 ps-text-blue-600'
        )}
      >
        <span>{text}</span>
      </Typography>
    </>
  );
};
