import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import { useForwardedRef } from '@hooks/use-forwarded-ref';
import { commonCn } from '@utils/cn';

export const DEFAULT_SIZE = 'md';
const DEFAULT_TEXT_ALIGN = 'left';

export const inputVariants = cva(
  'ps-w-full ps-cursor-text ps-items-center ps-rounded-[4px] ps-border ps-border-solid ps-border-gray ps-bg-white ps-text-gray-900 placeholder:ps-text-gray focus-within:ps-border-blue focus-within:ps-ring-2 focus-within:ps-ring-blue/25 hover:ps-border-blue',
  {
    variants: {
      flexDirection: {
        row: 'ps-flex',
        column: '',
      },
      error: {
        true: 'ps-border-red-500 focus-within:ps-border-red-500 focus-within:ps-ring-red-500/25 hover:ps-border-red-500',
      },
      highlight: {
        true: 'ps-bg-yellow-50',
      },
      disabled: {
        true: 'focus-within:ps-ring-none ps-border-gray ps-bg-gray-300 ps-text-gray-600 focus-within:ps-border-none hover:ps-border-gray',
      },
      size: {
        xs: 'ps-px-0.5 ps-text-md',
        sm: 'ps-px-1 ps-text-md',
        md: 'ps-px-2 ps-text-lg sm:ps-text-md',
        lg: 'ps-px-3',
      },
      textAlign: {
        left: 'ps-text-left',
        center: 'ps-text-center',
        right: 'ps-text-right',
      },
    },
    defaultVariants: {
      flexDirection: 'row',
      error: false,
      size: DEFAULT_SIZE,
      textAlign: DEFAULT_TEXT_ALIGN,
    },
  }
);

export const inputInputVariants = cva(
  'ps-m-0 ps-w-full ps-border-0 ps-border-transparent ps-bg-transparent ps-p-0 ps-font-sans ps-text-inherit ps-outline-none ps-ring-0 placeholder:ps-text-gray-600 focus:ps-border-transparent focus:ps-ring-0',
  {
    variants: {
      size: {
        xs: 'ps-h-[20px]',
        sm: 'ps-h-[28px]',
        md: 'ps-h-[38px]',
        lg: 'ps-h-[48px]',
      },
    },
    defaultVariants: {
      size: DEFAULT_SIZE,
    },
  }
);

export type InputVariantsProps = VariantProps<typeof inputVariants>;

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'ref'> {
  rightAdornment?: React.ReactNode;
  rightAdornmentSpacing?: number;
  rightAdornmentWrapperClassName?: string;
  leftAdornment?: React.ReactNode;
  leftAdornmentSpacing?: number;
  leftAdornmentWrapperClassName?: string;
  flexDirection?: InputVariantsProps['flexDirection'];
  highlight?: InputVariantsProps['highlight'];
  error?: InputVariantsProps['error'];
  textAlign?: InputVariantsProps['textAlign'];
  size?: InputVariantsProps['size'];
  parentRef?: any;
  inputClassName?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      rightAdornment,
      rightAdornmentSpacing = 0.5,
      rightAdornmentWrapperClassName,
      leftAdornment,
      leftAdornmentSpacing = 0.5,
      leftAdornmentWrapperClassName,
      error,
      flexDirection,
      parentRef,
      value,
      highlight,
      textAlign,
      inputClassName,
      ...props
    },
    forwardedRef
  ) => {
    /*
      TODO: check autofill styling in all browsers
    */
    const inputRef = useForwardedRef(forwardedRef);
    const invalid = !!error || props['aria-invalid'];

    const clickOnInput = (e: React.MouseEvent) => {
      if ((e.target as HTMLElement)?.tagName === 'INPUT') return;

      e.stopPropagation();
      if (inputRef && typeof inputRef === 'object') {
        inputRef.current?.click();
        inputRef.current?.focus();
      }
    };

    const showHighlight =
      highlight &&
      ((typeof value === 'string' && value.length === 0) ||
        (typeof value === 'number' && isNil(value)) ||
        (isArray(value) && value.length === 0) ||
        isNil(value));

    return (
      <div
        className={commonCn(
          inputVariants({
            className,
            flexDirection,
            disabled: props.disabled,
            highlight: showHighlight,
            size: props.size,
            textAlign: textAlign,
            error: !!invalid,
          })
        )}
        ref={parentRef}
        onClick={clickOnInput}
      >
        {leftAdornment && (
          <div
            className={commonCn(`ps-select-none`, leftAdornmentWrapperClassName)}
            style={{ marginRight: `${leftAdornmentSpacing * 8}px` }}
          >
            {leftAdornment}
          </div>
        )}
        <InputInput
          inputRef={inputRef}
          value={value}
          {...props}
          aria-invalid={invalid}
          className={commonCn(inputInputVariants({ size: props.size }), 'text-align-inherit', inputClassName)}
        />

        {rightAdornment && (
          <div
            className={commonCn(`ps-select-none`, rightAdornmentWrapperClassName)}
            style={{ marginLeft: `${rightAdornmentSpacing * 8}px` }}
          >
            {rightAdornment}
          </div>
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';

interface InputInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'size'> {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
}

const InputInput: React.FC<InputInputProps> = ({ inputRef, ...props }) => {
  return <input ref={inputRef} type={'text'} {...props} />;
};

export default Input;
