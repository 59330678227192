import React from 'react';
import { useOnScreen } from '@hooks';
import { commonCn } from '@utils/cn';
import { MuiGrid as Grid } from './grid';

export interface FormFooterProps {
  children?: React.ReactNode;
  onScroll?: () => void;
}

export const FormFooter: React.FC<FormFooterProps> = (props) => {
  const ref: any = React.useRef<HTMLDivElement>();
  const { isBelow } = useOnScreen<HTMLDivElement>(ref);

  return (
    <>
      <div ref={ref}></div>
      <Grid
        container
        className={commonCn(
          isBelow &&
            'ps-w-full lg:ps-w-[calc(100%-270px)] ps-flex-end ps-fixed ps-inset-x-0 ps-bottom-0 ps-left-0 ps-border-t ps-border-gray-200 ps-bg-white ps-py-1 ps-shadow-md lg:ps-ml-[270px] ps-px-1 lg:ps-px-[27px]',
          'hide-in-percy'
        )}
      >
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </>
  );
};

export default FormFooter;
