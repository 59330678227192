import React, { FocusEventHandler, useCallback } from 'react';
import { useField } from 'formik';
import { MultipleAutocomplete } from '@components/field';
import { MultipleAutocompleteFieldPropsWithFormik } from '@components/field/autocomplete/multiple-autocomplete-with-formik/types';
import { MultipleAutocompleteOptionConstrain } from '@components/field/autocomplete/multiple-autocomplete/multiple-autocomplete';
import { useFieldContext } from '@components/field/field-context';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function MultipleAutocompleteWithFormik<T extends MultipleAutocompleteOptionConstrain>({
  onChange,
  getOptionCode,
  onClear,
  isLoading: propsIsLoading,
  ...props
}: MultipleAutocompleteFieldPropsWithFormik<T>): React.ReactElement {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<T[] | null>(props.name);

  const { isLoading, disabled } = useFieldContext();

  const handleClear = useCallback(() => {
    if (onClear) onClear();
    else {
      void setTouched(true, false);
      void setValue([]);
    }
  }, [onClear, setTouched, setValue]);

  const handleChange = useCallback(
    (e: React.SyntheticEvent, option: T[] | null) => {
      const defaultAction = () => {
        void setTouched(true, false);
        void setValue(option);
      };

      if (onChange) onChange(e, option, defaultAction);
      else defaultAction();
    },
    [onChange, setTouched, setValue]
  );

  const handleFocus: FocusEventHandler<HTMLDivElement> = useCallback(() => {
    void setTouched(true, false);
  }, [setTouched]);

  // It is possible to pass accidentally sting as a value for  multiple Autocomplete through formik intiial values
  if (typeof value === 'string' || typeof value === 'number') {
    console.error('Only [] or null are allowed as value for multiple Autocomplete');
    return <></>;
  }

  return (
    <MultipleAutocomplete
      {...{
        ...props,
        isLoading: isLoading ?? propsIsLoading,
        value: value,
        onChange: handleChange,
        onFocus: handleFocus,
        onClear: handleClear,
        getOptionCode,
        error: props.error ? props.error : touched ? error : undefined,
        disabled: props.disabled || disabled,
      }}
    />
  );
}
