import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';

export const flattenKeys = (obj: object, path: string[] = []): Record<string, any> => {
  if (isObject(obj)) {
    return reduce(obj, (cum, next, key) => merge(cum, flattenKeys(next, [...path, key])), {});
  } else {
    return { [path.join('.')]: obj };
  }
};
