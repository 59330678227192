import React from 'react';
import Label, { LabelProps } from '@components/label';

export const LabelWrapper = ({ children, ...props }: LabelProps) => {
  if (React.isValidElement(children)) {
    return React.cloneElement(children, props);
  }

  return <Label {...props}>{children}</Label>;
};
