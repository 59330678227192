import React, { useEffect, useReducer, useRef } from 'react';
import { useForwardedRef } from '@hooks/use-forwarded-ref';
import { SegmentSettings, SegmentsState } from './types';
import { useBaseSegmentedInput } from './use-base-segmented-input';
import { makeInitialSegmentsValue, setHtmlInputValue } from './utils';

export type UseSegmentedInputOptions = {
  name?: string;
  defaultValue?: string;
  value?: string | null;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
  disabled?: boolean;
  onValueChange?: (value: string | null) => void;
  mapValueToSegments?: (value: string) => (string | null)[];
  mapSegmentsToValue?: (segments: (string | null)[]) => string;
  isValid?: (value: string | null | undefined, segmentsValues: SegmentsState) => boolean;
};

type SegmentAction =
  | { type: 'change'; payload: SegmentsState }
  | { type: 'clear'; payload?: never }
  | { type: 'reinitialise'; payload: SegmentsState };

export const useSegmentedInput = (segmentsSettings: SegmentSettings[], options?: UseSegmentedInputOptions) => {
  const optionsRef = useRef(options);
  optionsRef.current = options;
  const masterInputRef = useForwardedRef(options?.inputRef ?? null);

  const [segmentsValues, dispatch] = useReducer(
    (state: SegmentsState, action: SegmentAction) => {
      const { type, payload } = action;

      const setStringValue = (value: string | null) => {
        // setTimeout to prevent outer state change at the moment of inner state change
        setTimeout(() => {
          setHtmlInputValue(masterInputRef.current, value);
          options?.onValueChange?.(value);
        }, 1);
      };

      switch (type) {
        case 'change': {
          const valueString = options?.mapSegmentsToValue?.(payload) ?? payload.join('');
          const isValid = options?.isValid?.(valueString, payload) ?? true;
          setStringValue(isValid ? valueString : null);

          return payload;
        }
        case 'reinitialise': {
          const valueString = options?.mapSegmentsToValue?.(payload) ?? payload.join('');
          const isValid = options?.isValid?.(valueString, payload) ?? true;
          setHtmlInputValue(masterInputRef.current, isValid ? valueString : null);

          return payload;
        }
        case 'clear': {
          setStringValue('');
          return segmentsSettings.map(() => null);
        }
        default:
          return state;
      }
    },
    makeInitialSegmentsValue(segmentsSettings, options?.defaultValue ?? options?.value, options?.mapValueToSegments)
  );

  const clear = () => {
    if (options?.onValueChange && options?.value !== undefined) options?.onValueChange?.('');
    else dispatch({ type: 'clear' });
  };
  const handleClear = segmentsValues.some((el) => el !== null && el !== '')
    ? () => {
        clear();
        utils.focusSegment(0);
      }
    : undefined;

  const handleChangeValue = (value: string) => {
    const segments = makeInitialSegmentsValue(segmentsSettings, value, options?.mapValueToSegments);

    if (options?.onValueChange && options?.value !== undefined) options?.onValueChange?.(value);
    else dispatch({ type: 'change', payload: segments });
  };

  const segmentsValuesRef = useRef(segmentsValues);
  segmentsValuesRef.current = segmentsValues;
  const segmentsSettingsRef = useRef(segmentsSettings);
  segmentsSettingsRef.current = segmentsSettings;

  useEffect(() => {
    if (options?.value === null || options?.value === undefined) return;

    const currentValue = optionsRef.current?.mapSegmentsToValue?.(segmentsValuesRef.current);
    if (currentValue === options?.value) return;

    const values = makeInitialSegmentsValue(
      segmentsSettingsRef.current,
      options?.value,
      optionsRef.current?.mapValueToSegments
    );

    setTimeout(() => dispatch({ type: 'reinitialise', payload: values }), 1);
  }, [options?.value, optionsRef, segmentsValuesRef, segmentsSettingsRef]);

  const [segments, containerProps, utils] = useBaseSegmentedInput(segmentsSettings, {
    value: segmentsValues,
    onValueChange: (value) => dispatch({ type: 'change', payload: value }),
    disabled: options?.disabled,
  });

  const inputProps = {
    ref: masterInputRef,
    type: 'text',
    'aria-invalid': options?.isValid ? !options.isValid(options?.value, segmentsValues) : undefined,
    name: options?.name,
    onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      utils.focus();
    },
    disabled: options?.disabled,
    className: 'ps-sr-only',
  } as const;

  return [segments, containerProps, inputProps, { ...utils, clear, handleClear, handleChangeValue }] as const;
};
