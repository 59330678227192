import React from 'react';
import { ProviderContext, SnackbarProvider, SnackbarProviderProps, VariantType, useSnackbar } from 'notistack';
import { Alert, AlertProps } from '@components';

// eslint-disable-next-line @typescript-eslint/naming-convention
let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export const ProsightSnackbarProvider: React.FC<SnackbarProviderProps> = ({ children, ...props }) => {
  return (
    <SnackbarProvider
      {...props}
      Components={{
        success: CustomSnackbarSuccess,
        error: CustomSnackbarError,
        warning: CustomSnackbarWarning,
        info: CustomSnackbarInfo,
        default: CustomSnackbarInfo,
      }}
      classes={{
        containerRoot: '!ps-z-[999999]', // should be above modals
      }}
    >
      {children}
      <SnackbarUtilsConfigurator />
    </SnackbarProvider>
  );
};

type TitleAndMessage = {
  title: React.ReactNode;
  message?: React.ReactNode | React.ReactNode[];
};

export type CustomSnackbarProps = {
  message: string | React.ReactElement<TitleAndMessage>;
};

const CustomSnackbarSuccess = React.forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
  return <CustomSnackbar {...props} variant={'success'} ref={ref} />;
});
CustomSnackbarSuccess.displayName = 'CustomSnackbarSuccess';

const CustomSnackbarError = React.forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
  return <CustomSnackbar {...props} variant={'error'} ref={ref} />;
});
CustomSnackbarError.displayName = 'CustomSnackbarError';

const CustomSnackbarWarning = React.forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
  return <CustomSnackbar {...props} variant={'warning'} ref={ref} />;
});
CustomSnackbarWarning.displayName = 'CustomSnackbarWarning';

const CustomSnackbarInfo = React.forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
  return <CustomSnackbar {...props} variant={'info'} ref={ref} />;
});
CustomSnackbarInfo.displayName = 'CustomSnackbarInfo';

export const CustomSnackbar = React.forwardRef<
  HTMLDivElement,
  CustomSnackbarProps & { variant: AlertProps['severity'] }
>(({ variant = 'info', ...props }, ref) => {
  const makeTitleAndMessage = () => {
    if (typeof props.message === 'string') return [props.message, null];
    else return [props.message.props.title, props.message.props.message];
  };

  const [title, message] = makeTitleAndMessage();

  return (
    <div className={'hide-in-percy'} ref={ref}>
      {title && message ? (
        <Alert title={title} severity={variant}>
          {message}
        </Alert>
      ) : (
        <Alert severity={variant}>{title ? title : message}</Alert>
      )}
    </div>
  );
});
CustomSnackbar.displayName = 'CustomSnackbar';

type MessageFunction = (title: string, message?: React.ReactNode) => void;
type SnackbarType = {
  success: MessageFunction;
  warning: MessageFunction;
  info: MessageFunction;
  error: MessageFunction;
  toast: ({
    title,
    message,
    variant,
  }: TitleAndMessage & {
    variant?: VariantType;
  }) => void;
};

// empty component used to pass props to CustomSnackbar due to limitations of notistack API
// eslint-disable-next-line @typescript-eslint/naming-convention
const _SnackbarContent: React.FC<TitleAndMessage> = () => null;

export const Snackbar: SnackbarType = {
  success(title, message) {
    this.toast({ title, message, variant: 'success' });
  },
  warning(title, message) {
    this.toast({ title, message, variant: 'warning' });
  },
  info(title, message) {
    this.toast({ title, message, variant: 'info' });
  },
  error(title, message) {
    this.toast({ title, message, variant: 'error' });
  },
  toast({ title, message, variant = 'default' }) {
    useSnackbarRef?.enqueueSnackbar(<_SnackbarContent title={title} message={message} />, { variant });
  },
};
