import React from 'react';
import { sk } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import { ChevronLeft, ChevronRight } from 'react-feather';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

export const Calendar = ({ classNames, showOutsideDays = true, components, locale, ...props }: CalendarProps) => {
  return (
    <DayPicker
      locale={locale ?? sk}
      captionLayout="dropdown-buttons"
      showOutsideDays={showOutsideDays}
      fromYear={1900}
      toYear={2100}
      classNames={{
        months: 'ps-flex ps-flex-col sm:ps-flex-row ps-space-y-1 sm:ps-space-x-1 sm:ps-space-y-0',
        month: 'ps-space-y-1',
        caption: 'ps-flex ps-justify-between ps-pt-0.25 ps-relative ps-items-center',
        caption_label: 'ps-hidden',
        caption_dropdowns: 'ps-flex ps-gap-1',
        nav: 'ps-space-x-1 ps-flex ps-items-center',
        nav_button:
          'ps-inline-flex ps-cursor-pointer ps-select-none ps-items-center ps-justify-center ps-border-0 ps-bg-none ps-no-underline ps-transition-shadow ps-h-[30px] ps-w-[30px] ps-rounded-full ps-text-black data-[state=open]:ps-bg-gray-100 ps-bg-transparent ps-p-0 ps-opacity-50 ' +
          'hover:ps-bg-gray-300/25 hover:ps-ring-0 hover:ps-ring-gray-900/25 hover:ps-opacity-100 hover:ps-ring-offset-0 ' +
          'focus:ps-outline-none focus:ps-ring-2 focus:ps-ring-gray-900/50 focus:ps-ring-offset-0 ' +
          'active:ps-ring-0 active:ps-shadow-defaultActive active:ps-bg-gray-300/40 ' +
          'disabled:ps-pointer-events-none disabled:ps-text-gray disabled:ps-opacity-50',
        table: 'ps-w-[250px] ps-border-collapse ps-space-y-0.5',
        head_row: 'ps-flex',
        head_cell: 'ps-text-muted-foreground ps-rounded-md ps-w-full ps-font-normal ps-text-[0.8rem]',
        row: 'ps-flex ps-w-full ps-mt-1',
        cell:
          'ps-text-center ps-text-sm ps-p-[1px] ps-relative ps-w-full ps-aspect-square focus-within:ps-z-20 ' +
          (props.mode === 'range'
            ? '[&:has([aria-selected])]:ps-bg-blue first:[&:has([aria-selected])]:ps-rounded-l-md last:[&:has([aria-selected])]:ps-rounded-r-md '
            : ''),
        day: 'calendar__day',
        day_selected: 'calendar__day-selected',
        day_disabled: 'calendar__day-disabled',
        day_outside: 'calendar__day-outside',
        day_hidden: 'calendar__day-hidden',
        day_today: 'calendar__day-today',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="ps-h-3 ps-w-3 sm:ps-h-2 sm:ps-w-2" />,
        IconRight: () => <ChevronRight className="ps-h-3 ps-w-3 sm:ps-h-2 sm:ps-w-2" />,
        Dropdown: (props) => {
          const selectedItem = React.Children.toArray(props.children).find((el: any) => el.props.value === props.value);
          const style = props.style ?? {};

          // Workaround to set width of the select based on the longest option
          if (React.isValidElement(selectedItem)) {
            const tmp = document.createElement('select');
            tmp.appendChild(document.createElement('option')).textContent = selectedItem?.props.children ?? null;
            tmp.className = 'ps-opacity-0 ps-font-sans ps-text-base';
            document.body.appendChild(tmp);
            style.width = `${tmp.clientWidth + 5}px`;
            tmp.remove();
          }

          const dropdownType = props.className?.split('_')?.[1] as 'month' | 'year' | undefined;

          return (
            <select
              {...props}
              style={style}
              data-testid={`calendar-${dropdownType}-dropdown-trigger`}
              className={`${props.className} ps-cursor-pointer ps-rounded ps-border ps-border-transparent ps-bg-transparent ps-p-0 ps-font-sans ps-text-base ps-text-black ps-outline-none ps-ring-0 hover:ps-border-blue focus:ps-border-blue focus:ps-ring-2 focus:ps-ring-blue/25`}
            />
          );
        },
        ...components,
      }}
      {...props}
    />
  );
};
Calendar.displayName = 'Calendar';
