import React, { ReactNode, useContext } from 'react';

export interface FieldContextValue {
  isLoading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

const Context = React.createContext<FieldContextValue | null>(null);

export const FieldContext: React.FC<FieldContextValue> = ({ children, isLoading = false, disabled = false }) => {
  return <Context.Provider value={{ isLoading, disabled }}>{children}</Context.Provider>;
};

export const useFieldContext = () => {
  const context = useContext(Context);
  return context || {};
};
