import { getDaysInMonth, isValid } from 'date-fns';

export const getSegmentsRanges = (
  value: Date | null,
  { minDate, maxDate }: { minDate?: Date | null; maxDate?: Date | null }
) => {
  const fromDate = isValid(minDate) ? minDate : null;
  const toDate = isValid(maxDate) ? maxDate : null;

  const min = (() => {
    if (!fromDate) return { year: 1900, month: 1, day: 1 };

    const minYear = fromDate.getFullYear();
    const minMonth = value && minYear === value.getFullYear() ? fromDate.getMonth() + 1 : 1;
    const minDay =
      value && minYear === value.getFullYear() && minMonth === value.getMonth() + 1 ? fromDate.getDate() : 1;

    return { year: minYear, month: minMonth, day: minDay };
  })();

  const max = (() => {
    if (!toDate) return { year: 2100, month: 12, day: value ? getDaysInMonth(value) : 31 };

    const maxYear = toDate.getFullYear();
    const maxMonth = value && maxYear === value.getFullYear() ? toDate.getMonth() + 1 : 12;
    const maxDay = (() => {
      if (value && maxYear === value.getFullYear() && maxMonth === value.getMonth() + 1) return toDate.getDate();
      else if (value) return getDaysInMonth(value);
      else return 31;
    })();

    return { year: maxYear, month: maxMonth, day: maxDay };
  })();

  return {
    year: { min: min.year, max: max.year },
    month: { min: min.month, max: max.month },
    day: { min: min.day, max: max.day },
  };
};
