import React from 'react';
import { NavigationInterceptorContext } from '@utils/navigation-interceptor';

export const useNavigationInterceptor = (block: boolean, interceptorKey: string) => {
  const { registerInterceptor, updateInterceptor, removeInterceptor } = React.useContext(NavigationInterceptorContext);

  React.useEffect(() => {
    if (registerInterceptor) registerInterceptor(interceptorKey);

    return () => {
      if (removeInterceptor) removeInterceptor(interceptorKey);
    };
  }, [registerInterceptor, removeInterceptor, interceptorKey]);

  React.useEffect(() => {
    if (updateInterceptor) updateInterceptor(interceptorKey, block);
  }, [interceptorKey, block, updateInterceptor]);
};
