import React, { FC, ReactNode } from 'react';
import { ProsightTooltip } from './prosight-tooltip';

export type TooltipIconProps = {
  infoText?: ReactNode;
  component: React.ReactElement<any, any>;
};
export const TooltipIcon: FC<TooltipIconProps> = ({ infoText, component }) => {
  return <ProsightTooltip infoText={infoText}>{component}</ProsightTooltip>;
};
