import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { DEFAULT_VIEW, TabsContext, TabsView } from '@components/tabs/utils';

export const TabsRoot = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root> & {
    view?: TabsView;
  }
>(({ view = DEFAULT_VIEW, ...props }, ref) => {
  return (
    <TabsContext.Provider value={{ view, value: props.value }}>
      <TabsPrimitive.Root ref={ref} {...props} />
    </TabsContext.Provider>
  );
});
TabsRoot.displayName = TabsPrimitive.Root.displayName;
