import React, { SyntheticEvent } from 'react';
import isNil from 'lodash/isNil';
import { Virtuoso } from 'react-virtuoso';
import { Checkbox } from '@components/checkbox';
import { AutocompleteFieldPropsBase } from '@components/field/autocomplete/autocomplete/autocomplete';
import { SELECT_LIST_ITEM_TEST_ID } from '@components/field/autocomplete/autocomplete/autocomplete-list';
import { LeftAdornmentComponent } from '@components/field/autocomplete/multiple-autocomplete/left-adornment-component';
import useMultipleAutocomplete from '@components/field/autocomplete/multiple-autocomplete/use-multiple-autocomplete';
import { RightAdornmentComponent } from '@components/field/autocomplete/right-adornment-component';
import FieldWrapper from '@components/field/field-wrapper';
import { FieldSkeleton } from '@components/field/skeleton';
import { FieldPropsBase } from '@components/field/types';
import Input from '@components/input';
import { Popover } from '@components/popover';
import { Typography } from '@components/typography';

export type MultipleAutocompleteOptionConstrain = { code: string | number; name: string } | string | number;

export type MultipleAutocompleteFieldProps<T extends MultipleAutocompleteOptionConstrain> = FieldPropsBase &
  Omit<AutocompleteFieldPropsBase<T>, 'value' | 'onChange' | 'getOptionCode'> & {
    value: T[] | null;
    getOptionCode?: (option: T) => string | number;
    onChange: (e: SyntheticEvent, value: T[] | null) => void;
  };

const MultipleAutocompleteInner = <T extends MultipleAutocompleteOptionConstrain>(
  {
    value,
    options = [],
    getOptionLabel: propsGetOptionLabel,
    getOptionCode: propsGetOptionCode,
    onClear: propsOnClear,
    clearButton = true,
    onChange,
    isLoading,
    disabled,
    ...rest
  }: MultipleAutocompleteFieldProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const { label, labelProps, required, infoText, helperText, error, ...other } = rest;
  const {
    inputRef,
    handleClear,
    getOptionLabel,
    getOptionCode,
    virtuosoRef,
    isOpen,
    setOpen,
    inputValue,
    setInputValue,
    filteredList,
    handleRemoveOption,
    toggleOption,
    menuHeight,
    firstSelectedItem,
  } = useMultipleAutocomplete<T>({
    value,
    options,
    propsGetOptionLabel,
    propsGetOptionCode,
    propsOnClear,
    onChange,
  });

  if (isLoading) {
    return <FieldSkeleton hasLabel={!!label} hasError={!!error} hasHelperText={!!helperText} isTextarea={false} />;
  }

  return (
    <div className="ps-relative ps-w-full">
      <div className={'ps-flex ps-items-center '}>
        <Popover open={isOpen} onOpenChange={() => setOpen((prevState) => !prevState)}>
          <Popover.Trigger className={'ps-w-full ps-border-none ps-bg-transparent'} asChild={true}>
            <FieldWrapper
              {...{
                label,
                labelProps: {
                  ...labelProps,
                  onClick: () => {
                    setOpen((prevState) => !prevState);
                    inputRef?.current?.focus();
                  },
                },
                required,
                infoText,
                helperText,
                error,
              }}
            >
              {({ errorAriaAttributes, helperTextAttributes, id }) => (
                <Input
                  flexDirection={isOpen ? 'column' : 'row'}
                  className={'ps-flex-col! ps-group'}
                  inputClassName={isOpen ? '' : 'ps-w-0'}
                  onChange={(event) => setInputValue(event.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' && !inputValue && !isNil(value)) {
                      const valueToRemove: T = value[value.length - 1];

                      handleRemoveOption(e, valueToRemove);
                      e.preventDefault();
                    }
                  }}
                  onBlur={(e) => {
                    if (isNil(e.relatedTarget)) {
                      setInputValue('');
                    }
                  }}
                  rightAdornment={
                    <RightAdornmentComponent
                      isOpen={isOpen}
                      setOpen={setOpen}
                      clearButton={clearButton && !isNil(value) && value.length > 0}
                      handleClear={handleClear}
                      disabled={disabled}
                    />
                  }
                  leftAdornment={
                    <LeftAdornmentComponent
                      handleRemoveOption={handleRemoveOption}
                      getOptionCode={getOptionCode}
                      selectedValues={value}
                      getOptionLabel={getOptionLabel}
                      disabled={disabled}
                      isOpen={isOpen}
                    />
                  }
                  leftAdornmentWrapperClassName={'ps-overflow-hidden ps-flex-grow'}
                  value={inputValue}
                  disabled={disabled}
                  placeholder=""
                  {...errorAriaAttributes}
                  {...helperTextAttributes}
                  {...other}
                  id={id}
                  aria-selected={'true'}
                  autoComplete={'off'}
                  ref={ref ?? (inputRef as React.ForwardedRef<HTMLInputElement>)}
                  onFocus={() => setOpen(true)}
                  onClick={() => setOpen(true)}
                />
              )}
            </FieldWrapper>
          </Popover.Trigger>
          <Popover.Content
            asChild={true}
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
          >
            <div
              className={
                'ps-m-0 ps-w-[--radix-popover-trigger-width] ps-list-none ps-overflow-y-auto ps-rounded-md ps-border-[1px] ps-border-solid ps-border-gray-500 ps-bg-white ps-p-1'
              }
            >
              {filteredList.length === 0 ? (
                <Typography className={'ps-p-1 ps-text-center '}>Žiadne výsledky</Typography>
              ) : (
                <Virtuoso
                  ref={virtuosoRef}
                  initialTopMostItemIndex={{ index: firstSelectedItem, align: 'center' }}
                  style={{ height: `min(var(--radix-popper-available-height), ${menuHeight}px)` }}
                  className={'ps-bg-white'}
                  data={filteredList}
                  role={'dialog'}
                  itemContent={(index, el) => (
                    <label className={'ps-flex ps-cursor-pointer ps-items-center hover:ps-bg-gray-50'}>
                      <Checkbox
                        checked={!!value?.find((v) => getOptionCode(v) === el.code)}
                        onChange={(event) => {
                          const val = options?.find((v) => getOptionCode(v) === el.code);
                          toggleOption(event, val as T);
                        }}
                      />
                      <Typography
                        data-testid={SELECT_LIST_ITEM_TEST_ID}
                        key={el.code}
                        className={`ps-p-1 aria-selected:ps-bg-gray-50`}
                      >
                        {el.name}
                      </Typography>
                    </label>
                  )}
                />
              )}
            </div>
          </Popover.Content>
        </Popover>
      </div>
    </div>
  );
};

const MemoizedMultipleAutocompleteInner = React.memo(React.forwardRef(MultipleAutocompleteInner));
MemoizedMultipleAutocompleteInner.displayName = 'MultipleAutocomplete';

export const MultipleAutocomplete = MemoizedMultipleAutocompleteInner as unknown as typeof MultipleAutocompleteInner & {
  Skeleton: typeof FieldSkeleton;
};

Object.assign(MultipleAutocomplete, {
  Skeleton: FieldSkeleton,
});
