import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import presets from './presets';
import { FormatOptions, NumberFormatPreset } from './types';

const formatNumberWithOptions = (
  number: any,
  {
    negativeToZero,
    trailingZeros,
    onlySuffix,
    suffix,
    decimalSeparator,
    thousandsSeparator,
    precision,
    nilValue,
  }: FormatOptions
) => {
  if (isNil(number) || isNaN(number) || number === '') return nilValue;
  number = parseFloat(number.toString().replace(',', '.'));

  // return only suffix no need for other formatting
  if (onlySuffix) {
    return suffix instanceof Function ? suffix(number) : suffix;
  }

  // round to 'precision' and apply 'negativeToZero'
  number = round(number, precision);
  number = number < 0 && negativeToZero ? 0 : number;

  // convert to string and apply 'trailingZeros'
  let numberStr = '';
  numberStr = trailingZeros && number % 1 !== 0 ? number.toFixed(2) : number.toString();

  // apply 'thousandsSeparator'
  const parts = numberStr.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
  numberStr = parts.join(decimalSeparator);

  // add 'suffix'
  if (suffix.length > 2) numberStr += '\xa0';

  numberStr += suffix instanceof Function ? suffix(number) : suffix;
  return numberStr;
};

const defaultFormat = (number: number | string | null | undefined, options?: Partial<FormatOptions>) =>
  formatNumberWithOptions(number, { ...presets['_default'], ...options });

// eslint-disable-next-line @typescript-eslint/naming-convention
const presetFunctions = Object.fromEntries(
  Object.keys(presets).map((preset) => [
    preset,
    (number: number | string | null | undefined, args?: Partial<FormatOptions>) => {
      const options = { ...presets[preset as keyof typeof presets], ...args };
      return formatNumberWithOptions(number, options);
    },
  ])
) as Record<
  Exclude<NumberFormatPreset, '_default'>,
  (number: number | string | null | undefined, args?: Partial<FormatOptions>) => string
>;

/**
 * EXAMPLES:      null  0         1.5       1.23     '1234'         USAGE
 * default:       '-'  '0'       '1,5'     '1,23'    '1234'         numberFormat(value)
 * money:         '-'  '0,00 €'  '1,50 €'  '1,23 €'  '1 234,00 €'   numberFormat.money(value)
 * moneyRounded:  '-'  '0 €'     '2 €'     '1 €'     '1 234 €'      numberFormat.moneyRounded(value)
 */
export const numberFormat = Object.assign(defaultFormat, presetFunctions);
