import React from 'react';
import { commonCn } from '@utils/cn';

export interface NavBarSectionProps extends React.HTMLAttributes<HTMLUListElement> {
  title?: string;
}

export const NavBarSection: React.FC<NavBarSectionProps> = ({ title, children, className, ...rest }) => {
  return (
    <ul className={commonCn('ps-m-0 ps-list-none ps-p-0 ps-pb-1', className)} {...rest}>
      <li
        className={
          'ps-mb-[12px] ps-px-[10px] ps-pb-[8px] ps-pt-[16px] ps-text-md ps-font-bold ps-uppercase ps-leading-[20px] ps-text-gray-600'
        }
      >
        {title}
      </li>
      {children}
    </ul>
  );
};
