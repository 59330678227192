import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { TabsContext } from '@components/tabs/utils';

export const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & { keepMounted?: boolean }
>(({ className, children, keepMounted = true, ...props }, ref) => {
  const { value } = React.useContext(TabsContext);
  return (
    <TabsPrimitive.Content ref={ref} className={className} {...props}>
      {(value === props.value || keepMounted) && children}
    </TabsPrimitive.Content>
  );
});
TabsContent.displayName = TabsPrimitive.Content.displayName;
