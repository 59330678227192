import React, { ReactNode } from 'react';
import { Button, Modal, MuiGrid, Typography } from '@components';

export type ConfirmationModalType = 'danger' | 'warn';

export interface ConfirmationModalProps {
  id: string;
  title?: string;
  type?: ConfirmationModalType;
  modalText: ReactNode;
  confirmButtonText: string;
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
  cancelText?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  const {
    id,
    title = 'Potvrdenie',
    modalText,
    onConfirm,
    open,
    onClose,
    type = 'warning',
    confirmButtonText,
    cancelText = 'Zrušiť',
  } = props;
  return (
    <Modal open={open} onOpenChange={onClose}>
      <Modal.Body>
        <Modal.Header endButton={<Modal.Close />}>{title}</Modal.Header>
        <Modal.Content>
          <MuiGrid container>
            <MuiGrid item xs={12}>
              <Typography variant="body2">{modalText}</Typography>
            </MuiGrid>
          </MuiGrid>
        </Modal.Content>
        <Modal.Footer className={`ps-gap-2 ${type !== 'danger' ? 'ps-justify-between' : 'ps-justify-end'}`}>
          {type === 'danger' && (
            <Button id={'cancel-delete'} variant="outlined" onClick={onClose}>
              {cancelText}
            </Button>
          )}
          <Button
            autoFocus
            id={id}
            onClick={() => {
              onConfirm();
              onClose();
            }}
            color={type === 'danger' ? 'error' : 'primary'}
          >
            {confirmButtonText}
          </Button>

          {/*<MuiStack direction={'row'} gap={3} justifyContent={type !== 'danger' ? 'between' : 'end'}>*/}
          {/*  */}
          {/*</MuiStack>*/}
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
