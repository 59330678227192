import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { ScrollableWithArrows } from '@components/scrollable-with-arrows';
import { TabsContext } from '@components/tabs/utils';
import { commonCn } from '@utils/cn';

const TabsListClasses = {
  primary: 'ps-gap-2.5 ps-border-0 ps-border-b-2 ps-border-solid ps-border-b-gray ps-items-end',
  secondary: 'ps-gap-1 ps-items-center',
  'secondary-contrast': 'ps-gap-1 ps-items-center',
} as const;

export const TabsListUI = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, children, ...props }, ref) => {
  const { view } = React.useContext(TabsContext);

  return (
    <ScrollableWithArrows
      numberOfItems={React.Children.count(children)}
      chevron={(props) => <ScrollableWithArrows.Chevron {...props} className={'ps-shadow-none'} size={'small'} />}
      className={'ps-overflow-auto'}
    >
      {(scrollableProps) => (
        <div className={'ps-min-w-full ps-overflow-auto'} {...scrollableProps}>
          <TabsPrimitive.List
            ref={ref}
            className={commonCn('ps-inline-flex ps-min-w-full', TabsListClasses[view], className)}
            {...props}
          >
            {children}
          </TabsPrimitive.List>
        </div>
      )}
    </ScrollableWithArrows>
  );
});
TabsListUI.displayName = TabsPrimitive.List.displayName;
