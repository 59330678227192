import React from 'react';
import { Label } from '@components/label';
import { Radio, RadioProps } from '@components/radio';
import { Skeleton } from '@components/skeleton';
import { commonCn } from '@utils/cn';

export type RadioOptionProps<T> = Omit<RadioProps, 'value'> & {
  value: T extends boolean ? never : T;
  label?: string | React.ReactElement;
  mapOptionToValue?: (value: T) => string;
};

const RadioOption = <T,>({
  className,
  size = 16,
  label,
  error,
  disabled,
  isLoading,
  value: propValue,
  mapOptionToValue,
  ...rest
}: RadioOptionProps<T>) => {
  const randomId = React.useId();
  rest.id ??= randomId;
  if (isLoading) return <RadioSkeleton hasLabel={!!label} />;

  const value = typeof propValue === 'string' ? propValue : mapOptionToValue && mapOptionToValue(propValue);

  return (
    <div className={commonCn('ps-flex ps-items-center ps-gap-1', className)}>
      <Radio size={size} error={error} disabled={disabled} value={value} {...rest} />
      {label && (
        <Label
          className={commonCn(
            '-ps-mt-[6px]',
            disabled ? 'ps-pointer-events-none ps-cursor-default' : 'ps-cursor-pointer'
          )}
          htmlFor={rest.id}
          error={!!error}
          disabled={disabled}
          required={rest.required ?? true}
        >
          {label}
        </Label>
      )}
    </div>
  );
};
export default RadioOption;

export const RadioSkeleton = ({ hasLabel }: { hasLabel?: boolean }) => (
  <div className={'ps-flex ps-items-center ps-gap-2'}>
    <Skeleton variant="circular" width={16} height={16} animation="wave" />
    {hasLabel && <Skeleton variant="rectangular" width={100} height={12} animation="wave" />}
  </div>
);
