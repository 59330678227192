import React from 'react';
import Skeleton from '@components/skeleton';
import Stack from '@components/stack';

export interface FieldSkeletonProps {
  hasLabel?: boolean;
  hasHelperText?: boolean;
  hasError?: boolean;
  children?: React.ReactNode;
  direction?: 'row' | 'column';
  isTextarea?: boolean;
  rows?: number;
}

export const FieldSkeleton: React.FC<FieldSkeletonProps> = ({
  hasLabel,
  hasHelperText,
  hasError,
  children,
  direction,
  isTextarea = false,
  rows = 3,
}) => {
  return (
    <Stack gap={1} direction={direction} alignItems={direction === 'row' ? 'center' : undefined}>
      {hasLabel && <Skeleton variant="rounded" width={150} height={12} animation="wave" />}
      {hasHelperText && <Skeleton variant="rounded" width={200} height={12} animation="wave" />}
      {children ? (
        children
      ) : (
        <Skeleton variant="rounded" width="100%" height={isTextarea ? 20 * rows + 17 : 40} animation="wave" />
      )}
      {hasError && <Skeleton variant="rounded" width={200} height={12} animation="wave" />}
    </Stack>
  );
};
