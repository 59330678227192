import React from 'react';

export const useOnScreen = <T extends Element>(ref: React.MutableRefObject<T>, rootMargin: string = '0px') => {
  const [isOnScreen, setIsOnScreen] = React.useState<boolean>(false);
  const [isBelow, seIsBelow] = React.useState<boolean>(false);
  const [isAbove, setIsAbove] = React.useState<boolean>(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsOnScreen(entry.isIntersecting);
        seIsBelow(!entry.isIntersecting && entry.boundingClientRect.top > 0);
        setIsAbove(!entry.isIntersecting && entry.boundingClientRect.top < 0);
      },
      {
        rootMargin,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    } else {
      observer.unobserve(ref.current);
    }
    // eslint-disable-next-line
  }, []);

  return { isOnScreen, isBelow, isAbove };
};
