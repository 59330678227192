import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { TabSortable } from '@components/tabs/tab/tab-sortable';
import { TabUi } from '@components/tabs/tab/tab.ui';
import { TabsContext, getLeftAdornment } from '@components/tabs/utils';

export type TabProps = React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
  ref?: React.Ref<React.ElementRef<typeof TabsPrimitive.Trigger>>;
  status?: 'error' | 'success';
  onClose?: (e: React.MouseEvent) => void;
  leftAdornment?: React.ReactNode | ((props: { dragHandle?: React.ReactElement }) => React.ReactNode);
  // leftAdornment?: React.ReactNode;
  rightAdornment?: React.ReactNode;
  sortable?: boolean;
};

export const Tab = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Trigger>, TabProps>(
  ({ value, sortable, ...props }, ref) => {
    const { view } = React.useContext(TabsContext);

    if (sortable && view === 'primary') throw new Error('Primary tabs cannot be draggable');
    if (sortable) return <TabSortable ref={ref} value={value} {...props} />;

    const leftAdornment = getLeftAdornment(props.leftAdornment);

    return (
      <TabsPrimitive.Trigger ref={ref} value={value} asChild>
        <TabUi view={view} {...props} leftAdornment={leftAdornment} />
      </TabsPrimitive.Trigger>
    );
  }
);
Tab.displayName = TabsPrimitive.Trigger.displayName;
