import React, { ReactNode } from 'react';
import omit from 'lodash/omit';
import { Virtuoso } from 'react-virtuoso';
import { Option, OptionWithOriginal } from '@components/field';
import { Typography } from '@components/typography';

const VIRTUOSO_TRESHOLD = 10;
export const SELECT_LIST_ITEM_TEST_ID = 'select-list-item';

interface AutocompleteListProps<T> {
  virtuosoRef: React.RefObject<any>;
  optionsLoading?: boolean;
  useVirtuoso?: boolean;
  focused: number;
  handleElementSelect: (e: React.SyntheticEvent, el: Option) => void;
  filteredList: OptionWithOriginal<T>[];
  menuHeight: number;
  renderOption?: (
    option: T,
    filteredListOption: Option,
    handleElementSelect: (e: React.SyntheticEvent, el: Option) => void,
    focused: boolean
  ) => ReactNode;
}

type ListItemProps = {
  el: Option;
  focused: boolean;
  handleElementSelect: (e: React.SyntheticEvent, el: Option) => void;
};

const ListItem = React.memo(({ el, focused, handleElementSelect }: ListItemProps) => {
  return (
    <Typography
      key={el.code}
      data-testid={SELECT_LIST_ITEM_TEST_ID}
      className={'ps-cursor-pointer ps-p-1 hover:ps-bg-gray-50 aria-selected:ps-bg-gray-300'}
      onClick={(e) => {
        handleElementSelect(e, el);
      }}
      aria-selected={focused}
    >
      {el.name}
    </Typography>
  );
});

ListItem.displayName = 'ListItem';

const AutocompleteList = <T,>({
  optionsLoading,
  focused,
  handleElementSelect,
  filteredList,
  virtuosoRef,
  menuHeight,
  renderOption: renderOptionProps,
}: AutocompleteListProps<T>) => {
  if (optionsLoading) return <Typography className={'ps-p-1 ps-text-center '}>Načítavam... </Typography>;
  if (renderOptionProps && filteredList.length === 0) return <></>;
  if (filteredList.length === 0) return <Typography className={'ps-p-1 ps-text-center '}>Žiadne výsledky</Typography>;

  const renderOption = (elWithOriginal: OptionWithOriginal<T>, index: number) => {
    const el = omit(elWithOriginal, 'original');
    if (renderOptionProps) {
      return renderOptionProps(filteredList[index].original, el, handleElementSelect, focused === index);
    }
    return <ListItem key={el.code} el={el} focused={focused === index} handleElementSelect={handleElementSelect} />;
  };

  if (filteredList.length > VIRTUOSO_TRESHOLD)
    return (
      <Virtuoso
        ref={virtuosoRef}
        initialTopMostItemIndex={{ index: focused, align: 'end' }}
        style={{ height: `min(var(--radix-popper-available-height),${menuHeight}px)` }}
        data={filteredList}
        role={'dialog'}
        className={'ps-bg-white'}
        itemContent={(index, el) => renderOption(el, index)}
      />
    );
  return filteredList.map(renderOption);
};

export default AutocompleteList;
